import React, { Component } from 'react'
import DateTimePicker from 'react-datepicker'
import ControlledInputSpan from './ControlledInputSpan'
import Moment from 'moment'
import { Form, FormGroup, Label, Row, Col, CustomInput } from 'reactstrap'
import './Controls.css'
import '../../../../../../node_modules/react-datepicker/dist/react-datepicker.css'

class controls extends Component {
  render () {
    // console.log(this.props);

    return (
      <Form className='notification-history-form'>
        <Row form>
          <Col md={2}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label> Showing </Label>
              &nbsp;
              <CustomInput type='switch' id='showing-toggle' onClick={this.props.toggleShowAll} label={this.props.showing} />
            </FormGroup>
          </Col>
          { this.props.showing === 'All'
            ? <React.Fragment>
              <Col md={4}>
                <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                  <Label for='beginning-date-time'>Beginning</Label>
                  <DateTimePicker
                    id='beginning-date-time'
                    className='form-control'
                    selected={this.props.start.toDate()}
                    onChange={this.props.setStart}
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat='MMMM d, yyyy h:mm aa'
                    minDate={Moment(this.props.rangeStart).toDate()}
                    maxDate={Moment(this.props.rangeEnd).toDate()} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                  <Label for='span-days'>Span (days)</Label>
                  <ControlledInputSpan id='span-days' units={this.props.units} onChange={this.props.setSpan} value={this.props.span} />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                  <Label for='ending-date-time'>End</Label>
                  <DateTimePicker
                    id='ending-date-time'
                    className='form-control'
                    selected={this.props.end.toDate()}
                    onChange={this.props.setEnd}
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat='MMMM d, yyyy h:mm aa'
                    minDate={Moment(this.props.rangeStart).toDate()}
                    maxDate={Moment(this.props.rangeEnd).toDate()} />
                </FormGroup>
              </Col>
            </React.Fragment>
            : <React.Fragment>
              <Col md={10}> &nbsp; </Col>
            </React.Fragment>
          }
        </Row>
        &nbsp;
      </Form>
    )
  }
}

export default controls
