import React, { Component } from 'react'
import UtilizationPreview from '../UtilizationPreview/UtilizationPreview'
import { Button } from 'reactstrap'
import Moment from 'moment'
import './MachineListItem.css'
import shared from '../../../../../shared/shared'
import axios from 'axios'

class machineListItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      totalHours: this.props.data.totalHours,
      lastData: undefined,
      utilization: this.props.data.utilization
    }

    // *** HOURS FIELDS MUST BE SUM-ABLE!! ***
    this.hoursFields = undefined
    this.lastRow = undefined
    this.totalHours = undefined
    this.lastData = undefined
    this.lastReceived = undefined

    this.machineDetailsPane = this.machineDetailsPane.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  getData () {
    axios.get(
      shared.getVmsHost('hoursFields?machine_ID=' + this.props.data.serial_num), { withCredentials: true }
    ).then((response) => {
      // console.log(response.data)
      this.hoursFields = response.data[0].hours_fields
      if (this.lastRow !== undefined) {
        this.sumHours()
      }
    }).catch((error) => { shared.handleError(error) })

    axios.get(
      shared.getVmsHost('lastRow?machine=' + this.props.data.serial_num), { withCredentials: true }
    ).then((response) => {
      if (response.status !== 299) {
        this.lastRow = response.data[0]
        if (this.hoursFields !== undefined) {
          this.sumHours()
        }
      }
    }).catch((error) => { shared.handleError(error) })
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.data.totalHours === this.state.totalHours) {
      return false
    } else {
      return true
    }
  }

  componentWillReceiveProps (nextProps) {
    if ((nextProps.data.totalHours !== undefined) && (nextProps.data.last_row !== undefined) && (nextProps.data.totalHours !== this.state.totalHours)) {
      this.lastRow = nextProps.last_row
      this.setState({
        totalHours: nextProps.data.totalHours,
        lastData: nextProps.data.last_row.datetime
      })
    }
  }

  machineDetailsPane = (e) => {
    this.props.machineDetailsPane(e.currentTarget.textContent.split('/')[0])
  }

  sumHours () {
    this.totalHours = undefined

    if (this.hoursFields !== null) {
      let rowKeys = Object.keys(this.lastRow)
      let l = 0

      for (let j = 0; j < rowKeys.length; j++) {
        for (let k = 0; k < this.hoursFields.length; k++) {
          if (rowKeys[j].includes(this.hoursFields[k])) {
            l++
          }
        }
      }

      if (l === this.hoursFields.length) {
        this.totalHours = 0

        for (let i = 0; i < this.hoursFields.length; i++) {
          this.totalHours += this.lastRow[this.hoursFields[i]]
        }
      } else {
        this.totalHours = 'N/A'
      }
    } else {
      this.totalHours = 'N/A'
    }

    this.lastData = this.lastRow.datetime
    // console.log(this.totalHours);

    this.props.getBackChildData(this.props.data.serial_num, 'totalHours', this.totalHours)
    this.props.getBackChildData(this.props.data.serial_num, 'last_row', this.lastRow)

    this.setState({
      totalHours: this.totalHours,
      lastData: this.lastData
    })
  }

  handleClick (event) {
    this.machineDetailsPane(event)
  }

  getBackUtilization (value) {
    // console.log(value)
    this.setState({
      utilization: value
    })
    this.props.getBackChildData(this.props.data.serial_num, 'utilization', value)
  }

  render () {
    if (this.props.data.totalHours === undefined) {
      this.getData()
    }

    let lastData = 'N/A'

    if (this.state.lastData !== undefined) {
      lastData = this.state.lastData.replace('T', ' ').replace('.000Z', '').replace('.', "'").slice(0, 21)
    }

    let lastReceived = 'N/A'

    if ((this.props.data.last_data_received !== undefined) && (this.props.data.last_data_received !== null)) {
      lastReceived = Moment(this.props.data.last_data_received + '.000Z').format().replace('T', ' ').slice(0, 19)
    }

    let hours = 'N/A'
    if ((this.state.totalHours !== undefined) && (this.state.totalHours !== 'N/A')) {
      hours = this.state.totalHours.toFixed(1)
    }

    return (
      <tr>
        <td onClick={this.handleClick}>
          <Button color='link' onClick={(e) => { e.preventDefault() }}>
            {this.props.data.serial_num}{this.props.data.owner_designation ? '/' + this.props.data.owner_designation : null}
          </Button>
        </td>
        <td>{lastData}</td>
        <td>{lastReceived}</td>
        <td className={this.state.totalHours ? null : 'hours-not-available'}>{hours}</td>
        <td><UtilizationPreview getBackData={this.getBackUtilization.bind(this)} data={this.props.data} lastRow={this.lastRow} ajaxError={this.props.ajaxError} /></td>
      </tr>
    )
  }
}

export default machineListItem
