import React, { Component } from 'react'
import { UncontrolledPopover, PopoverHeader, PopoverBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Label, Input, CustomInput, Spinner, FormFeedback } from 'reactstrap'
import axios from 'axios'
import ImageUploader from 'react-images-upload'
import { Typeahead } from 'react-bootstrap-typeahead'
import './NewPPRModal.css'
import 'react-bootstrap-typeahead/css/Typeahead.min.css'
import Cookies from 'js-cookie'
import { FaRegQuestionCircle } from 'react-icons/fa'
import shared from '../../shared/shared'

class NewPPRModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      activeIndex: 0,
      activeTab: '1',
      initiatedBy: [],
      branches: [],
      customers: [],
      types: [],
      severities: [],
      factoryPlantNames: [],
      factoryLocationNames: [],
      warrantyTypes: [],
      warrantyStatus: [],
      vendor: [],
      rigTypes: [],
      rig: '',
      rigType: '',
      sites: [],
      title: '',
      partNumber: '',
      type: '',
      severity: '',
      plant: '',
      location: '',
      problemDefinitionDetails: '',
      engineHours: '',
      powerPackHours: '',
      drillPercHours: '',
      category: '',
      responsibleGroup: '',
      branch: '',
      repairReworkDetails: '',
      inspectionContainmentDetails: '',
      problemDefinitionCategory: [],
      problemDefinitionResponsibleGroups: [],
      quantity: '',
      retrieving: false,
      customerSelected: false,
      warrantySelected: false,
      locationSwitchLabel: 'Factory',
      submittingPPR: false,
      problemDefinitionFiles: [],
      problemDefinitionFileData: [],
      selectedCustomer: '',
      selectedSite: '',
      titleFormFeedback: false,
      rigFormFeedback: false,
      quantityFormFeedback: false,
      partNumberFormFeedback: false,
      typeFormFeedback: false,
      severityFormFeedback: false,
      locationSwitchFormFeedback: false,
      plantFormFeedback: false,
      locationFormFeedback: false,
      customerFormFeedback: false,
      siteFormFeedback: false,
      engineHoursFormFeedback: false,
      powerPackHoursFormFeedback: false,
      drillCompressionHoursFormFeedback: false,
      problemDefinitionDetailsFormFeedback: false,
      categoryFormFeedback: false,
      responsibleGroupsFormFeedback: false,
      branchesFormFeedback: false,
      repairReworkDetailsFormFeedback: false,
      inspectionContainmentDetailsFormFeedback: false,
      rigTypeFormFeedback: false,
      action: 'post',
      role: Cookies.get('Permissions')
    }
    this.submitPPR = this.submitPPR.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.assignValuesToModal = this.assignValuesToModal.bind(this)
  }

  async componentDidMount () {
    if (Object.keys(this.props.data).length !== 0) {
      this.assignValuesToModal()
    }
    this.state.role !== 'Distributor'
      ? this.setState()
      : this.setState({
        locationSwitchLabel: 'Customer',
        responsibleGroup: 5,
        type: 2,
        branch: 'Corporate'
      })
    if (this.state.initiatedBy.length <= 0 && !this.state.retrieving) {
      this.setState({ retrieving: true })
      await axios.get(shared.getEnvironmentURL(`getVendorList`), { withCredentials: true }).then(
        async (response) => {
          this.setState({
            vendor: [...new Set(response.data)].sort().filter(vendor => (vendor !== null && vendor !== '' && vendor !== '<New Vendor>'))
          })
        }
      ).catch((error) => {
        shared.handleError(error)
      })
      axios.get(shared.getEnvironmentURL(`getPPRData`), { withCredentials: true }).then(
        async (response) => {
          this.setState({
            initiatedBy: [...new Set(response.data.map(ppr => ppr.serviceTech))].sort()
          })
        }
      ).catch((error) => {
        shared.handleError(error)
      })
      axios.get(shared.getEnvironmentURL(`getCustomerNames`), { withCredentials: true }).then(
        async (response) => {
          this.setState({
            customers: response.data
          })
        }
      ).catch((error) => {
        shared.handleError(error)
      })
      axios.get(shared.getEnvironmentURL(`getAllControlledValues`), { withCredentials: true }).then(
        async (response) => {
          this.setState({
            severities: [...new Set(response.data.severities.filter(severity => this.state.role !== 'Distributor' ? severity : severity.id !== 5))].sort(),
            types: [...new Set(response.data.types)].sort(),
            factoryPlantNames: [...new Set(response.data.factoryPlantNames)].sort(),
            factoryLocationNames: [...new Set(response.data.factoryLocationNames)].sort(),
            warrantyTypes: [...new Set(response.data.warrantyTypes)].sort(),
            warrantyStatus: [...new Set(response.data.warrantyStatus)].sort(),
            problemDefinitionCategory: [...new Set(response.data.categories)].sort(),
            problemDefinitionResponsibleGroups: [...new Set(response.data.responsibleGroups)].sort(),
            rigTypes: [...new Set(response.data.rigTypes)].sort()
          })
        }
      ).catch((error) => {
        shared.handleError(error)
      })
    }
  }

  async assignValuesToModal () {
    this.setState({
      id: this.props.data.id,
      title: this.props.data.title,
      rigType: this.props.data.rigType.id,
      rig: this.props.data.rig,
      quantity: this.props.data.quantity,
      partNumber: this.props.data.partNumber,
      type: this.props.data.type.id,
      severity: this.props.data.severity.id,
      locationType: this.props.data.locationSwitchLabel,
      plant: this.props.data.plant.id,
      location: this.props.data.plantLocation.id,
      selectedCustomer: this.props.data.customerName,
      customerSelected: this.props.data.customerName !== null,
      locationSwitchLabel: this.props.data.customerName === null ? 'Factory' : 'Customer',
      selectedSite: this.props.data.customerSiteName,
      engineHours: this.props.data.engineHours,
      powerPackHours: this.props.data.powerPackHours,
      drillPercHours: this.props.data.drillPercussionHours,
      problemDefinitionDetails: this.props.data.problemDefinitionDetails,
      problemDefinitionFiles: this.props.data.problemDefinitionFiles,
      problemDefinitionFileData: this.props.data.problemDefinitionFileData,
      category: this.props.data.problemDefinitionCategory.id,
      responsibleGroup: this.props.data.problemDefinitionGroupResponsible.id,
      branch: this.props.data.branch,
      repairReworkDetails: this.props.data.repairReworkDetails,
      inspectionContainmentDetails: this.props.data.inspectionContainmentDetails,
      warrantySelected: this.props.data.warrantySelected,
      action: 'patch'
    })
  }
  toggle (tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  async submitPPR () {
    this.setState({ submittingPPR: true })
    await axios({
      method: this.state.action,
      url: shared.getEnvironmentURL(`submitPPR`),
      data: {
        id: this.state.action === 'patch' ? this.state.id : null,
        title: this.state.title,
        rigType: this.state.rigType,
        rig: this.state.rig,
        quantity: this.state.quantity,
        partNumber: this.state.partNumber,
        type: this.state.type,
        severity: this.state.severity,
        locationType: this.state.locationSwitchLabel,
        plant: this.state.locationSwitchLabel === 'Factory' ? this.state.plant : null,
        location: this.state.locationSwitchLabel === 'Factory' ? this.state.location : null,
        selectedCustomer: this.state.locationSwitchLabel === 'Customer' ? this.state.selectedCustomer : null,
        selectedSite: this.state.locationSwitchLabel === 'Customer' ? this.state.selectedSite : null,
        engine: this.state.locationSwitchLabel === 'Customer' ? this.state.engineHours : null,
        powerPackHours: this.state.locationSwitchLabel === 'Customer' ? parseInt(this.state.powerPackHours) : null,
        drillPercHours: this.state.locationSwitchLabel === 'Customer' ? parseInt(this.state.drillPercHours) : null,
        problemDefinitionDetails: this.state.problemDefinitionDetails,
        problemDefinitionFiles: this.state.problemDefinitionFiles,
        problemDefinitionFileData: this.state.problemDefinitionFileData,
        category: this.state.category,
        responsibleGroup: this.state.responsibleGroup,
        branch: this.state.branch,
        repairReworkDetails: this.state.repairReworkDetails,
        inspectionContainmentDetails: this.state.locationSwitchLabel === 'Factory' ? this.state.inspectionContainmentDetails : null,
        warrantySelected: this.state.warrantySelected
      },
      transformRequest: [
        (data, headers) => {
          Object.keys(data).map(field => {
            if (data[field] === '') {
              data[field] = null
            }
            return field
          })
          return JSON.stringify(data)
        }
      ],
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      withCredentials: true
    })
      .then(
        async (response) => {
          await this.setState({ submittingPPR: false })
          this.props.toggle()
          this.props.reRenderTable()
        }
      ).catch(async (e) => {
        shared.handleError(e)
        let errorMessages = e.response.data
        this.setState({
          submittingPPR: false,
          titleFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'title') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'title') }).message : null,
          rigFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'rig') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'rig') }).message : null,
          quantityFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'quantity') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'quantity') }).message : null,
          partNumberFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'part number') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'part number') }).message : null,
          typeFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'type') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'type') }).message : null,
          severityFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'severity') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'severity') }).message : null,
          locationSwitchFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'location type') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'location type') }).message : null,
          plantFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'plant') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'plant') }).message : null,
          locationFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'factory location') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'factory location') }).message : null,
          customerFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'customer') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'customer') }).message : null,
          siteFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'site') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'site') }).message : null,
          engineHoursFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'engine hours') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'engine hours') }).message : null,
          powerPackHoursFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'power pack hours') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'power pack hours') }).message : null,
          drillCompressionHoursFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'drill compression hours') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'drill compression hours') }).message : null,
          problemDefinitionDetailsFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'problem definition details') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'problem definition details') }).message : null,
          categoryFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'category') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'category') }).message : null,
          responsibleGroupsFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'responsible group') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'responsible group') }).message : null,
          branchesFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'branch') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'branch') }).message : null,
          repairReworkDetailsFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'repair rework details') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'repair rework details') }).message : null,
          inspectionContainmentDetailsFormFeedback: errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'inspection containment details') }) !== undefined ? errorMessages.find((formFeedback) => { return (formFeedback['field'] === 'inspection containment details') }).message : null
        })
      })
  }

  renderOptionListFromArray (options) {
    return options.map((option, index) => {
      return <option key={index} value={option[Object.keys(option)[1]] !== null ? option.id : 1}>{option[Object.keys(option)[1]]}</option>
    })
  }

  getSitesForSelectedCustomer (selectedCustomer) {
    axios.get(shared.getEnvironmentURL(`getSitesForSelectedCustomer/${selectedCustomer}`), { withCredentials: true }).then(
      async (response) => {
        this.setState({
          customerSelected: true,
          sites: [...new Set(response.data)].sort().filter(site => (site !== null && site !== ''))
        })
      }
    ).catch((error) => {
      shared.handleError(error)
    })
  }

  getBranchesForSelectedGroup (selectedGroup) {
    if (selectedGroup !== 1) {
      axios.get(shared.getEnvironmentURL(`getBranchesForSelectedGroup/${encodeURIComponent(selectedGroup)}`), { withCredentials: true }).then(
        async (response) => {
          if (response.data[0].branch === null) {
            this.setState({
              branches: [{ id: 'Corporate', branch: 'Corporate' }],
              branch: 'Corporate'
            })
          } else {
            this.setState({
              branches: response.data.map((data) => { return { id: data.branch, branch: data.branch } })
            })
          }
        }
      ).catch((error) => {
        shared.handleError(error)
      })
    }
  }

  onDrop (pictures, pictureDataURLs) {
    this.setState({
      problemDefinitionFiles: pictures.map(picture => {
        return { lastModified: picture.lastModified, lastModifiedDate: picture.lastModifiedDate, name: picture.name, size: picture.size, type: picture.type }
      }),
      problemDefinitionFileData: pictureDataURLs
    })
  }
  handleChange (e) {
    if (e.target.id === 'ppr-title') {
      this.setState({ title: e.target.value })
    } else if (e.target.id === 'ppr-rig-type') {
      this.setState({ rigType: e.target.value })
    } else if (e.target.id === 'ppr-rig') {
      this.setState({ rig: e.target.value })
    } else if (e.target.id === 'ppr-quantity' && new RegExp('^\\d*$').test(e.target.value)) {
      this.setState({ quantity: e.target.value })
    } else if (e.target.id === 'ppr-part-number') {
      this.setState({ partNumber: e.target.value })
    } else if (e.target.id === 'ppr-type') {
      this.setState({ type: e.target.value })
    } else if (e.target.id === 'ppr-severity') {
      this.setState({ severity: e.target.value })
    } else if (e.target.id === 'ppr-factory-plant-names') {
      this.setState({ plant: e.target.value })
    } else if (e.target.id === 'ppr-factory-location-names') {
      this.setState({ location: e.target.value })
    } else if (e.target.id === 'ppr-problem-definition-details') {
      this.setState({ problemDefinitionDetails: e.target.value })
    } else if (e.target.id === 'ppr-engine') {
      this.setState({ engineHours: e.target.value })
    } else if (e.target.id === 'ppr-power-pack' && new RegExp('^\\d*$').test(e.target.value)) {
      this.setState({ powerPackHours: e.target.value })
    } else if (e.target.id === 'ppr-drill-compression' && new RegExp('^\\d*$').test(e.target.value)) {
      this.setState({ drillPercHours: e.target.value })
    } else if (e.target.id === 'ppr-category') {
      this.setState({ category: e.target.value })
    } else if (e.target.id === 'ppr-responsible-groups') {
      this.setState({ responsibleGroup: e.target.value })
    } else if (e.target.id === 'ppr-branches') {
      this.setState({ branch: e.target.value })
    } else if (e.target.id === 'ppr-repair-rework-details') {
      this.setState({ repairReworkDetails: e.target.value })
    } else if (e.target.id === 'ppr-inspection-containment-details') {
      this.setState({ inspectionContainmentDetails: e.target.value })
    }
  }
  renderContent () {
    let required = <sup className='required'>*</sup>
    let pprTypeTooltip = <React.Fragment>
      <FaRegQuestionCircle id='pprTypeTooltip' />
      <UncontrolledPopover trigger='legacy' placement='right' target='pprTypeTooltip'>
        <PopoverHeader><center>Type</center></PopoverHeader>
        <PopoverBody>
          <ul>
            <li><strong>Non-Conformance:</strong> A part is produced that does not meet specification.</li>
            <li><strong>Opportunity for Improvement:</strong> A new alternative design or method can be applied to improve performance, save time or money.</li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>

    let pprSeverityTooltip = <React.Fragment>
      <FaRegQuestionCircle id='pprSeverityTooltip' />
      <UncontrolledPopover trigger='legacy' placement='right' target='pprSeverityTooltip'>
        <PopoverHeader><center>Severity</center></PopoverHeader>
        <PopoverBody>
          <ul>
            <li><strong>A - Safety Impact:</strong> Potential or confirmed safety incident or risk to end user, public or employees.</li>
            <li><strong>B1 - Customer Down:</strong> Customer is down, awaiting our response. This should be changed following interim consideration.</li>
            <li><strong>B2 - Customer Impact:</strong> Problem has been reported by the customer, or has occurred in the field.</li>
            <li><strong>C - Factory Impact:</strong> Problem has occurred in the factory or branch during production.</li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
    let pprOriginTooltip = <React.Fragment>
      <FaRegQuestionCircle id='pprOriginTooltip' />
      <UncontrolledPopover trigger='legacy' placement='right' target='pprOriginTooltip'>
        <PopoverHeader><center>Origin</center></PopoverHeader>
        <PopoverBody>
          <ul>
            <li><strong>Customer:</strong> Problem originated at Customer or in the field.</li>
            <li><strong>Factory:</strong> Problem originated in Production.</li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
    let pprWarrantyTooltip = <React.Fragment>
      <FaRegQuestionCircle id='pprWarrantyTooltip' />
      <UncontrolledPopover trigger='legacy' placement='right' target='pprWarrantyTooltip'>
        <PopoverHeader><center>Warranty</center></PopoverHeader>
        <PopoverBody>
          <strong>Warranty Request:</strong> Request warranty consideration.
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
    return (
      this.state.submittingPPR
        ? <Spinner style={{ width: '20rem', height: '20rem', display: 'block', margin: '13% auto' }} color='primary' type='grow' />
        : <React.Fragment>
          <Row form>
            <Col sm={12}>
              <FormGroup>
                <Label for='title'>Title{required}</Label>
                <Input type='text' id='ppr-title' value={this.state.title} onChange={this.handleChange} placeholder='Enter Title For The Problem' invalid={typeof this.state.titleFormFeedback === 'boolean' ? this.state.titleFormFeedback : true} />
                <FormFeedback>{this.state.titleFormFeedback}</FormFeedback>

              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={2}>
              <FormGroup>
                <Label for='ppr-rig-type'>Rig Type{required}</Label>
                <Input type='select' id='ppr-rig-type' value={this.state.rigType} onChange={this.handleChange} invalid={typeof this.state.typeFormFeedback === 'boolean' ? this.state.typeFormFeedback : true}>
                  {this.renderOptionListFromArray(this.state.rigTypes)}
                </Input>
                <FormFeedback>{this.state.rigTypeFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Label for='ppr-rig'>Rig{required}</Label>
                <Input type='text' id='ppr-rig' value={this.state.rig} onChange={this.handleChange} invalid={typeof this.state.rigFormFeedback === 'boolean' ? this.state.rigFormFeedback : true} />
                <FormFeedback>{this.state.rigFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Label for='ppr-quantity'>Quantity{required}</Label>
                <Input type='text' id='ppr-quantity' value={this.state.quantity} onChange={this.handleChange} invalid={typeof this.state.quantityFormFeedback === 'boolean' ? this.state.quantityFormFeedback : true} />
                <FormFeedback>{this.state.quantityFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup>
                <Label for='ppr-part-number'>Part Number{required}</Label>
                <Input type='text' id='ppr-part-number' value={this.state.partNumber} onChange={this.handleChange} invalid={typeof this.state.partNumberFormFeedback === 'boolean' ? this.state.partNumberFormFeedback : true} />
                <FormFeedback>{this.state.partNumberFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            {this.state.role !== 'Distributor'
              ? <Col sm={2}>
                <FormGroup>
                  <Label for='type'>Type{required} {pprTypeTooltip}</Label>
                  <Input type='select' name='select' id='ppr-type' value={this.state.type} onChange={this.handleChange} invalid={typeof this.state.typeFormFeedback === 'boolean' ? this.state.typeFormFeedback : true}>
                    {this.renderOptionListFromArray(this.state.types)}
                  </Input>
                  <FormFeedback>{this.state.typeFormFeedback}</FormFeedback>
                </FormGroup>
              </Col>
              : null }
            <Col sm={2}>
              <FormGroup>
                <Label for='ppr-severity'>Severity{required} {pprSeverityTooltip}</Label>
                <Input type='select' value={this.state.severity} onChange={this.handleChange} id='ppr-severity' invalid={typeof this.state.severityFormFeedback === 'boolean' ? this.state.severityFormFeedback : true}>
                  {this.renderOptionListFromArray(this.state.severities)}
                </Input>
                <FormFeedback>{this.state.severityFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {
            this.state.role !== 'Distributor'
              ? <Row>
                <Col sm={12}>
                  <CustomInput
                    type='switch'
                    color='secondary'
                    id='location-switch'
                    name='customSwitch'
                    checked={this.state.customerSelected}
                    label={this.state.locationSwitchLabel}
                    onChange={() => {
                      this.state.locationSwitchLabel === 'Customer' ? this.setState({ locationSwitchLabel: 'Factory', customerSelected: false }) : this.setState({ locationSwitchLabel: 'Customer', customerSelected: true })
                    }} />
                  {pprOriginTooltip}
                  <FormFeedback>{this.state.locationSwitchFormFeedback}</FormFeedback>
                </Col>
              </Row>
              : null
          }

          {
            this.state.locationSwitchLabel !== 'Customer'
              ? <Row>
                <Col sm={3}>
                  <FormGroup>
                    <Label for='ppr-factory-plant-names'>Plant</Label>
                    <Input type='select' value={this.state.plant} onChange={this.handleChange} id='ppr-factory-plant-names' invalid={typeof this.state.plantFormFeedback === 'boolean' ? this.state.plantFormFeedback : true}>
                      {this.renderOptionListFromArray(this.state.factoryPlantNames)}
                    </Input>
                    <FormFeedback>{this.state.plantFormFeedback}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label for='ppr-factory-location-names'>Location</Label>
                    <Input type='select' value={this.state.location} onChange={this.handleChange} id='ppr-factory-location-names' invalid={typeof this.state.locationFormFeedback === 'boolean' ? this.state.locationFormFeedback : true}>
                      {this.renderOptionListFromArray(this.state.factoryLocationNames)}
                    </Input>
                    <FormFeedback>{this.state.locationFormFeedback}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              : <React.Fragment>
                <Row>
                  <Col sm={3}>
                    <FormGroup>
                      <Label for='ppr-customer'>Customer{required}</Label>
                      <Typeahead
                        id='ppr-customer'
                        onChange={(selected) => {
                          if (selected[0] !== '') {
                            this.setState({ selectedCustomer: selected[0] })
                            this.getSitesForSelectedCustomer(selected[0])
                          }
                        }}
                        defaultSelected={this.state.selectedCustomer === undefined ? [] : [this.state.selectedCustomer]}
                        options={this.state.customers}
                        invalid={typeof this.state.customerFormFeedback === 'boolean' ? this.state.customerFormFeedback : true}
                      />
                      <FormFeedback>{this.state.customerFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label for='ppr-site'>Site{required}</Label>
                      <Typeahead
                        id='ppr-site'
                        disabled={!this.state.customerSelected}
                        onChange={(selected) => {
                          this.setState({ selectedSite: selected[0] })
                        }}
                        options={this.state.sites}
                        defaultSelected={this.state.selectedSite === undefined ? [] : [this.state.selectedSite]}
                        invalid={typeof this.state.siteFormFeedback === 'boolean' ? this.state.siteFormFeedback : true}
                      />
                      <FormFeedback>{this.state.siteFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label for='ppr-engine'>Engine Hours{required}</Label>
                      <Input type='text' value={this.state.engineHours} onChange={this.handleChange} id='ppr-engine' invalid={typeof this.state.engineHoursFormFeedback === 'boolean' ? this.state.engineHoursFormFeedback : true} />
                      <FormFeedback>{this.state.engineHoursFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label for='ppr-power-pack'>Power Pack Hours</Label>
                      <Input type='text' value={this.state.powerPackHours} onChange={this.handleChange} id='ppr-power-pack' invalid={typeof this.state.powerPackHoursFormFeedback === 'boolean' ? this.state.powerPackHoursFormFeedback : true} />
                      <FormFeedback>{this.state.powerPackHoursFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label for='ppr-drill-compression'>Drill Compression Hours</Label>
                      <Input type='text' value={this.state.drillPercHours} onChange={this.handleChange} id='ppr-drill-compression' invalid={typeof this.state.drillCompressionHoursFormFeedback === 'boolean' ? this.state.drillCompressionHoursFormFeedback : true} />
                      <FormFeedback>{this.state.drillCompressionHoursFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
          }
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label for='ppr-problem-definition-details'>Details{required}</Label>
                <Input type='textarea' id='ppr-problem-definition-details' value={this.state.problemDefinitionDetails} onChange={this.handleChange} invalid={typeof this.state.problemDefinitionDetailsFormFeedback === 'boolean' ? this.state.problemDefinitionDetailsFormFeedback : true} />
                <FormFeedback>{this.state.problemDefinitionDetailsFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <FormGroup>
                <Label for='ppr-category'>Category{required}</Label>
                <Input type='select' id='ppr-category' value={this.state.category} onChange={this.handleChange} invalid={typeof this.state.categoryFormFeedback === 'boolean' ? this.state.categoryFormFeedback : true}>
                  {this.renderOptionListFromArray(this.state.problemDefinitionCategory)}
                </Input>
                <FormFeedback>{this.state.categoryFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            {
              this.state.role !== 'Distributor'
                ? <React.Fragment>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for='ppr-responsible-groups'>Responsible Groups{required}</Label>
                      <Input type='select' id='ppr-responsible-groups' value={this.state.responsibleGroup}
                        onChange={
                          (selectedGroup) => {
                            this.handleChange(selectedGroup)
                            this.getBranchesForSelectedGroup(selectedGroup.target.value)
                          }} invalid={typeof this.state.responsibleGroupsFormFeedback === 'boolean' ? this.state.responsibleGroupsFormFeedback : true}>
                        {this.renderOptionListFromArray(this.state.problemDefinitionResponsibleGroups)}
                      </Input>
                      <FormFeedback>{this.state.responsibleGroupsFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for='ppr-branches'>Branches{required}</Label>
                      <Input type='select' value={this.state.branch} onChange={this.handleChange} id='ppr-branches' readOnly={this.state.branches === 'undefined' || this.state.branches.length === 0} invalid={typeof this.state.branchesFormFeedback === 'boolean' ? this.state.branchesFormFeedback : true}>
                        {this.renderOptionListFromArray(this.state.branches)}
                      </Input>
                      <FormFeedback>{this.state.branchesFormFeedback}</FormFeedback>
                    </FormGroup>
                  </Col>
                </React.Fragment>
                : null
            }
          </Row>
          <Row>
            <Col sm={4}>
              <FormGroup>
                <Label for='ppr-repair-rework-details'>Repair/Rework Details{required}</Label>
                <Input type='textarea' id='ppr-repair-rework-details' value={this.state.repairReworkDetails} onChange={this.handleChange} name='ppr-repair-rework-details' invalid={typeof this.state.repairReworkDetailsFormFeedback === 'boolean' ? this.state.repairReworkDetailsFormFeedback : true} />
                <FormFeedback>{this.state.repairReworkDetailsFormFeedback}</FormFeedback>
              </FormGroup>
            </Col>
            {
              this.state.locationSwitchLabel !== 'Customer'
                ? <Col sm={4}>
                  <FormGroup>
                    <Label for='ppr-inspection-containment-details'>Inspection/Containment Details{required}</Label>
                    <Input type='textarea' id='ppr-inspection-containment-details' value={this.state.inspectionContainmentDetails === null ? '' : this.state.inspectionContainmentDetails} onChange={this.handleChange} invalid={typeof this.state.inspectionContainmentDetailsFormFeedback === 'boolean' ? this.state.inspectionContainmentDetailsFormFeedback : true} />
                    <FormFeedback>{this.state.inspectionContainmentDetailsFormFeedback}</FormFeedback>
                  </FormGroup>
                </Col>
                : null
            }
            <Col sm={4}><ImageUploader withIcon withPreview buttonText='Choose images' onLoad={this.onDrop} defaultImages={this.state.problemDefinitionFileData} onChange={this.onDrop} maxFileSize={5242880} /></Col>
          </Row>
          <Row>
            <Col sm={12}>
              <CustomInput
                type='switch'
                color='secondary'
                id='warranty-switch'
                name='warranty-switch'
                label={this.state.warrantySelected ? 'Warranty Requested' : 'Warranty Not Requested'}
                onChange={() => {
                  this.setState((prevState) => {
                    return { warrantySelected: !prevState.warrantySelected }
                  })
                }} />
              {pprWarrantyTooltip}
              <FormFeedback>{this.state.warrantyFormFeedback}</FormFeedback>
            </Col>
          </Row>
        </React.Fragment>
    )
  }
  render () {
    return (
      this.props.modal
        ? (<Modal className='new-ppr-modal' isOpen={this.props.modal} toggle={this.props.toggle} backdrop='static'>
          <ModalHeader toggle={!this.state.submittingPPR ? this.props.toggle : () => { return true }} className='new-ppr-modal-header'>Create New PPR</ModalHeader>
          <ModalBody>
            {this.renderContent()}
          </ModalBody>
          <ModalFooter>
            <center>
              <Button color='primary' type='submit' disabled={this.state.submittingPPR} onClick={this.submitPPR}>Submit</Button>{' '}
              <Button color='secondary' type='button' disabled={this.state.submittingPPR} onClick={this.props.toggle}>Cancel</Button>
            </center>
          </ModalFooter>
          {/* </Form> */}
        </Modal>)
        : null
    )
  }
}

export default NewPPRModal
