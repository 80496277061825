import React, { Component } from 'react'
import { Alert, Spinner } from 'reactstrap'
import axios from 'axios'
import ReactTable from 'react-table'
import shared from '../../shared/shared'
class RigsInProcess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.state.row.map(row => { return (`${row[accessor]}` || '').length }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  async componentDidMount () {
    const URL = shared.getEnvironmentURL(`getRigsInProcess`)
    this.setState({ loaded: false })
    await axios.get(URL, { withCredentials: true }).then((response) => {
      this.setState({ row: response.data, loaded: true })
    }).catch((error) => {
      shared.handleError(error)
    })
    this.setState({
      columns: [{ accessor: 'slot', Header: 'Slot', width: this.getColumnWidth('slot', 'Slot') },
        { accessor: 'ms', Header: 'Status', width: this.getColumnWidth('ms', 'Status') },
        { accessor: 'site', Header: 'Site', width: this.getColumnWidth('site', 'Site') },
        { accessor: 'completion',
          Header: 'Assembly',
          width: this.getColumnWidth('partNumber', 'Assembly'),
          Cell: props => {
            return `${props.value * 100 + ' %'}`
          }
        },
        { accessor: 'model', Header: 'Model', width: this.getColumnWidth('model', 'Model') },
        { accessor: 'number', Header: 'Number', width: this.getColumnWidth('number', 'Number') },
        { accessor: 'partNumber', Header: 'Part Number', width: this.getColumnWidth('partNumber', 'Part Number') },
        { accessor: 'description', Header: 'Description', width: this.getColumnWidth('description', 'Description') },
        { accessor: 'commitFactoryComplete',
          Header: 'Customer Date',
          width: this.getColumnWidth('commitFactoryComplete', 'Customer Date'),
          Cell: props => { return props.value === '1899-12-31' || props.value === '1/1/1900' ? null : props.value }
        }]
    })
  }

  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      <React.Fragment>
        <Alert color='info'><h4 className='display-4'><center> Rigs In Process </center></h4></Alert>
        {
          this.state.loaded
            ? <ReactTable filterable data={this.state.row} columns={this.state.columns} className='-striped -highlight' defaultFilterMethod={this.filterCaseInsensitive} defaultPageSize={5} />
            : <Spinner color='info' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        }
      </React.Fragment>
    )
  }
}

export default RigsInProcess
