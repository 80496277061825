import React, { Component } from 'react'
import PlotlyChart from './PlotlyChart'
import DeTable from './DeTable'
import { Alert, Spinner } from 'reactstrap'
import shared from '../../../../../../shared/shared'
import axios from 'axios'

class usagePlot extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: this.props.start,
      end: this.props.end,
      barData: undefined,
      trammingHours: undefined,
      auxHours: undefined,
      chargingHours: undefined,
      batt3Hours: undefined,
      noDataTimeFrame: false,
      noDataMachine: false
    }

    this.fields = this.props.fields
    this.usageData = this.props.overviewData
    this.firstRender = true
  }

  componentWillReceiveProps (nextProps) {
    if ((nextProps.start !== this.state.start) || (nextProps.end !== this.state.end)) {
      this.setState({
        start: nextProps.start,
        end: nextProps.end,
        barData: undefined,
        trammingHours: undefined,
        auxHours: undefined,
        chargingHours: undefined,
        batt3Hours: undefined,
        noDataTimeFrame: false
      }, () => { this.getData() })
    }
  }

  getData () {
    let body = {
      machine: this.props.details.serial_num,
      fields: this.props.fields,
      date_end: this.state.end.format().replace('T', ' ').slice(0, 19),
      date_start: this.state.start.format().replace('T', ' ').slice(0, 19)
    }

    axios.post(
      shared.getVmsHost('getData'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.status > 298) {
        this.setState({
          noDataMachine: true
        })
      } else {
        // console.log(data)
        if (response.data.length > 0) {
          this.usageData = response.data
          this.convertToBarData()
        } else {
          this.setState({
            noDataTimeFrame: true
          })
        }
      }
    }).catch((error) => { shared.handleError(error) })
  }

  convertToBarData () {
    let lenngth = this.usageData.length
    let slices
    if (lenngth > 0) {
      if (lenngth < 5000) {
        slices = 1
      } else if (lenngth >= 5000 && lenngth < 10000) {
        slices = 2
      } else if (lenngth >= 10000 && lenngth < 20000) {
        slices = 3
      } else if (lenngth >= 20000 && lenngth < 50000) {
        slices = 4
      } else {
        slices = 5
      }

      let barData = { auxMode: { datetime: [], y: [], base: [] }, tramMode: { datetime: [], y: [], base: [] }, chargeMode: { datetime: [], y: [], base: [] } }
      let chargingCount = 0
      let auxCount = 0
      let tramCount = 0
      let batt3Count = 0

      for (let i = 0; i < this.usageData.length; i += slices) {
        if ((this.usageData[i].InAuxMode) && (this.usageData[i].BatteryState === 3)) {
          barData.auxMode.datetime.push(this.usageData[i].datetime)
          barData.auxMode.y.push(1)
          barData.auxMode.base.push(0)
          auxCount += slices
        }
        if ((!this.usageData[i].InAuxMode) && (this.usageData[i].BatteryState === 3)) {
          barData.tramMode.datetime.push(this.usageData[i].datetime)
          barData.tramMode.y.push(1)
          barData.tramMode.base.push(1)
          tramCount += slices
        }
        if ((this.usageData[i].ManageCharger1State === 5) && (this.usageData[i].BatteryState === 3)) {
          barData.chargeMode.datetime.push(this.usageData[i].datetime)
          barData.chargeMode.y.push(1)
          barData.chargeMode.base.push(2)
          chargingCount += slices
        }
        if (this.usageData[i].BatteryState === 3) {
          batt3Count += slices
        }
      }

      this.usageData = undefined

      if ((barData.auxMode.datetime.length > 0) || (barData.chargeMode.datetime.length > 0) || (barData.tramMode.datetime.length > 0)) {
        this.setState({
          barData: barData,
          auxHours: (auxCount / 3600),
          chargingHours: (chargingCount / 3600),
          trammingHours: (tramCount / 3600),
          batt3Hours: (batt3Count / 3600),
          noDataTimeFrame: false
        })
      } else {
        this.setState({
          noDataTimeFrame: true
        })
      }
    } else {
      this.setState({
        noDataTimeFrame: true
      })
    }
  }

  render () {
    let plotlychart = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    let table = null

    if (this.props.longTermClicked && this.firstRender) {
      this.firstRender = false
      this.getData()
    } else if ((this.firstRender) && (this.usageData !== undefined)) {
      this.firstRender = false
      this.convertToBarData()
    } else if ((this.firstRender) && (this.usageData === undefined)) {
      this.firstRender = false
      this.getData()
    }

    if (this.state.barData !== undefined) {
      // console.log('render plotlychart');
      plotlychart = <PlotlyChart rangeStart={this.state.start.format().slice(0, 19).replace('T', ' ')}
        rangeEnd={this.state.end.format().slice(0, 19).replace('T', ' ')} barData={this.state.barData} />
      table = <DeTable auxHours={this.state.auxHours} chargingHours={this.state.chargingHours} trammingHours={this.state.trammingHours} totalHours={this.state.batt3Hours} />
      // plotTable = this.renderPlotTable();
    }

    if (this.state.noDataTimeFrame) {
      plotlychart = <Alert color='primary'><center><h3>No data available from selected timeframe.</h3></center></Alert>
    }

    if (this.state.noDataMachine) {
      plotlychart = <Alert color='primary'><center><h3>Utilization detail not available for {this.props.details.serial_num}.</h3></center></Alert>
    }

    return (
      <React.Fragment>
        {table}
        {plotlychart}
      </React.Fragment>
    )
  }
}

export default usagePlot
