import React from 'react'
import Plot from 'react-plotly.js'

class PlotlyChart extends React.Component {
  renderPlot () {
    return <Plot
      config={{ displaylogo: false,
        // showSendToCloud: true,
        modeBarButtonsToRemove: ['lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'] }}
      data={[
        { name: 'Charging', type: 'bar', marker: { color: 'rgb(60, 189, 58)' }, x: this.props.barData.chargeMode.datetime, y: this.props.barData.chargeMode.y, base: this.props.barData.chargeMode.base },
        { name: 'Tram Mode', type: 'bar', marker: { color: 'rgb(188, 58, 189)' }, x: this.props.barData.tramMode.datetime, y: this.props.barData.tramMode.y, base: this.props.barData.tramMode.base },
        { name: 'Aux Mode', type: 'bar', marker: { color: 'rgb(58, 125, 189)' }, x: this.props.barData.auxMode.datetime, y: this.props.barData.auxMode.y, base: this.props.barData.auxMode.base }
      ]}
      useResizeHandler
      style={{ width: '100%', height: '50vh' }}
      layout={{
        xaxis: { range: [this.props.rangeStart, this.props.rangeEnd] },
        bargap: 0,
        autosize: true,
        title: this.props.chartTitle,
        yaxis: { range: [0, 3], showline: false, ticks: '', showticklabels: false }
      }}
    />
  }

  render () {
    // console.log(this.props);
    let plot = null

    if (this.props.barData !== undefined) {
      // console.log(this.props.chartData);
      plot = this.renderPlot()
    }

    return (
      <React.Fragment>
        {plot}
      </React.Fragment>
    )
  }
}

export default PlotlyChart
