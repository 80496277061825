import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap'
import axios from 'axios'
import shared from '../shared/shared'
// import { Redirect } from 'react-router-dom'
import ReportTable from './report-table/report-table'
import Cookies from 'js-cookie'
import './subheader.css'
class SubHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: null,
      isInternal: Cookies.get('Permissions') !== 'Distributor'
    }
  }

  selectYear (year) {
    this.setState({ selected: year })
  }

  buttonList () {
    let buttonList = []
    let date = new Date()
    for (let i = 1; date.getFullYear() + i >= 2015; i--) {
      if (i === 1 && date.getMonth() > 3) {
        buttonList.push(
          <Button className='mr-4' color='primary' outline={date.getFullYear() + i !== this.state.selected} onClick={() => this.selectYear(date.getFullYear() + i)} key={date.getFullYear() + i}>
            {date.getFullYear() + i}
          </Button>)
      } else if (i < 1) {
        buttonList.push(
          <Button className='mr-4' color='primary' outline={date.getFullYear() + i !== this.state.selected} onClick={() => this.selectYear(date.getFullYear() + i)} key={date.getFullYear() + i}>
            {date.getFullYear() + i}
          </Button>)
      }
    }
    return buttonList
  }

  async componentDidMount () {
    // if (this.state.isInternal) {
    await axios.get(shared.getEnvironmentURL('getServerTime'), { withCredentials: true })
      .then((response) => {
        this.setState({
          selected:
          new Date(response.data).getFullYear() === new Date().getFullYear()
            ? new Date().getMonth() > 3
              ? new Date(response.data).getFullYear() + 1
              : new Date().getFullYear()
            : new Date(response.data).getFullYear() + 1 })
      })
      .catch((error) => { shared.handleError(error) })
    // }
  }

  render () {
    return (

      // this.state.isInternal
      //   ?
      <React.Fragment>
        <Row>
          <h2 className='display-4 subheading'>SRS Information for FY { this.state.selected } </h2>
        </Row>
        <Row>
          {this.state.selected !== null ? this.buttonList() : null}
        </Row>
        &nbsp;
        <Row>
          <Col sm='12'>
            <ReportTable year={this.state.selected !== null ? this.state.selected : null} customer={this.props.customer} reRender={this.props.reRender} />
          </Col>
        </Row>
      </React.Fragment>
    // : <Redirect to='/unauthorized' />

    )
  }
}

export default SubHeader
