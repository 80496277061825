import React, { Component } from 'react'
import { Alert, Row, Col } from 'reactstrap'
import RigDashboardComponent from './dashboard-components/Rigs'
import DailyBacklogDashboardComponent from './dashboard-components/DailyBacklog'
import RigInProcessDashboardComponent from './dashboard-components/RigsInProcess'
// import ServiceReportsDashboardComponent from './dashboard-components/ServiceReports'
import Cookies from 'js-cookie'
import './Dashboard.css'
class DashboardComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userData: JSON.parse(decodeURIComponent(Cookies.get('Profile'))),
      mounted: true
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({ mounted: false }, () => {
        this.setState({ mounted: true })
      })
    }
  }
  render () {
    return (
      this.state.mounted
        ? <React.Fragment>
          <Alert className='welcome-banner' color='primary'>
            <h2 className='display-4'>
              <center>
              MacLean Customer Dashboard
              </center>
            </h2>
          </Alert>
          {/* <Alert className='welcome-banner-display-name' color='secondary'>
          <h4 className='display-4'>
            <center>
              {'Welcome ' + this.state.userData.displayName}
            </center>
          </h4>
        </Alert> */}
          <br />
          <Row>
            <Col sm={3}>
              <RigDashboardComponent />
            </Col>
            <Col sm={9}>
              {/* <Row>
              <Col sm={12}>
                <ServiceReportsDashboardComponent />
              </Col>
            </Row> */}
              <Row><Col sm={12}><RigInProcessDashboardComponent /></Col></Row>
            &nbsp;
              <Row><Col sm={12}><DailyBacklogDashboardComponent /></Col></Row>
            </Col>
          </Row>
        </React.Fragment>
        : null
    )
  }
}

export default DashboardComponent
