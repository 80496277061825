import React, { Component } from 'react'
import { Row, Col, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ExcelExporting extends Component {
  componentDidUpdate (prevProps, prevState) {
    if (this.props.loaded !== prevProps.loaded) {
      this.forceUpdate()
    }
  }
  render () {
    let columns = []
    this.props.columns.forEach(column => {
      // console.log(column.accessor)
      columns.push(
        <ExcelColumn
          key={column}
          label={column.Header}
          value={
            (col) => {
              if (column.Header === 'Report Date') {
                return new Date(col.ReportDate).toLocaleDateString()
              } else if (column.Header === 'Scope of Work Completed') {
                return col[column.accessor] ? 'Yes' : 'No'
              } else {
                return col[column.accessor]
              }
            }} />)
    })

    return (
      <Row>
        <Col sm='12'>
          <UncontrolledButtonDropdown>
            <DropdownToggle disabled={!this.props.loaded} outline caret>Export to Excel</DropdownToggle>
            <DropdownMenu>
              {/* <ExcelFile element={<DropdownItem color='secondary' outline='true'>Export filtered data</DropdownItem>}>
                <ExcelSheet data={this.props.filteredData} name='Service Report Data'>
                  <ExcelColumn label='ID' value={this.props.filteredData.id ? 'ID' : 'id'} />
                </ExcelSheet>
              </ExcelFile> */}
              <ExcelFile element={<DropdownItem color='secondary' outline='true'>Export data for full year</DropdownItem>}>
                <ExcelSheet data={this.props.unfilteredData} name='Service Report Data'>
                  {columns}
                </ExcelSheet>
              </ExcelFile>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
        &nbsp;
      </Row>
    )
  }
}

export default ExcelExporting
