import React, { Component } from 'react'
import './FaultTable.css'
import FaultRow from './FaultRow/FaultRow'
import { Alert, Table, Spinner } from 'reactstrap'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import shared from '../../../../shared/shared'
import axios from 'axios'

class FaultsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      spns: this.props.spns,
      spnData: undefined,
      sortByColumn: 'SPN',
      spnSorted: '',
      titleSorted: ''
    }
    this.sortSameOld = false
    this.spnData = undefined
    this.arraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) { return false }
      for (var i = arr1.length; i--;) {
        if (arr1[i] !== arr2[i]) { return false }
      }
      return true
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if ((!this.arraysEqual(nextProps.spns, this.state.spns)) || (nextState.spnData !== this.state.spnData)) {
      return true
    } else {
      return false
    }
  }

  getSpns () {
    this.spnData = []
    if ((this.props.table === null) || (this.props.table === undefined)) {
      for (let i = 0; i < this.state.spns.length; i++) {
        this.spnData.push({ spn: this.state.spns[i], title: undefined, description: undefined })
      }
      this.setState({
        spnData: this.spnData
      })
    } else {
      for (let i in this.state.spns) {
        this.getSpn(this.state.spns[i])
      }
    }
  }

  getSpn (spn) {
    let body = {
      spn: spn,
      table: this.props.table
    }

    axios.post(
      shared.getVmsHost('getSPN'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.data.length > 0) {
        this.spnData.push({ spn: response.data[0].spn, title: response.data[0].title, definition: response.data[0].description })
      } else {
        this.spnData.push({ spn: spn, title: undefined, description: undefined })
      }
      if (this.spnData.length === this.state.spns.length) {
        this.setState({
          spnData: this.spnData
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  sortByColumns (e) {
    // console.log(e);
    if (this.state.spnData === undefined) return

    let tempSpnList = this.state.spnData

    if (e === 'SPN') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ spnSorted: <MdKeyboardArrowUp />, titleSorted: '' })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) { return Number(a.spn) - Number(b.spn) })
      } else {
        this.setState({ spnSorted: <MdKeyboardArrowDown />, titleSorted: '' })
        tempSpnList.sort(function (a, b) { return Number(b.spn) - Number(a.spn) })
        this.sortSameOld = true
      }
    } else if (e === 'Title') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        this.sortSameOld = false
        this.setState({ spnSorted: '', titleSorted: <MdKeyboardArrowUp /> })
        tempSpnList.sort(function (a, b) {
          if (b.title === undefined) { return 1 }
          var x = b.title.toLowerCase()
          if (a.title === undefined) { return -1 }
          var y = a.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.sortSameOld = true
        this.setState({ spnSorted: '', titleSorted: <MdKeyboardArrowDown /> })
        tempSpnList.sort(function (a, b) {
          if (a.title === undefined) { return 1 }
          var x = a.title.toLowerCase()
          if (b.title === undefined) { return -1 }
          var y = b.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else {
      return
    }
    // console.log(tempSpnList);

    this.setState({
      spnData: tempSpnList,
      sortByColumn: e
    })
    this.forceUpdate()
  }

  renderTable () {
    return (
      <Table>
        <thead>
          <tr>
            <th className='sortable' onClick={() => this.sortByColumns('SPN')}>SPN {this.state.spnSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Title')}>Title {this.state.titleSorted}</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.spnData.map((el, index) => {
              return (<FaultRow key={index} spn={el.spn} title={el.title} definition={el.definition} />)
            })
          }
        </tbody>
      </Table>
    )
  }

  render () {
    let table = <Alert color='primary'><center><h3>No current faults found.</h3></center></Alert>
    if ((this.state.spns !== undefined) && (this.state.spnData === undefined)) {
      if (this.state.spns !== null) {
        if (this.state.spns.length > 0) {
          table = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
          this.getSpns()
        }
      }
    }

    if (this.state.spnData !== undefined) {
      table = this.renderTable()
    }
    return (
      <React.Fragment>
        {table}
      </React.Fragment>
    )
  }
}

export default FaultsTable
