import React, { Component } from 'react'
import { Spinner } from 'reactstrap'
import axios from 'axios'
import Cookies from 'js-cookie'
import ExternalReportTable from './report-table/external-report-table'
import InternalReportTable from './report-table/internal-report-table'
import shared from '../shared/shared'
class Backlogs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      timePeriod: 1,
      fetching: false,
      backlogData: [],
      isInternal: Cookies.get('Permissions') !== 'Distributor'
    }
    this.fetchInternalBacklogs = this.fetchInternalBacklogs.bind(this)
    this.fetchExternalBacklogs = this.fetchExternalBacklogs.bind(this)
  }
  fetchInternalBacklogs () {
    this.setState({ fetching: true })
    axios.get(shared.getEnvironmentURL('getBacklogs'),
      { withCredentials: true }
    )
      .then((response) => {
        this.setState({ backlogData: response.data, fetching: false })
        this.forceUpdate()
      })
      .catch((error) => { shared.handleError(error) })
  }
  fetchExternalBacklogs () {
    this.setState({ fetching: true })
    axios.get(shared.getEnvironmentURL('getBacklogsForDistributor'),
      { withCredentials: true }
    )
      .then((response) => {
        this.setState({ backlogData: response.data.backlogDetails, fetching: false, customerName: response.data.customerName || JSON.parse(Cookies.get('selectedCustomer')).key })
        this.forceUpdate()
      })
      .catch((error) => { shared.handleError(error) })
  }
  componentDidMount () {
    this.state.isInternal ? this.fetchInternalBacklogs() : this.fetchExternalBacklogs()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.customer.key !== this.props.customer.key) {
      this.componentDidMount()
    }
  }
  renderInternalView () {
    return (
      <InternalReportTable data={this.state.backlogData === undefined ? [] : this.state.backlogData} customerName={this.state.customerName === undefined ? null : this.state.customerName} />
    )
  }

  renderExternalView () {
    return (
      <ExternalReportTable data={this.state.backlogData === undefined ? [] : this.state.backlogData} customerName={this.state.customerName === undefined ? null : this.state.customerName} />
    )
  }
  render () {
    return (
      this.state.fetching
        ? <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        : <React.Fragment>
          <h2 className='display-4 text-center'>Daily Backlog{this.state.isInternal ? null : ` for ` + this.state.customerName}</h2>
          <br />
          {
            this.state.isInternal
              ? this.renderInternalView()
              : this.renderExternalView()
          }
        </React.Fragment>
    )
  }
}
export default Backlogs
