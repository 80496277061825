import React, { Component } from 'react'
import DateTimePicker from 'react-datepicker'
import ControlledInputSpan from './ControlledInputSpan'
import { Form, FormGroup, Label, Row, Col, Input } from 'reactstrap'
import Moment from 'moment'
import './Controls.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class controls extends Component {
  shouldComponentUpdate (nextProps) {
    if (nextProps.start.toDate() !== this.props.start.toDate()) {
      return true
    } else {
      return false
    }
  }

  render () {
    // console.log(this.props);

    return (
      <Form className='utilization-long-term-view-controls'>
        <Row form>
          <Col md={{ size: 3, offset: 1 }}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='beginning-date-time'>Beginning</Label>
              <DateTimePicker
                id='beginning-date-time'
                className='form-control'
                selected={this.props.start.toDate()}
                onChange={this.props.setStart}
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy'
                minDate={Moment(this.props.machineRangeStart).toDate()}
                maxDate={Moment(this.props.machineRangeEnd).toDate()} />
            </FormGroup>
          </Col>
          <Col md={{ size: 2 }}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='span-days'>Span</Label>
              <ControlledInputSpan units={this.props.units} onChange={this.props.setSpan} value={this.props.span} />
            </FormGroup>
          </Col>
          <Col md={{ size: 2 }}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='span-days'>Units</Label>
              <Input type='select' onChange={this.props.setUnit} value={this.props.stepUnit}>
                <option value='days'>days</option>
                <option value='months'>months</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='ending-date-time'>End</Label>
              <DateTimePicker
                id='ending-date-time'
                className='form-control'
                selected={this.props.end.toDate()}
                onChange={this.props.setEnd}
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy'
                minDate={Moment(this.props.machineRangeStart).toDate()}
                maxDate={Moment(this.props.machineRangeEnd).toDate()} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default controls
