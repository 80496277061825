import React, { Component } from 'react'
import TopBar from '../TopBar/TopBar'
import RadialIndicator from './RadialIndicator/RadialIndicator'
import './FleetUtilizationPane.css'
import { Col, Spinner } from 'reactstrap'

class fleetUtilizationPane extends Component {
  render () {
    let avg = 0

    if (this.props.data !== undefined) {
      let keys = Object.keys(this.props.data)
      let outOf = keys.length
      for (let i in keys) {
        if (!isNaN(this.props.data[keys[i]].utilization)) {
          avg += this.props.data[keys[i]].utilization
        } else {
          outOf -= 1
        }
      }
      avg = avg / outOf
      avg = (avg / 100) * 24
      avg = avg.toFixed(1)
    }

    let radialIndicator = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if (avg !== undefined) {
      radialIndicator = <RadialIndicator avg={avg} />
    }

    return (
      <Col xs='3' sm='3'>
        <TopBar headerText='Fleet Utilization' />
        {radialIndicator}
      </Col>
    )
  }
}

export default fleetUtilizationPane
