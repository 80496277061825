import React, { Component } from 'react'
import SideDrawer from './SideDrawer/SideDrawerDetailPane'
import OverviewEVWithAux from './Overviews/OverviewEVWithAux/OverviewEVs'
import OverviewEVBlockholer from './Overviews/OverviewEVBlockholer/OverviewEVs'
import OverviewEVGrader from './Overviews/OverviewEVGrader/OverviewEVs'
import UtilExplorerEVWithAux from './UtilExplorers/UtilExplorerEVWithAux/UtilExplorerEVs'
import UtilExplorerEVGrader from './UtilExplorers/UtilExplorerEVGrader/UtilExplorerEVs'
import UtilExplorerEVBlockholer from './UtilExplorers/UtilExplorerEVBlockholer/UtilExplorerEVs'
import PowerConsumptionEVs from './PowerConsumption/PowerConsumption'
import NotificationHistory from './Notifications/Notifications'
import './MachineDetailPane.css'
import FaultDetail from './Faults/Faults'
import DataExplorer from './DataExplorer/DataExplorer'
import { Modal, ModalHeader, ModalBody, Row, Col, Spinner } from 'reactstrap'

class machineDetailPane extends Component {
  constructor (props) {
    super(props)

    this.state = {
      details: this.props.details,
      currentView: this.props.view,
      machineTables: undefined
    }

    this.allowGetTables = true
    this.tables = undefined
    this.machineTables = undefined
    this.overviewUtilizationData = undefined

    this.deProps = {
      view: undefined,
      start: undefined
    }
    this.toggle = this.toggle.bind(this)
    this.withAuxTypes = ['scissor_EV', 'bolter_EV', 'cassette_EV', 'emulsion_EV', 'boom_EV']
    this.possibleTypes = ['scissor_EV', 'bolter_EV', 'cassette_EV', 'grader_EV', 'blockholer_EV', 'boom_EV', 'emulsion_EV']
  }

  getBackFirstUtilData (data) {
    this.overviewUtilizationData = data
  }

  changedViewHandler (view, pprops = undefined) {
    // console.log(view);

    if (pprops === undefined) {
      this.deProps.view = undefined
      this.deProps.start = undefined
      this.setState({
        currentView: view
      })
    } else {
      this.deProps.view = pprops[0]
      this.deProps.start = pprops[1]
      this.setState({
        currentView: view
      })
    }
  }

  toggle () {
    this.props.toggle()
  }
  render () {
    if ((this.state.details.last_row === 'n') || (this.possibleTypes.indexOf(this.state.details.type) < 0)) {
      return (
        <Modal isOpen={this.props.isMachineDetailsPaneOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
                      Machine {this.state.currentView}: {this.state.details.serial_num}
          </ModalHeader>
          <ModalBody>
            <h1>Sorry, machine details not available for {this.state.details.serial_num}.</h1>
          </ModalBody>
        </Modal>
      )
    } else {
      let currentView = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

      if (this.state.details !== undefined) {
        switch (this.state.currentView) {
          case 'Overview':
            if (this.withAuxTypes.indexOf(this.state.details.type) > -1) {
              currentView = <OverviewEVWithAux ajaxError={this.props.ajaxError} details={this.state.details} getBackFirstUtilData={this.getBackFirstUtilData.bind(this)} />
            } else if (this.state.details.type === 'grader_EV') {
              currentView = <OverviewEVGrader ajaxError={this.props.ajaxError} details={this.state.details} getBackFirstUtilData={this.getBackFirstUtilData.bind(this)} />
            } else if (this.state.details.type === 'blockholer_EV') {
              currentView = <OverviewEVBlockholer ajaxError={this.props.ajaxError} details={this.state.details} getBackFirstUtilData={this.getBackFirstUtilData.bind(this)} />
            } else {
              currentView = <h1>Sorry, overview not available for vehicle type {this.state.details.type}</h1>
            }
            break

          case 'Notification History':
            currentView = <NotificationHistory ajaxError={this.props.ajaxError} details={this.state.details} changeView={this.changedViewHandler.bind(this)} />
            // console.log(this.state.currentView);
            break

          case 'Fault History':
            currentView = <FaultDetail ajaxError={this.props.ajaxError} details={this.state.details} />
            // console.log(this.state.currentView);
            break

          case 'Utilization Explorer':
            if (this.withAuxTypes.indexOf(this.state.details.type) > -1) {
              currentView = <UtilExplorerEVWithAux ajaxError={this.props.ajaxError} details={this.state.details} overviewData={this.overviewUtilizationData} />
            } else if (this.state.details.type === 'grader_EV') {
              currentView = <UtilExplorerEVGrader ajaxError={this.props.ajaxError} details={this.state.details} overviewData={this.overviewUtilizationData} />
            } else if (this.state.details.type === 'blockholer_EV') {
              currentView = <UtilExplorerEVBlockholer ajaxError={this.props.ajaxError} details={this.state.details} overviewData={this.overviewUtilizationData} />
            } else {
              currentView = <h1>Sorry, utilization explorer not available for vehicle type {this.state.details.type}</h1>
            }
            break

          case 'Data Explorer':
            // console.log(this.state.currentView);
            currentView = <DataExplorer ajaxError={this.props.ajaxError} details={this.state.details} deProps={this.deProps} />
            break

          case 'Power Consumption':
            // console.log(this.state.currentView);
            currentView = <PowerConsumptionEVs ajaxError={this.props.ajaxError} details={this.state.details} />
            break
          default:
        }
      }

      return (
        <Modal isOpen={this.props.isMachineDetailsPaneOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
                      Machine {this.state.currentView}: {this.state.details.serial_num}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs='3'>
                <SideDrawer details={this.state.details} currentView={this.state.currentView} clickedHandler={this.changedViewHandler.bind(this)} />
              </Col>
              <Col xs='9'>
                {currentView}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )
    }
  }
}

export default machineDetailPane
