import React, { Component } from 'react'
import Controls from '../../Controls/Controls'
import Moment from 'moment'
import Plot from 'react-plotly.js'
import { Spinner, Alert } from 'reactstrap'
import './Plots.css'
import shared from '../../../../../shared/shared'
import axios from 'axios'

class BatterySOC extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: undefined,
      end: undefined,
      rangeStart: undefined,
      rangeEnd: undefined,
      span: undefined,
      stepUnit: undefined,
      step: undefined,
      noData: false,
      setEnd: true,
      getData: false,
      plotData: undefined
    }

    this.view = 'Battery State of Charge'

    this.step = undefined
    this.stepUnit = undefined

    this.whichSignals = () => {
      switch (this.props.details.type) {
        // case "blockholer_EV":
        //   return [
        //     'StateOfCharge',
        //     'BatteryDcCurrent',
        //     'BatteryDcVoltage',
        //     'Charger1Running',
        //     'Charger2Running',
        //     'Charger3Running',
        //     'Charger4Running',
        //     'BatteryState',
        //     'InDrillMode',
        //     'ManageCharger1State',
        //     'ManageSystemState'
        //   ]

        case 'grader_EV':
          return [
            'StateOfCharge',
            'BatteryDcCurrent',
            'BatteryDcVoltage',
            'Charger1Running',
            'Charger2Running',
            'Charger3Running',
            'Charger4Running',
            'ManageSystemState',
            'ManageCharger1State',
            'BatteryState'
          ]

        default:
          return [
            'StateOfCharge',
            'BatteryDcCurrent',
            'BatteryDcVoltage',
            'Charger1Running',
            'Charger2Running',
            'Charger3Running',
            'Charger4Running',
            'BatteryState',
            'InAuxMode',
            'ManageCharger1State'
          ]
      }
    }

    this.signals = this.whichSignals()
  }

  getMachineDateRange () {
    console.log('getMachineDateRange')
    let body = {
      machine: this.props.details.serial_num,
      fields: this.signals
    }

    axios.post(
      shared.getVmsHost('machineDaterange'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.data.starts !== undefined) {
        this.setState({
          rangeStart: response.data.starts,
          rangeEnd: response.data.ends
        }, () => {
          this.setFirstStart()
        })
      } else {
        this.setState({
          noData: true
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  setFirstStart () {
    if (this.props.start === undefined) {
      if (this.props.deProps.start === undefined) {
        this.stepUnit = 'hours'
        this.step = 1
        this.setState({
          start: Moment(this.state.rangeEnd).subtract(1, 'hours'),
          end: Moment(this.state.rangeEnd),
          span: 1,
          step: 1,
          stepUnit: 'hours',
          getData: true
        }, () => { this.props.getBack(Moment(this.state.rangeEnd).subtract(1, 'hours'), Moment(this.state.rangeEnd)) })
      } else { // case where start comes from outside (notifications)
        let start = this.props.deProps.start.clone().subtract(1, 'minutes')
        let end = this.props.deProps.start.clone().add(1, 'minutes')
        this.stepUnit = 'minutes'
        this.step = 2
        this.setState({
          start: start,
          end: end,
          span: 2,
          step: 2,
          stepUnit: 'minutes',
          getData: true
        }, () => { this.props.getBack(start, end) })
      }
    } else {
      this.stepUnit = 'hours'
      this.step = 1
      this.setState({
        start: this.props.start,
        end: this.props.end,
        span: 1,
        step: 1,
        stepUnit: 'hours',
        getData: true
      })
    }
  }

  setStart (e) {
    let start = Moment(e).format()

    start = Moment(start)
    let end = start.clone()
    end.add(this.state.span, this.state.stepUnit)

    // console.log(start);

    this.setState({
      start: start,
      end: end,
      setEnd: false,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  setEnd (e) {
    let end = Moment(e).format()
    end = Moment(end)
    let start = end.clone()
    start = start.subtract(this.state.span, this.state.stepUnit)

    this.setState({
      end: end,
      start: start,
      setEnd: true,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  setSpan (e) {
    if (this.state.setEnd) {
      let start = this.state.end.clone()
      start.subtract(e, this.state.stepUnit)

      this.setState({
        span: e,
        start: start,
        plotData: undefined,
        getData: true
      }, () => { this.props.getBack(start, this.state.end) })
    } else {
      let end = this.state.start.clone()
      end.add(e, this.state.stepUnit)

      this.setState({
        span: e,
        end: end,
        plotData: undefined,
        getData: true
      }, () => { this.props.getBack(this.state.start, end) })
    }
  }

  setUnit (e) {
    this.stepUnit = e.target.value
    let span = this.state.end.diff(this.state.start, e.target.value)
    this.setState({
      span: span,
      stepUnit: this.stepUnit
    })
    // console.log(this.stepUnit);
  }

  setStep (e) {
    this.step = e
    this.setState({
      step: this.step
    })
    // console.log(this.step);
  }

  forward () {
    // console.log(JSON.stringify(Moment(this.state.start)));
    let start = this.state.start.clone().subtract(this.step, this.stepUnit)
    let end = this.state.end.clone().subtract(this.step, this.stepUnit)

    this.setState({
      start: start,
      end: end,
      stepUnit: this.stepUnit,
      step: this.step,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  back () {
    // console.log(JSON.stringify(Moment(this.state.start).add(this.step, this.stepUnit)));
    let start = this.state.start.clone().add(this.step, this.stepUnit)
    let end = this.state.end.clone().add(this.step, this.stepUnit)

    this.setState({
      start: start,
      end: end,
      stepUnit: this.stepUnit,
      step: this.step,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  getData () {
    let body = {
      machine: this.props.details.serial_num,
      fields: this.signals,
      date_end: this.state.end.format().replace('T', ' ').slice(0, 19),
      date_start: this.state.start.format().replace('T', ' ').slice(0, 19)
    }

    axios.post(
      shared.getVmsHost('getData'),
      body, { withCredentials: true }
    ).then((response) => {
      this.setState({
        plotData: response.data,
        getData: false
      })
    }).catch((error) => { shared.handleError(error) })
  }

  renderPlot () {
    // console.log('renderPlot');
    let plotData = { datetime: [], StateOfCharge: [], BattPower: [], Charging: { val: [], base: [] } }
    let auxiliaryData
    let range = []
    let timeKey = {}
    let start = this.state.start.clone()
    let data

    while (this.state.end.diff(start, 'seconds') > 0) {
      range.push(start.clone().format().slice(0, 19))
      start.add(1, 'seconds')
    }

    for (let i in this.state.plotData) {
      timeKey[Moment.utc(this.state.plotData[i].datetime.slice(0, 19)).format().slice(0, 19)] = this.state.plotData[i]
    }

    let timeKeyKeys = Object.keys(timeKey)

    for (let i in range) {
      // console.log(range[i], timeKeyKeys.indexOf(range[i]))
      if (timeKeyKeys.indexOf(range[i]) > -1) {
        plotData.datetime.push(range[i])
        plotData.StateOfCharge.push(timeKey[range[i]].StateOfCharge)
        plotData.BattPower.push(0 - (timeKey[range[i]].BatteryDcCurrent * timeKey[range[i]].BatteryDcVoltage) / 1000)
        plotData.Charging.val.push((timeKey[range[i]].Charger1Running || timeKey[range[i]].Charger2Running || timeKey[range[i]].Charger3Running || timeKey[range[i]].Charger4Running) ? 10 : undefined)
        plotData.Charging.base.push((timeKey[range[i]].Charger1Running || timeKey[range[i]].Charger2Running || timeKey[range[i]].Charger3Running || timeKey[range[i]].Charger4Running) ? 30 : undefined)
      } else {
        plotData.datetime.push(range[i])
        plotData.StateOfCharge.push(undefined)
        plotData.BattPower.push(undefined)
        plotData.Charging.val.push(undefined)
        plotData.Charging.base.push(undefined)
      }
    }

    data = [
      {
        name: 'Battery Power',
        type: 'bar',
        marker: { color: 'rgba(255, 74, 83, 0.8)' },
        x: plotData.datetime,
        y: plotData.BattPower,
        xaxis: 'x1',
        yaxis: 'y2',
        connectgaps: false
      },
      {
        name: 'State of Charge',
        type: 'line',
        marker: { color: 'rgb(107, 163, 255)' },
        // fill: 'tonexty',
        // fillcolor: 'rgba(107, 163, 255, 0.3)',
        x: plotData.datetime,
        y: plotData.StateOfCharge,
        connectgaps: false,
        xaxis: 'x1',
        yaxis: 'y'
      },
      {
        name: 'Charging',
        type: 'bar',
        marker: { color: 'rgba(6, 193, 0, 0.4)' },
        x: plotData.datetime,
        y: plotData.Charging.val,
        base: plotData.Charging.base,
        hoverinfo: 'skip',
        // fill: "tozeroy",
        // fillcolor: 'rgba(6, 193, 0, 0.4)',
        xaxis: 'x1',
        yaxis: 'y1',
        connectgaps: false
      }
    ]

    switch (this.props.details.type) {
      // case "blockholer_EV":
      //   auxiliaryData = { drillMode: {val: [], base:[]}, tramMode: {val: [], base:[]}, remoteMode: {val: [], base:[]} }

      //   for (let i in range) {
      //     // console.log(range[i], timeKeyKeys.indexOf(range[i]))
      //     if (timeKeyKeys.indexOf(range[i]) > -1) {
      //       auxiliaryData.tramMode.val.push((timeKey[range[i]].ManageSystemState === 4) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
      //       auxiliaryData.remoteMode.val.push((timeKey[range[i]].ManageSystemState === 6) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
      //       auxiliaryData.drillMode.val.push((timeKey[range[i]].InDrillMode) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
      //       auxiliaryData.tramMode.base.push((timeKey[range[i]].ManageSystemState === 4) && (timeKey[range[i]].BatteryState === 3) ? 0 : undefined)
      //       auxiliaryData.remoteMode.base.push((timeKey[range[i]].ManageSystemState === 6) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
      //       auxiliaryData.drillMode.base.push((timeKey[range[i]].InDrillMode) && (timeKey[range[i]].BatteryState === 3) ? 20 : undefined)
      //     } else {
      //       auxiliaryData.tramMode.val.push(undefined)
      //       auxiliaryData.remoteMode.val.push(undefined)
      //       auxiliaryData.drillMode.val.push(undefined)
      //       auxiliaryData.tramMode.base.push(undefined)
      //       auxiliaryData.remoteMode.base.push(undefined)
      //       auxiliaryData.drillMode.base.push(undefined)
      //     }

      //     data.push(
      //       {
      //         name: 'Tram Mode',
      //         type: 'bar',
      //         marker: { color: 'rgba(188, 58, 189, 0.4)' },
      //         x: plotData.datetime,
      //         y: auxiliaryData.tramMode.val,
      //         base: auxiliaryData.tramMode.base,
      //         hoverinfo: 'skip',
      //         xaxis: 'x1',
      //         yaxis: 'y1'
      //       }
      //     )
      //     data.push(
      //       {
      //         name: 'Remote Mode',
      //         type: 'bar',
      //         marker: { color: 'rgba(168, 50, 50, 0.4)' },
      //         x: plotData.datetime,
      //         y: auxiliaryData.remoteMode.val,
      //         base: auxiliaryData.remoteMode.base,
      //         hoverinfo: 'skip',
      //         xaxis: 'x1',
      //         yaxis: 'y1'
      //       }
      //     )
      //     data.push(
      //       {
      //         name: 'Drill Mode',
      //         type: 'bar',
      //         marker: { color: 'rgba(58, 125, 189, 0.4)' },
      //         x: plotData.datetime,
      //         y: auxiliaryData.drillMode.val,
      //         base: auxiliaryData.drillMode.base,
      //         hoverinfo: 'skip',
      //         xaxis: 'x1',
      //         yaxis: 'y1',
      //       }
      //     )
      //   }

      //   break

      case 'grader_EV':
        auxiliaryData = { tramMode: { val: [], base: [] } }

        for (let i in range) {
          // console.log(range[i], timeKeyKeys.indexOf(range[i]))
          if (timeKeyKeys.indexOf(range[i]) > -1) {
            plotData.datetime.push(range[i])
            auxiliaryData.tramMode.val.push((timeKey[range[i]].ManageSystemState === 3) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
            auxiliaryData.tramMode.base.push((timeKey[range[i]].ManageSystemState === 3) && (timeKey[range[i]].BatteryState === 3) ? 0 : undefined)
          } else {
            plotData.datetime.push(range[i])
            auxiliaryData.tramMode.val.push(undefined)
            auxiliaryData.tramMode.base.push(undefined)
          }
        }

        data.push(
          {
            name: 'Tram Mode',
            type: 'bar',
            marker: { color: 'rgba(188, 58, 189, 0.4)' },
            x: plotData.datetime,
            y: auxiliaryData.tramMode.val,
            base: auxiliaryData.tramMode.base,
            hoverinfo: 'skip',
            xaxis: 'x1',
            yaxis: 'y1'
          }
        )

        break

      default:
        auxiliaryData = { tramMode: { val: [], base: [] }, auxMode: { val: [], base: [] } }

        for (let i in range) {
          // console.log(range[i], timeKeyKeys.indexOf(range[i]))
          if (timeKeyKeys.indexOf(range[i]) > -1) {
            auxiliaryData.tramMode.val.push((!timeKey[range[i]].InAuxMode) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
            auxiliaryData.auxMode.val.push((timeKey[range[i]].InAuxMode) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
            auxiliaryData.tramMode.base.push((!timeKey[range[i]].InAuxMode) && (timeKey[range[i]].BatteryState === 3) ? 0 : undefined)
            auxiliaryData.auxMode.base.push((timeKey[range[i]].InAuxMode) && (timeKey[range[i]].BatteryState === 3) ? 10 : undefined)
          } else {
            auxiliaryData.tramMode.val.push(undefined)
            auxiliaryData.auxMode.val.push(undefined)
            auxiliaryData.tramMode.base.push(undefined)
            auxiliaryData.auxMode.base.push(undefined)
          }
        }

        data.push(
          {
            name: 'Tram Mode',
            type: 'bar',
            marker: { color: 'rgba(188, 58, 189, 0.4)', width: 1 },
            x: plotData.datetime,
            y: auxiliaryData.tramMode.val,
            base: auxiliaryData.tramMode.base,
            hoverinfo: 'skip',
            xaxis: 'x1',
            yaxis: 'y1'
          }
        )
        data.push(
          {
            name: 'Aux Mode',
            type: 'bar',
            marker: { color: 'rgba(58, 125, 189, 0.4)', width: 1 },
            x: plotData.datetime,
            y: auxiliaryData.auxMode.val,
            base: auxiliaryData.auxMode.base,
            hoverinfo: 'skip',
            xaxis: 'x1',
            yaxis: 'y1'
          }
        )
    }

    return <Plot
      data={data}
      useResizeHandler
      style={{ width: '100%', height: '50vh' }}
      config={{
        displaylogo: false,
        // showSendToCloud: true,
        modeBarButtonsToRemove: ['lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage']
      }}
      layout={{
        xaxis: { range: [JSON.stringify(this.state.start.format()).slice(1, 20).replace('T', ' '),
          JSON.stringify(this.state.end.format()).slice(1, 20).replace('T', ' ')] },
        bargap: 0,
        barmode: 'overlay',
        autosize: true,
        title: this.props.details.serial_num + ': Battery State of Charge',
        legend: { x: 1.05 },
        yaxis: { range: [0, 100], ticksuffix: '%', anchor: 'x', tickfont: { color: 'rgb(107, 163, 255)' } },
        yaxis2: { range: [-100, 100], side: 'right', overlaying: 'y', ticksuffix: 'KW', showgrid: false, tickfont: { color: 'rgb(255, 74, 83)' } }
      }}
    />
  }

  renderControls () {
    return (
      <Controls
        start={this.state.start}
        setStart={this.setStart.bind(this)}
        end={this.state.end}
        span={this.state.span}
        step={this.state.step}
        setStep={this.setStep.bind(this)}
        units={this.state.stepUnit}
        setEnd={this.setEnd.bind(this)}
        setSpan={this.setSpan.bind(this)}
        setUnit={this.setUnit.bind(this)}
        forward={this.forward.bind(this)}
        back={this.back.bind(this)}
        rangeStart={this.state.rangeStart}
        rangeEnd={this.state.rangeEnd}
      />
    )
  }

  render () {
    console.log(this.props)

    let plot = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    let controls = null

    if ((this.state.noData === false) && (this.state.rangeStart === undefined)) {
      this.getMachineDateRange()
    }

    if (this.state.stepUnit !== undefined) {
      controls = this.renderControls()
    }

    if (this.state.getData) {
      this.getData()
    }

    if (this.state.plotData !== undefined) {
      if (this.state.plotData.length > 0) {
        plot = this.renderPlot()
      } else {
        plot = <Alert color='info'>No data available for selected timeframe</Alert>
      }
    }

    if (this.state.noData) {
      plot = <Alert color='info'>Required data missing for {this.view} view</Alert>
    }

    return (
      <React.Fragment>
        {controls}
        {plot}
      </React.Fragment>
    )
  }
}

export default BatterySOC
