import React, { Component } from 'react'
import PowerPlot from './PowerPlot/PowerPlot'
import Controls from './Controls/Controls'
import Moment from 'moment'
import './PowerConsumption.css'
import { Alert, Spinner } from 'reactstrap'
import shared from '../../../shared/shared'
import axios from 'axios'

class PowerConsumptionEVs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machineDetails: this.props.details,
      machine_ID: this.props.details.serial_num,
      powerData: undefined,
      lastdata: undefined,
      firstdata: undefined,
      exists: undefined,
      span: undefined,
      stepUnit: 'days',
      step: 30,
      setEnd: true
    }

    this.machineIDs = undefined
    this.exists = undefined
    this.stepUnit = 'days'
    this.step = 30
  }

  getLastTimestamps () {
    axios.get(
      shared.getVmsHost('getPowerTables'),
      { withCredentials: true }
    ).then((response) => {
      this.machineIDs = response.data
      this.checkExists()
    }).catch((error) => { shared.handleError(error) })
  }

  checkExists () {
    for (let i = 0; i < this.machineIDs.length; i++) {
      if (this.state.machine_ID === this.machineIDs[i].table_name) {
        this.exists = true
        break
      }
    }

    if (this.exists === undefined) {
      this.setState({
        exists: false
      })
    } else {
      this.getFirstTimestamp()
    }
  }

  getFirstTimestamp () {
    let body = {
      machine: this.state.machine_ID
    }

    axios.post(
      shared.getVmsHost('getFirstPowerData'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(data);
      if (response.data.length > 0) {
        let first = Moment.utc(response.data[0].timechunk.slice(0, 19))
        let last = Moment.utc(response.data[1].timechunk.slice(0, 19))
        this.setState({
          firstdata: first,
          lastdata: last,
          exists: true,
          span: last.diff(first, 'days', true).toFixed(1)
        })
      } else {
        this.setState({
          exists: false
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

    setStart = (e) => {
      let start = e.format()

      // console.log(start)

      start = Moment.utc(start)
      let end = start.clone()
      end.add(this.state.span, this.state.stepUnit)

      // console.log(start);

      this.setState({
        firstdata: start,
        lastdata: end,
        setEnd: false,
        span: this.state.lastdata.diff(this.state.firstdata, this.state.stepUnit, true).toFixed(1)
      })
    }

    setEnd (e) {
      let end = e.format()
      end = Moment.utc(end)
      let start = end.clone()
      start = start.subtract(this.state.span, this.state.stepUnit)

      this.setState({
        lastdata: end,
        firstdata: start,
        setEnd: true,
        span: this.state.lastdata.diff(this.state.firstdata, this.state.stepUnit, true).toFixed(1)
      })
    }

    setSpan = (e) => {
      // console.log(e);

      if (this.state.setEnd) {
        let start = this.state.lastdata.clone()
        start.subtract(e, this.stepUnit)

        this.setState({
          span: e,
          firstdata: start
        })
      } else {
        let end = this.state.firstdata.clone()
        end.add(e, this.stepUnit)

        this.setState({
          span: e,
          stepUnit: this.stepUnit,
          lastdata: end
        })
      }
    }

    setUnit = (e) => {
      this.stepUnit = e.target.value
      this.setState({
        stepUnit: this.stepUnit,
        span: this.state.lastdata.diff(this.state.firstdata, this.stepUnit, true).toFixed(1)
      })
      // console.log(this.stepUnit);
    }

    setStep = (e) => {
      this.step = e
      // console.log(this.step);
    }

    forward = () => {
      // console.log(JSON.stringify(Moment(this.state.start)));

      this.setState({
        start: this.state.firstdata.subtract(this.step, this.stepUnit),
        end: this.state.lastdata.subtract(this.step, this.stepUnit),
        stepUnit: this.stepUnit,
        step: this.step,
        span: this.state.lastdata.diff(this.state.firstdata, this.state.stepUnit, true).toFixed(1)
      })
    }

    back = () => {
      // console.log(JSON.stringify(Moment(this.state.start).add(this.step, this.stepUnit)));

      this.setState({
        start: this.state.firstdata.add(this.step, this.stepUnit),
        end: this.state.lastdata.add(this.step, this.stepUnit),
        stepUnit: this.stepUnit,
        step: this.step,
        span: this.state.lastdata.diff(this.state.firstdata, this.state.stepUnit, true).toFixed(1)
      })
    }

    render () {
      // console.log(this.props);

      let powerPlot = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
      let controls = null

      if (this.state.exists === undefined) {
        this.getLastTimestamps()
      } else if (!this.state.exists) {
        powerPlot = <Alert color='primary'><center><h3>Power consumption data not available for {this.state.machine_ID}.</h3></center></Alert>
      } else {
        // console.log(this.state);

        controls = <Controls
          start={this.state.firstdata}
          end={this.state.lastdata}
          setStart={this.setStart.bind(this)}
          setEnd={this.setEnd.bind(this)}
          setSpan={this.setSpan.bind(this)}
          setUnit={this.setUnit.bind(this)}
          setStep={this.setStep.bind(this)}
          span={this.state.span}
          step={this.state.step}
          stepUnit={this.state.stepUnit}
          forward={this.forward.bind(this)}
          back={this.back.bind(this)} />
        // console.log(JSON.stringify(this.state.firstdata.format()));
        powerPlot = <PowerPlot ajaxError={this.props.ajaxError} start={this.state.firstdata} end={this.state.lastdata} machine={this.state.machine_ID} />
      }

      return (
        <React.Fragment>
          {controls}
          {powerPlot}
        </React.Fragment>
      )
    }
}

export default PowerConsumptionEVs
