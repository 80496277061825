import React, { Component } from 'react'
import { Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import shared from '../../shared/shared'
import ReactTable from 'react-table'
import NewPPRModal from './NewPPRModal'
import classnames from 'classnames'

class ListReportsComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      processedRows: [],
      unprocessedRows: [],
      assignedRows: [],
      selectedPPR: null,
      processedColumns: [
        { accessor: 'id',
          Header: 'ID',
          Cell: props => {
            return (<a href={`list-report/${props.original.id === -1 ? props.original.transientID + '?transientID=true' : props.original.id}`}>{props.original.id === -1 ? props.original.transientID : props.original.id}</a>)
          }
        },
        { accessor: 'title', Header: 'Title' },
        { accessor: 'location', Header: 'Location' },
        { accessor: 'site', Header: 'Site' },
        { accessor: 'serviceTech', Header: 'Created By' },
        { accessor: 'customer', Header: 'Customer' },
        { accessor: 'status', Header: 'Status' }
      ],
      unprocessedColumns: [
        { accessor: 'id',
          Header: 'ID',
          Cell: props => {
            return (<a href={`list-report/${props.value + '?transientID=true'}`}>{props.value}</a>)
          }
        },
        { accessor: 'title', Header: 'Title' },
        { accessor: 'location', Header: 'Location' },
        { accessor: 'site', Header: 'Site' },
        { accessor: 'serviceTech', Header: 'Created By' },
        { accessor: 'customer', Header: 'Customer' },
        { accessor: 'state',
          Header: 'State',
          Cell: props => {
            return (props.original.state === 'Error'
              ? <React.Fragment>
                {props.value + ': ' + props.original.errors}
              </React.Fragment>
              : props.value)
          } },
        { Header: 'Actions',
          Cell: props => {
            return (
              (props.original.pprID === -1 && (['Unprocessed', 'Error'].indexOf(props.original.state) !== -1))
                ? <React.Fragment>
                  <Button color='primary'
                    onClick={async () => {
                      await this.togglePprLock(props.original.id).then(() => {
                        this.setState({ selectedPPR: props.original.id })
                      })
                      await this.fetchPPRData(props.original.id).then(() => { this.toggle() })
                    }}
                  > Edit </Button>
                  &nbsp;
                  <Button color='danger' onClick={async () => {
                    this.setState({ selectedPPR: props.original.id })
                    await this.togglePprLock(props.original.id)
                    this.toggleDeleteConfirmModal()
                  }}> Delete </Button>
                </React.Fragment>
                : null
            )
          } }
      ],
      assignedColumns: [
        { accessor: 'id',
          Header: 'ID',
          Cell: props => {
            return (<a href={`list-report/${props.original.id === -1 ? props.original.transientID + '?transientID=true' : props.original.id}`}>{props.original.id === -1 ? props.original.transientID : props.original.id}</a>)
          }
        },
        { accessor: 'title', Header: 'Title' },
        { accessor: 'location', Header: 'Location' },
        { accessor: 'site', Header: 'Site' },
        { accessor: 'serviceTech', Header: 'Created By' },
        { accessor: 'customer', Header: 'Customer' },
        { accessor: 'status', Header: 'Status' }
      ],
      modal: false,
      deleteConfirmModal: false,
      data: {},
      tooltipOpen: false,
      activeTab: 1
    }
    this.toggle = this.toggle.bind(this)
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
    this.reRenderTable = this.reRenderTable.bind(this)
  }
  toggleTooltip () {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }
  componentDidMount () {
    const processedPPR = shared.getEnvironmentURL('getPPRData')
    const unprocessedPPR = shared.getEnvironmentURL('getUnprocessedPPRData')
    const assignedPPR = shared.getEnvironmentURL('getAssignedPPRData')
    this.setState({ loaded: false })
    axios.all([
      axios.get(processedPPR, { withCredentials: true }),
      axios.get(unprocessedPPR, { withCredentials: true }),
      axios.get(assignedPPR, { withCredentials: true })
    ]).then(
      axios.spread((processedResponse, unprocessedResponse, assignedResponse) => {
        this.setState({ processedRows: processedResponse.data, unprocessedRows: unprocessedResponse.data, assignedRows: assignedResponse.data, loaded: true })
      })).catch((error) => {
      shared.handleError(error)
    })
  }
  async togglePprLock (pprID) {
    await axios.get(shared.getEnvironmentURL(`togglePPRLock/${pprID}`), { withCredentials: true })
  }
  async fetchPPRData (pprID) {
    await axios.get(shared.getEnvironmentURL(`getPPR/${pprID}`) + `?transient=true`, { withCredentials: true })
      .then(async (result) => {
        await this.setState({ data: result.data })
      })
  }
  async deletePPR (pprID = this.state.selectedPPR) {
    if (pprID !== null) {
      await axios.delete(shared.getEnvironmentURL(`submitPPR`),
        { withCredentials: true, data: { id: pprID } })
        .then(async result => {
          this.toggleDeleteConfirmModal().then(() => { this.reRenderTable() })
        })
    }
  }
  toggle () {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }
  async toggleDeleteConfirmModal () {
    await this.setState(prevState => ({
      deleteConfirmModal: !prevState.deleteConfirmModal
    }))
  }
  changeTabs (tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  reRenderTable () {
    this.componentDidMount()
    this.forceUpdate()
  }
  renderDeleteConfirmModal () {
    return (
      <Modal isOpen={this.state.deleteConfirmModal} toggle={this.toggleDeleteConfirmModal} backdrop='static'>
        <ModalHeader toggle={this.toggleDeleteConfirmModal}>Delete PPR</ModalHeader>
        <ModalBody>
          <h6><center>Are you sure you want to delete this PPR?</center></h6>
        </ModalBody>
        <ModalFooter>
          <center>
            <Button color='primary' type='submit'onClick={async () => {
              this.deletePPR(this.state.selectedPPR)
            }}>Delete</Button>{' '}
            <Button color='secondary' type='button' onClick={async () => {
              await this.togglePprLock(this.state.selectedPPR).then(() => { this.toggleDeleteConfirmModal() })
            }}>Cancel</Button>
          </center>
        </ModalFooter>
      </Modal>
    )
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      this.state.loaded
        ? <React.Fragment>
          <h1 className='display-4 text-center'>Manage Reports</h1>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.changeTabs(1) }}> Assigned PPRs </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.changeTabs(2) }}> Processed PPRs </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === 3 })} onClick={() => { this.changeTabs(3) }}> Unprocessed PPRs </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={1}>
              <ReactTable filterable defaultPageSize={10} data={this.state.assignedRows} columns={this.state.assignedColumns} filterAll defaultFilterMethod={this.filterCaseInsensitive} className='-striped -highlight' />
            </TabPane>
            <TabPane tabId={2}>
              <ReactTable filterable defaultPageSize={10} data={this.state.processedRows} columns={this.state.processedColumns} filterAll defaultFilterMethod={this.filterCaseInsensitive} className='-striped -highlight' />
            </TabPane>
            <TabPane tabId={3}>
              <ReactTable filterable defaultPageSize={10} data={this.state.unprocessedRows} columns={this.state.unprocessedColumns} filterAll defaultFilterMethod={this.filterCaseInsensitive} className='-striped -highlight' />
            </TabPane>
          </TabContent>
      &nbsp;
          <Row>
            <Col sm={{ size: 2, offset: 4 }}>
              <Button size='lg' color='primary' tag={Link} to='/'>&nbsp; &nbsp; &nbsp; Back &nbsp; &nbsp; &nbsp;</Button>
            </Col>
            <Col sm={{ size: 3 }}>
              <Button size='lg' color='secondary' onClick={async () => {
                await this.setState({ data: {} }, this.toggle)
              }}>Create New PPR</Button>
            </Col>
          </Row>
      &nbsp;
          {this.state.modal ? <NewPPRModal modal={this.state.modal} toggle={this.toggle} data={this.state.data} reRenderTable={this.reRenderTable} /> : null }
          {this.renderDeleteConfirmModal()}
        </React.Fragment>
        : <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />

    )
  }
}

export default ListReportsComponent
