import React, { Component } from 'react'

class ControlledInputWithInternalState extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }
    this.value = props.value
    this.valid = true
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      // new value from the parent - copy it to state
      this.setState({ value: nextProps.value })
    }
  }

    onValueChanged = (e) => {
      const isValid = ((!isNaN(e.target.value)) && (e.target.value > 0) && (e.target.value <= 900))
      if (!isValid) {
        this.valid = false
      } else {
        this.valid = true
      }
      this.setState({
        value: e.target.value
      })
    }

    updateParent = (e) => {
      if (e.key === 'Enter') {
        if ((this.valid) && (this.state.value !== this.value)) {
          this.value = this.state.value
          this.props.onChange(this.state.value)
        }
      }
    }

    render () {
      let style = {}

      if (!this.valid) {
        style['border'] = '2px solid red'
      }

      return (
        <React.Fragment>
          <input
            className='form-control'
            type='text'
            name='theInput'
            value={this.state.value}
            onChange={this.onValueChanged}
            onKeyDown={this.updateParent}
          />
        </React.Fragment>
      )
    }
}

export default ControlledInputWithInternalState
