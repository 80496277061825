import React, { Component } from 'react'
import { BrowserRouter, Link } from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import './header.css'
import Axios from 'axios'
import shared from '../shared/shared'
import Cookies from 'js-cookie'
class Header extends Component {
  constructor (props) {
    super(props)
    this.toggleServiceDropdown = this.toggleServiceDropdown.bind(this)
    this.toggleSalesDropdown = this.toggleSalesDropdown.bind(this)
    this.toggleOperationsDropdown = this.toggleOperationsDropdown.bind(this)
    this.toggleLanguageDropdown = this.toggleLanguageDropdown.bind(this)
    this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this)
    this.toggleCustomersDropdown = this.toggleCustomersDropdown.bind(this)
    this.state = {
      serviceDropdownOpen: false,
      salesDropdownOpen: false,
      operationsDropdownOpen: false,
      languageDropdownOpen: false,
      profileDropdownOpen: false,
      customersDropdownOpen: false,
      isInternal: Cookies.get('Permissions') === undefined || (['Service Tech', 'Field Service'].indexOf(Cookies.get('Permissions')) !== -1),
      isLoggedIn: Cookies.get('isLoggedIn') === 'true',
      isFieldService: Cookies.get('Permissions') === 'Field Service',
      customers: []
    }
  }
  componentDidMount () {
    if (!this.state.isInternal) {
      this.setState({
        customers: JSON.parse(Cookies.get('assignedCustomers') !== undefined ? Cookies.get('assignedCustomers') : []),
        selectedCustomer: this.getSelectedCustomer()
      })
    }
  }
  getSelectedCustomer () {
    return (
      JSON.parse(Cookies.get('assignedCustomers') !== undefined ? Cookies.get('assignedCustomers') : [])[0].value !== JSON.parse(Cookies.get('selectedCustomer')).value
        ? JSON.parse(Cookies.get('selectedCustomer')) : JSON.parse(Cookies.get('assignedCustomers'))[0])
  }
  setSelectedCustomer (customer) {
    this.setState({ selectedCustomer: customer }, async () => {
      await Axios.patch(shared.getEnvironmentURL(`selectCustomer`), { selectedCustomer: this.state.selectedCustomer }, { withCredentials: true })
        .then(async (response) => {
          await Cookies.set('selectedCustomer', JSON.parse(Cookies.get('assignedCustomers')).find(selectedCustomer => selectedCustomer.key === response.data.key))
          this.props.reRender()
        })
        .catch()
    })
  }
  getCustomerList () {
    let customerList = (<Dropdown nav isOpen={this.state.customersDropdownOpen} className='offset-sm-1 col-sm-4' toggle={this.toggleCustomersDropdown}>
      {
        !this.state.isInternal
          ? <React.Fragment>
            <DropdownToggle className='text-white lead' nav caret>{this.state.selectedCustomer ? this.state.selectedCustomer.key : null}</DropdownToggle>
            <DropdownMenu className='bg-dark'>
              {this.state.customers.map((customer, index) => <DropdownItem key={index} className='lead text-white' onClick={() => this.setSelectedCustomer(customer)}>{customer.key}</DropdownItem>)}
            </DropdownMenu>
          </React.Fragment>
          : null
      }
    </Dropdown>
    )
    return (customerList)
  }

  toggleCustomersDropdown () {
    this.setState({
      customersDropdownOpen: !this.state.customersDropdownOpen
    })
  }

  toggleServiceDropdown () {
    this.setState({
      serviceDropdownOpen: !this.state.serviceDropdownOpen
    })
  }
  toggleSalesDropdown () {
    this.setState({
      salesDropdownOpen: !this.state.salesDropdownOpen
    })
  }
  toggleOperationsDropdown () {
    this.setState({
      operationsDropdownOpen: !this.state.operationsDropdownOpen
    })
  }
  toggleLanguageDropdown () {
    this.setState({
      languageDropdownOpen: !this.state.languageDropdownOpen
    })
  }
  toggleProfileDropdown () {
    this.setState({
      profileDropdownOpen: !this.state.profileDropdownOpen
    })
  }
  render () {
    return (
      <React.Fragment>
        <header className='bg-light'>
          <Container>
            <Row>
              <BrowserRouter forceRefresh>
                <Col sm='3'>
                  <Link className='text-center' to={`${this.state.isInternal ? './' : '/customer-dashboard'}`}>
                    <img className='header-image' src={require('./header-image.png')} alt='MacLean Engineering Service Portal' />
                  </Link>
                </Col>
              </BrowserRouter>
              <Col sm={{ size: 4, offset: 2 }} className='header-search-container'>
                {/* <h1 className='display-3 text-dark'>Service Portal</h1>
                <Form action='/' method='post' inline>
                  <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                    <Input type='text' name='searchword' />
                    <input type='hidden' name='task' value='search' />
                    <input type='hidden' name='option' value='com_search' />
                    <input type='hidden' name='Itemid' value='307' />
                  </FormGroup>
                  <Button color='primary'>Search</Button>
                </Form> */}
              </Col>
              <Col sm={{ size: 3 }}>
                {/* <Row className='language-selection-label'>
                <Col sm='12'>
                      :
                </Col>
              </Row> */}

                {this.state.isLoggedIn
                  ? <Row className='language-selection-label'>
                    {/* <Col sm='4'>
                  <BrowserRouter forceRefresh><Link to='/'>English</Link></BrowserRouter>
                </Col>
                <Col sm='4'>
                  <BrowserRouter forceRefresh><Link to='/fr-ca'>Français</Link></BrowserRouter>
                </Col>
                <Col sm='4'>
                  <BrowserRouter forceRefresh><Link to='/es-mx'>Español</Link></BrowserRouter>
                </Col> */}
                    <Col sm='6'>
                      <Dropdown isOpen={this.state.languageDropdownOpen} toggle={this.toggleLanguageDropdown}>
                        <DropdownToggle caret color='link'>Select Language </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem><BrowserRouter forceRefresh><Link to='/'>English</Link></BrowserRouter></DropdownItem>
                          <DropdownItem><BrowserRouter forceRefresh><Link to='/fr-ca'>Français</Link></BrowserRouter></DropdownItem>
                          <DropdownItem><BrowserRouter forceRefresh><Link to='/es-mx'>Español</Link></BrowserRouter></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col sm='6'>
                      <Dropdown isOpen={this.state.profileDropdownOpen} toggle={this.toggleProfileDropdown}>
                        <DropdownToggle caret color='link'>{ JSON.parse(decodeURIComponent(Cookies.get('Profile'))).displayName }</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={
                            () => {
                              Axios.get(shared.getEnvironmentURL('logout'),
                                { withCredentials: true }
                              )
                                .then(response => {
                                  console.log(response)
                                  window.location.replace(response.data)
                                })
                            }}>
                            Logout
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                  : null}
              </Col>
            </Row>
          </Container>
        </header>
        {this.state.isLoggedIn && !this.state.isFieldService
          ? <Nav pills className='bg-dark nav-bar'>
            {this.getCustomerList()}
            {this.state.isInternal ? <NavItem><NavLink href='https://documoto.digabit.com/' className='lead text-white'>Documoto</NavLink></NavItem> : null }
            <NavItem>
              <NavLink href='/vms' className='lead text-white'>Vehicle Telemetry</NavLink>
            </NavItem>
            <Dropdown nav isOpen={this.state.serviceDropdownOpen} toggle={this.toggleServiceDropdown}>
              <DropdownToggle className='text-white lead' nav caret>Service</DropdownToggle>
              <DropdownMenu className='bg-dark'>
                <DropdownItem className='lead text-white' tag='a' href='/service-reports'>Service Reports</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={this.state.salesDropdownOpen} toggle={this.toggleSalesDropdown}>
              <DropdownToggle className='text-white lead' nav caret>Sales</DropdownToggle>
              <DropdownMenu className='bg-dark'>
                <DropdownItem className='lead text-white' tag='a' href='/backlogs'>Daily Backlog</DropdownItem>
                <DropdownItem className='lead text-white' tag='a' href='/invoices'>Invoices</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={this.state.operationsDropdownOpen} toggle={this.toggleOperationsDropdown}>
              <DropdownToggle className='text-white lead' nav caret>Operations</DropdownToggle>
              <DropdownMenu className='bg-dark'>
                { this.state.isInternal ? <DropdownItem className='lead text-white' tag='a' href='/list-reports'>Product Problem Report (PPR)</DropdownItem> : null }
                <DropdownItem className='lead text-white' tag='a' href='/rigs-in-process'>Rigs in Progress</DropdownItem>
                <DropdownItem className='lead text-white' tag='a' href='/rigs'>Capital Equipment</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          : null}
        {this.state.isFieldService
          ? <Nav pills className='bg-dark nav-bar'>
            <Dropdown nav isOpen={this.state.operationsDropdownOpen} className='offset-sm-6 col-sm-1' toggle={this.toggleOperationsDropdown}>
              <DropdownToggle className='text-white lead' nav caret>Operations</DropdownToggle>
              <DropdownMenu className='bg-dark'>
                <DropdownItem className='lead text-white' tag='a' href='/list-reports'>Product Problem Report (PPR)</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          : null}
      </React.Fragment>
    )
  }
}

export default Header
