import React, { Component } from 'react'
import NotificationListTable from './NotificationTable/NotificationListTable'
import FaultListTable from './FaultTable/FaultListTable'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
class machineListPane extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machinesWithNotificationsModal: this.props.isOpen
    }
    this.toggle = this.toggle.bind(this)
  }
  toggle () {
    this.props.popOpenList(undefined, undefined, undefined)
  }
  render () {
    let table = null

    if (this.props.whatList === 'Notifications') {
      table = <NotificationListTable machineIdentities={this.props.machineIdentities} machines={this.props.machines} notifications={this.props.notifications}
        machineDetailPane={this.props.machineDetailPane} popOpenList={this.props.popOpenList} data={this.props.data} ajaxError={this.props.ajaxError} />
    } else {
      table = <FaultListTable machines={this.props.machines} machineDetailPane={this.props.machineDetailPane} popOpenList={this.props.popOpenList} data={this.props.data}
        machineIdentities={this.props.machineIdentities} ajaxError={this.props.ajaxError} />
    }

    return (
      <Modal isOpen={this.state.machinesWithNotificationsModal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Machines With {this.props.whatList}</ModalHeader>
        <ModalBody>
          {table}
        </ModalBody>
      </Modal>
    )
  }
}

export default machineListPane
