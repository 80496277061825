import React, { Component } from 'react'
import './Faults.css'
import FaultTable from './FaultTable/FaultTable'
import FaultHistory from './FaultHistory/FaultHistory'
import { Nav, NavItem, NavLink } from 'reactstrap'

class FaultDetail extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machineID: this.props.details.serial_num,
      spns: this.getCurrentSPNs(), // ['2', '21074', '517074', '516529', '516388', '516537', '45231'],
      table: this.props.details.spn_table,
      currentView: 'Current'
    }
  }

  getCurrentSPNs () {
    if (this.props.details.last_row === undefined) {
      return undefined
    } else {
      let keys = Object.keys(this.props.details.last_row)
      for (let i in keys) {
        if (keys[i].includes('SPN')) {
          return this.props.details.last_row[keys[i]]
        }
      }
      return []
    }
  }

  handleChangeView (view) {
    if (view !== this.state.currentView) {
      this.setState({
        currentView: view
      })
    }
  }

  render () {
    let currentView = null
    switch (this.state.currentView) {
      case 'Current':
        currentView = <FaultTable ajaxError={this.props.ajaxError} spns={this.state.spns} table={this.state.table} />
        break

      case 'History':
        currentView = <FaultHistory ajaxError={this.props.ajaxError} machine={this.state.machineID} spnTable={this.state.table} details={this.props.details} />
        break
      default:
    }

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink onClick={() => this.handleChangeView('Current')} active={(this.state.currentView === 'Current')}>Current</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.handleChangeView('History')} active={(this.state.currentView === 'History')}>History</NavLink>
          </NavItem>
        </Nav>
        {currentView}
      </React.Fragment>
    )
  }
}

export default FaultDetail
