import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      data: [],
      row: [],
      columns: [
        { accessor: 'InvoiceID', Header: 'Invoice ID', width: this.getColumnWidth('InvoiceID', 'Invoice ID') },
        { accessor: 'LineNumber', Header: 'Line Number', width: this.getColumnWidth('LineNumber', 'Line Number'), Aggregated: row => null },
        { accessor: 'SalesOrder', Header: 'Sales Order', width: this.getColumnWidth('SalesOrder', 'Sales Order'), Aggregated: row => null },
        { accessor: 'PartNumber', Header: 'Part Number', width: this.getColumnWidth('PartNumber', 'Part Number'), Aggregated: row => null },
        { accessor: 'MasterDescription', Header: 'Description', width: this.getColumnWidth('MasterDescription', 'Description'), Aggregated: row => null },
        { accessor: 'Quantity', Header: 'Quantity', width: this.getColumnWidth('Quantity', 'Quantity'), Aggregated: row => null },
        { accessor: 'UnitPrice', Header: 'Unit Price', width: this.getColumnWidth('UnitPrice', 'Unit Price'), Aggregated: row => null },
        { accessor: 'ExtInvoice', Header: 'Ext Invoice', width: this.getColumnWidth('ExtInvoice', 'Ext Invoice'), Aggregated: row => null },
        { accessor: 'CustomerPO', Header: 'Customer PO', width: this.getColumnWidth('CustomerPO', 'CustomerPO'), Aggregated: row => null },
        { accessor: 'InvoiceDate', Header: 'Invoice Date', width: this.getColumnWidth('InvoiceDate', 'Invoice Date'), Aggregated: row => null },
        { accessor: 'ShipDate', Header: 'Ship Date', width: this.getColumnWidth('ShipDate', 'Ship Date'), Aggregated: row => null },
        { accessor: 'Delivery', Header: 'Packing Slip Number', width: this.getColumnWidth('Delivery', 'Packing Slip Number'), Aggregated: row => null },
        { accessor: 'MLWaybill', Header: 'Waybill', width: this.getColumnWidth('MLWaybill', 'Waybill'), Aggregated: row => null }
      ]
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => {
      if (accessor === 'InvoiceDate' || accessor === 'CreateDate' || accessor === 'ShipDate' || accessor === 'ReportRunAt' || accessor === 'ReportUploadAt') {
        return new Date(row[accessor]).toLocaleDateString().length
      } else if (accessor === 'ExtPrice' || accessor === 'Markup' || accessor === 'GrossMargin') {
        return row[accessor].toFixed(2).length
      } else {
        return (`${row[accessor]}` || '').length
      }
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }

  componentDidMount () {
    this.setState({
      expandedRows: this.props.data.map((element, index) => { return { index: true } }),
      data: this.props.data
    })
  }
  render () {
    return (
      <React.Fragment>
        <ReactTable
          filterable
          data={this.state.data}
          pivotBy={['InvoiceID']}
          expanded={this.state.expandedRows}
          onExpandedChange={(newExpanded) => this.setState({ expandedRows: newExpanded })}
          columns={this.state.columns}
          defaultPageSize={10}
          defaultFilterMethod={this.filterCaseInsensitive}
          className='-striped -highlight' />
        <br />
      </React.Fragment>
    )
  }
}

export default ReportTable
