import React, { Component } from 'react'
import { Spinner, Alert } from 'reactstrap'
import Cookies from 'js-cookie'
import axios from 'axios'
import shared from '../shared/shared'
import ExternalReportTable from './report-table/external-report-table'
import InternalReportTable from './report-table/internal-report-table'

class RigsInProcess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fetching: false,
      rigData: [],
      isInternal: Cookies.get('Permissions') !== 'Distributor'
    }
    this.fetchRigs = this.fetchRigs.bind(this)
  }

  async fetchRigs () {
    this.setState({ fetching: true })
    await axios.get(shared.getEnvironmentURL('getRigsInProcess'), { withCredentials: true })
      .then((response) => {
        this.setState({ rigData: response.data, fetching: false })
        this.forceUpdate()
      })
      .catch((error) => { shared.handleError(error) })
    if (this.state.rigData.length > 0) {
      this.setState({ customerName: this.state.rigData[this.state.rigData.length - 1].customer })
    }
  }

  componentDidMount () {
    this.fetchRigs()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.customer.key !== this.props.customer.key) {
      this.fetchRigs()
    }
  }
  renderInternalView () {
    return (
      <InternalReportTable data={this.state.rigData === undefined ? [] : this.state.rigData} customerName={this.state.customerName === undefined ? null : this.state.customerName} />
    )
  }

  renderExternalView () {
    return (
      <ExternalReportTable data={this.state.rigData === undefined ? [] : this.state.rigData} customerName={this.state.customerName === undefined ? null : this.state.customerName} />
    )
  }

  render () {
    return (
      this.state.fetching
        ? <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        : <React.Fragment>
          { this.state.rigData.length > 0
            ? <h2 className='display-4 text-center'>
            Rigs In Progress{
                this.state.isInternal
                  ? null
                  : ` for ` + this.state.customerName
              }
            </h2>
            : null }
          <br />
          {
            this.state.isInternal
              ? this.renderInternalView()
              : this.state.rigData.length > 0
                ? this.renderExternalView()
                : <React.Fragment>
                  <Alert color='info'>
                    <h4 className='display-4'>
                      <center>No Rigs In Process For Customer</center>
                    </h4>
                  </Alert>
                </React.Fragment>
          }
        </React.Fragment>
    )
  }
}
export default RigsInProcess
