import React, { Component } from 'react'
import { Button } from 'reactstrap'
import ReactTable from 'react-table'
import ReactExport from 'react-export-excel'
import 'react-table/react-table.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }
  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => {
      if (accessor === 'Delivery' || accessor === 'Create Date') {
        return new Date(row[accessor]).toLocaleDateString().length
      } else {
        return (`${row[accessor]}` || '').length
      }
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  componentDidMount () {
    this.setState({
      columns: [
        { accessor: 'shipDate', Header: 'Ship Date', width: this.getColumnWidth('Delivery', 'Ship Date') },
        { accessor: 'customerReference', Header: 'Customer Reference', width: this.getColumnWidth('customerReference', 'Customer Reference') },
        { accessor: 'customerRequisition', Header: 'Customer Requisition', width: this.getColumnWidth('customerRequisition', 'Customer Requisition') },
        { accessor: 'name', Header: 'Name', width: this.getColumnWidth('name', 'Name') },
        { accessor: 'customerAccount', Header: 'Customer Account', width: this.getColumnWidth('customerAccount', 'Customer Account') },
        { accessor: 'salesOrder', Header: 'Sales Order', width: this.getColumnWidth('salesOrder', 'Sales Order') },
        { accessor: 'canShipFull', Header: 'Can Ship Full', width: this.getColumnWidth('canShipFull', 'Can Ship Full') },
        { accessor: 'itemNumber', Header: 'Item Number', width: this.getColumnWidth('itemNumber', 'Item Number') },
        { accessor: 'productName', Header: 'Product Name', width: this.getColumnWidth('productName', 'Product Name') },
        { accessor: 'unit', Header: 'UOM', width: this.getColumnWidth('unit', 'UOM   ') },
        { accessor: 'quantity', Header: 'Quantity', width: this.getColumnWidth('quantity', 'Quantity') },
        { accessor: 'deliveryRemainder', Header: 'Delivery Remainder', width: this.getColumnWidth('deliveryRemainder', 'Delivery Remainder') },
        { accessor: 'shipComplete', Header: 'Ship Complete', width: this.getColumnWidth('itemNumber', 'Item Number') },
        { accessor: 'rmaNumber', Header: 'RMA Number', width: this.getColumnWidth('rmaNumber', 'RMA Number') },
        { accessor: 'notes', Header: 'Notes', width: this.getColumnWidth('notes', 'Notes') },
        { accessor: 'pool', Header: 'Pool', width: this.getColumnWidth('pool', 'Pool') },
        { accessor: 'createdDateAndTime', Header: 'Created Date And Time', width: this.getColumnWidth('createdDateAndTime', 'Created Date And Time') }
      ]
    })
  }
  render () {
    return (<React.Fragment>
      <ReactTable
        filterable
        data={this.props.data}
        onFetchData={(state, instance) => {
          this.setState({ dataToExport: state.sortedData.map((data) => { return (data._original) }) })
        }}
        columns={this.state.columns}
        defaultPageSize={10}
        defaultFilterMethod={this.filterCaseInsensitive}
        className='-striped -highlight' />
      <br />
      <ExcelFile filename={`Backlog_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
        <ExcelSheet data={this.state.dataToExport} name='Backlog Data'>
          { this.state.columns.map((column) => {
            return (<ExcelColumn
              key={column}
              label={column.Header}
              value={
                (col) => {
                  // console.log(col[column.accessor])
                  return col[column.accessor]
                }
              }
            />)
          })}
        </ExcelSheet>
      </ExcelFile>
    </React.Fragment>)
  }
}

export default ReportTable
