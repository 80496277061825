import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
class deTable extends Component {
  renderTable () {
    let table =
      <React.Fragment>
        <Row>
          <Col sm={{ size: 5, offset: 5 }}>
            <br />
            Avg for Period: {this.props.perAvg.toFixed(1)}%
          </Col>
        </Row>
      </React.Fragment>
    return table
  }

  render () {
    // console.log(this.props);

    let table = null

    if (this.props.perAvg !== undefined) {
      table = this.renderTable()
    }

    return (
      <React.Fragment>
        {table}
      </React.Fragment>
    )
  }
}

export default deTable
