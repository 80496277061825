import React, { Component } from 'react'
import MachinePreviewPane from './MachinePreviewPane/MachinePreviewPane'
import './DashboardContainer.css'
import FleetConnectedPane from './FleetConnectedPane/FleetConnectedPane'
import FleetUtilizationPane from './FleetUtilizationPane/FleetUtilizationPane'
import FleetNoticesPane from './FleetNoticesPane/FleetNoticesPane'
import MachineDetailPane from './MachineDetailPane/MachineDetailPane'
import { Row, Col } from 'reactstrap'
import axios from 'axios'
import shared from '../shared/shared'

class MainPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machineIdentities: undefined,
      showMachineIdentities: undefined,
      company: this.props.company,
      utilizationAvg: undefined,
      showMachineView: 'Whole Fleet',
      machineDetailsMachineID: undefined,
      machineDetailsView: 'Overview',
      rerenderNoti: false,
      isMachineDetailsPaneOpen: false,
      machinesDataBack: undefined,
      allowListSort: false
    }

    this.machineDetailsPane = null
    this.utilObj = {}
    this.oldAvg = Number()
    this.allowSetStateUtil = false
    this.machinesDataBack = {}
    this.allowDataBack = true
  }

  getCompanyMachineIdentities () {
    axios.post(
      shared.getVmsHost('companyMachineIdentities'), { company: this.props.company }, { withCredentials: true })
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          response.data.sort(function (a, b) {
            if (a.last_data_received === null) {
              return 1
            } else {
              return -1
            }
          })
          this.setState({
            machineIdentities: response.data,
            showMachineIdentities: response.data
          })
          this.allowSetStateUtil = true
        }
      }).catch((error) => { shared.handleError(error) })
  }

  subFleetVehicleType (e) {
    this.allowSetStateUtil = true
    this.utilObj = {}
    let tempIdentities = []
    let i = 0
    this.machinesDataBack = {}
    this.allowDataBack = true

    if (e === 'Whole Fleet') {
      if (this.state.machineIdentities === this.state.showMachineIdentities) {
        return
      } else {
        tempIdentities = this.state.machineIdentities
        this.setState({
          showMachineIdentities: tempIdentities,
          showMachineView: e
        })
      }
    } else {
      for (i = 0; i < this.state.machineIdentities.length; i++) {
        if (this.state.machineIdentities[i].type === e) {
          tempIdentities.push(this.state.machineIdentities[i])
        }
      }
    }

    this.setState({
      showMachineIdentities: tempIdentities,
      showMachineView: e,
      allowListSort: false
    })
  }

  machineDetailsPaneHandler (machine, view = undefined) {
    if (this.state.machinesDataBack !== undefined) {
      this.setState(prevState => (
        {
          machineDetailsMachineID: machine,
          machineDetailsView: view === undefined ? 'Overview' : view,
          rerenderNoti: !this.state.rerenderNoti,
          isMachineDetailsPaneOpen: !prevState.isMachineDetailsPaneOpen
        }
      ))
    } else {

    }
  }
  toggleMachineDetailsPane () {
    this.setState(prevState => ({
      isMachineDetailsPaneOpen: !prevState.isMachineDetailsPaneOpen
    }
    ))
  }

  getBackData (machine, dataName, value) {
    if (this.allowDataBack === false) return

    if (['utilization', 'last_row', 'totalHours'].indexOf(dataName) > -1) {
      let keys = Object.keys(this.machinesDataBack)
      if (keys.indexOf(machine) > -1) {
        this.machinesDataBack[machine][dataName] = value
      } else {
        this.machinesDataBack[machine] = {}
        this.machinesDataBack[machine][dataName] = value
      }

      if (keys.length === this.state.showMachineIdentities.length) {
        let done = true
        for (let i in keys) {
          if ((this.machinesDataBack[keys[i]].utilization === undefined) || (this.machinesDataBack[keys[i]].last_row === undefined) ||
            (this.machinesDataBack[keys[i]].totalHours === undefined)) {
            done = false
            break
          }
        }
        if (!done) {

        } else {
          this.allowDataBack = false
          this.setState({
            machinesDataBack: this.machinesDataBack,
            allowListSort: true
          })
        }
      }
    } else {

    }
  }

  render () {
    let machineDetailsPane = null
    if (this.state.machineDetailsMachineID !== undefined) {
      let details
      for (let i = 0; i < this.state.machineIdentities.length; i++) {
        if (this.state.machineIdentities[i].serial_num === this.state.machineDetailsMachineID) {
          details = this.state.machineIdentities[i]
        }
      }

      try {
        details['last_row'] = this.state.machinesDataBack[this.state.machineDetailsMachineID].last_row
        details['utilization'] = this.state.machinesDataBack[this.state.machineDetailsMachineID].utilization
        details['totalHours'] = this.state.machinesDataBack[this.state.machineDetailsMachineID].totalHours
      } catch {
      } finally {}

      machineDetailsPane = this.state.isMachineDetailsPaneOpen
        ? <MachineDetailPane
          details={details}
          isMachineDetailsPaneOpen={this.state.isMachineDetailsPaneOpen}
          toggle={this.toggleMachineDetailsPane.bind(this)}
          view={this.state.machineDetailsView}
          ajaxError={this.props.ajaxError}
        />
        : null
    }
    if (this.state.machineIdentities === undefined) {
      this.getCompanyMachineIdentities()
    }
    return (
      <React.Fragment>
        <Row className='display-4'>
          <Col sm='12'><center>Vehicle Monitoring System</center></Col>
          &nbsp;
        </Row>
        {machineDetailsPane}
        <Row>
          <FleetConnectedPane data={this.state.showMachineIdentities} />
          <FleetUtilizationPane data={this.state.machinesDataBack} />
          <FleetNoticesPane
            detailsOpen={machineDetailsPane === null}
            machineIdentities={this.state.showMachineIdentities}
            machineDetailPane={this.machineDetailsPaneHandler.bind(this)}
            rerender={this.state.rerenderNoti}
            data={this.state.machinesDataBack}
            ajaxError={this.props.ajaxError}
          />
        </Row>
        <br />
        <br />
        <Row>
          <MachinePreviewPane
            company={this.props.company}
            companyMachines={this.state.machineIdentities}
            machineIdentities={this.state.showMachineIdentities}
            machineDetailsPane={this.machineDetailsPaneHandler.bind(this)}
            sortVehicleType={this.subFleetVehicleType.bind(this)} showMachines={this.state.showMachineView}
            getBackData={this.getBackData.bind(this)}
            ajaxError={this.props.ajaxError}
            allowListSort={this.state.allowListSort}
          />
        </Row>
      </React.Fragment>
    )
  }
}

export default MainPage
