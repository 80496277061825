import React, { Component } from 'react'
import TopBar from '../TopBar/TopBar'
import RadialIndicator from './RadialIndicator/RadialIndicator'
import Moment from 'moment'
import { Col, Spinner } from 'reactstrap'
import shared from '../../shared/shared'
import axios from 'axios'

class fleetConnectedPane extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hocData: [],
      dates: undefined,
      currentServerTime: undefined
    }

    this.globalvars = {
      currentTimeSet: false
    }
  }

  getCurrentServerTime () {
    axios.get(
      shared.getVmsHost('getServerTime'), { withCredentials: true }
    ).then((response) => {
      this.setState({ currentServerTime: response.data })
      // console.log(this.state.currentServerTime);
    }).catch((error) => { shared.handleError(error) })
  }

  handleProps () {
    let i = 0
    let dates = []

    for (i = 0; i < this.props.data.length; i++) {
      if (this.props.data[i].last_data_received !== null) {
        dates.push(this.props.data[i].last_data_received)
      }
    }

    this.setState({
      hocData: this.props.data,
      dates: dates
    })
  }

  renderRadialIndicator () {
    let x = 0
    let timeTwo = Moment(this.state.currentServerTime[0].now)

    for (let i in this.state.dates) {
      let timeOne = Moment.utc(this.state.dates[i])
      let difference = Moment.duration(timeTwo.diff(timeOne))
      difference = difference.asHours()
      if (difference < 24) {
        x += 1
      }
    }

    return (
      <RadialIndicator count={x} outOf={this.state.hocData.length} />
    )
  }

  render () {
    // console.log(this.props.data);
    let radialIndicator = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if ((this.state.currentServerTime === undefined) && (this.globalvars.currentTimeSet === false)) {
      // console.log('getCurrentServerTime');
      this.getCurrentServerTime()
      this.globalvars.currentTimeSet = true
    }

    if ((this.props.data !== undefined) && (this.props.data !== this.state.hocData)) {
      this.handleProps()
      // console.log('handleProps');
    }

    if ((this.state.currentServerTime !== undefined) && (this.state.dates !== undefined)) {
      // console.log('renderRadialIndicator');
      radialIndicator = this.renderRadialIndicator()
    }

    return (
      <Col xs='3' sm='3'>
        <TopBar headerText='Reporting Status' />
        {radialIndicator}
      </Col>
    )
  }
}

export default fleetConnectedPane
