import React, { Component } from 'react'
import './Notices.css'
import { Col, Row, Button } from 'reactstrap'
import shared from '../../../shared/shared'
import axios from 'axios'

class table extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machinesWithSPNs: [],
      machinesWithNotifications: [],
      notifications: [],
      faultsText: 'None',
      unseenNotificationsText: 'None',
      faultsButtonDisabled: true,
      unseenNotificationsButtonDisabled: true
    }

    this.machineIdentities = []
    this.data = {}
    this.machineArray = []
    this.notifications = []
    this.machinesWithNotifications = []
    this.machinesWithSPNs = []
  }

  componentWillReceiveProps (nextProps) {
    if ((nextProps.detailsOpen && !this.props.detailsOpen) || (nextProps.showList === undefined && this.props.showList !== undefined)) {
      this.getNotifications()
    }
  }

  arraysEqual (arr1, arr2) {
    if (arr1.length !== arr2.length) { return false }

    for (var i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) { return false }
    }

    return true
  }

  getFaults () {
    this.data = this.props.data
    let keys = Object.keys(this.props.data)
    this.machinesWithSPNs = []
    for (let i in keys) {
      if ((this.data[keys[i]].last_row['SPNs_active'] !== undefined) && (this.data[keys[i]].last_row['SPNs_active'] !== null)) {
        try {
          if (this.data[keys[i]].last_row['SPNs_active'].length > 0) {
            this.machinesWithSPNs.push(keys[i])
          }
        } catch {
        } finally {
        }
      }
    }
    if (!this.arraysEqual(this.machinesWithSPNs, this.state.machinesWithSPNs)) {
      this.setState({
        machinesWithSPNs: this.machinesWithSPNs
      }, () => { this.setFaultsButtonInfo() })
    } else {
      this.setFaultsButtonInfo()
    }
  }

  getNotifications () {
    this.machineIdentities = this.props.machineIdentities
    this.notifications = []

    let body = {
      machineArray: this.props.machineIdentities.map((el) => { return el.serial_num })
    }

    axios.post(
      shared.getVmsHost('customerNotifications'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(data)
      this.notifications = response.data
      this.getMachinesWithNotifications()
    }).catch((error) => { shared.handleError(error) })
  }

  getMachinesWithNotifications () {
    let machinesWithNotifications = {}
    if (this.notifications.length !== 0) {
      for (let i = 0; i < this.notifications.length; i++) {
        machinesWithNotifications[this.notifications[i].serial_num] = undefined
      }
    }
    this.machinesWithNotifications = Object.keys(machinesWithNotifications)
    this.setState({
      machinesWithNotifications: this.machinesWithNotifications,
      notifications: this.notifications,
      machineIdentities: this.props.machineIdentities
    })
    this.setUnseenNotificationsInfo()
  }

  setFaultsButtonInfo () {
    let text = 'None'
    if (this.state.machinesWithSPNs.length !== 0) {
      if (this.state.machinesWithSPNs.length === 1) {
        text = '1 Machine'
      }
      if (this.state.machinesWithSPNs.length > 1) {
        text = this.state.machinesWithSPNs.length + ' Machines'
      }
      this.setState({
        addFaultImageShake: true,
        faultsButtonDisabled: false,
        faultsText: text,
        faultsColor: 'danger'
      })
    } else {
      this.setState({
        addFaultImageShake: false,
        faultsButtonDisabled: true,
        faultsText: text,
        faultsColor: 'secondary'
      })
    }
  }
  setUnseenNotificationsInfo () {
    let text = 'None'
    if (this.state.machinesWithNotifications.length !== 0) {
      if (this.state.machinesWithNotifications.length === 1) {
        text = '1 Machine'
      }
      if (this.state.machinesWithNotifications.length > 1) {
        text = this.state.machinesWithNotifications.length + ' Machines'
      }
      this.setState({
        addUnseenNotificationsImageShake: true,
        unseenNotificationsButtonDisabled: false,
        unseenNotificationsText: text,
        unseenNotificationsColor: 'danger'
      })
    } else {
      this.setState({
        addUnseenNotificationsImageShake: false,
        unseenNotificationsButtonDisabled: true,
        unseenNotificationsText: text,
        unseenNotificationsColor: 'secondary'
      })
    }
  }
  openNoticesModal (notice) {
    if (notice === 'Active Faults') {
      if (!this.state.faultsButtonDisabled) {
        this.props.openList(notice, this.state.machinesWithSPNs, this.state.notifications)
      }
    } else if (notice === 'Notifications') {
      if (!this.state.unseenNotificationsButtonDisabled) {
        this.props.openList(notice, this.state.machinesWithNotifications, this.state.notifications)
      }
    }
  }
  render () {
    if ((this.props.machineIdentities !== undefined) && (!this.arraysEqual(this.props.machineIdentities, this.machineIdentities))) {
      this.getNotifications()
    }

    if ((this.props.data !== undefined) && (!this.arraysEqual(Object.keys(this.props.data), Object.keys(this.data)))) {
      this.getFaults()
    }

    return (
      <React.Fragment>
        {/* <Row className='fleet-events-container'>
          <Col xs={{ size: 3, offset: 1 }} className='my-auto'>
            <img className={this.state.addFaultImageShake ? 'imgShake' : null} src={require('./Assets/Ron_1-01.png')} alt='' />
          </Col>
          <Col xs='4' className='notices-text align-self-center'>
            Active Faults
          </Col>
          <Col xs='4' className='my-auto'>
            <Button
              className='float-left'
              color={this.state.faultsColor}
              disabled={this.state.faultsButtonDisabled}
              onClick={() => this.openNoticesModal('Active Faults')}
            >
              {this.state.faultsText}
            </Button>
          </Col>
        </Row> */}
        &nbsp;
        <Row>
          <Col xs={{ size: 3, offset: 1 }}>
            <img className={this.state.addUnseenNotificationsImageShake ? 'imgShake' : null} src={require('./Assets/Ron_2-01.png')} alt='' />
          </Col>
          <Col xs='4' className='notices-text align-self-center'>
            Unseen Notifications
          </Col>
          <Col xs='4' className='my-auto'>
            <Button
              className='float-left'
              color={this.state.unseenNotificationsColor}
              disabled={this.state.unseenNotificationsButtonDisabled}
              onClick={() => this.openNoticesModal('Notifications')}
            >
              {this.state.unseenNotificationsText}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default table
