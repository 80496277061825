import React, { Component } from 'react'
import { Alert, Spinner } from 'reactstrap'
import axios from 'axios'
import ReactTable from 'react-table'
import shared from '../../shared/shared'
class Rigs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.state.row.map(row => { return (`${row[accessor]}` || '').length }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  async componentDidMount () {
    const URL = shared.getEnvironmentURL(`getRigs`)
    this.setState({ loaded: false })
    await axios.get(URL, { withCredentials: true }).then((response) => {
      this.setState({ row: response.data, loaded: true })
    }).catch((error) => {
      shared.handleError(error)
    })
    this.setState({
      columns: [
        { accessor: 'machine', Header: 'Machine', width: this.getColumnWidth('machine', 'Machine') },
        { accessor: 'customerUnitID', Header: 'Customer Unit ID', width: this.getColumnWidth('customerUnitID', 'Customer Unit ID') },
        { accessor: 'site', Header: 'Site', width: this.getColumnWidth('site', 'Site') }
      ]
    })
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      <React.Fragment>
        <Alert color='info'><h4 className='display-4'><center> Rigs </center></h4></Alert>
        {
          this.state.loaded
            ? <ReactTable filterable data={this.state.row} columns={this.state.columns} className='-striped -highlight' defaultFilterMethod={this.filterCaseInsensitive} defaultPageSize={10} />
            : <Spinner color='info' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        }
      </React.Fragment>

    )
  }
}

export default Rigs
