import React, { Component } from 'react'
import DataExplorerEVs from './DataExplorerEVs/DataExplorerEVs'
import { Alert } from 'reactstrap'
import './DataExplorer.css'

class DataExplorer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      vehicleType: this.props.details.type
    }
  }

  render () {
    // console.log('render');
    let dataExplorer = null

    if (this.state.vehicleType !== undefined) {
      switch (this.state.vehicleType) {
        case 'boom_EV':
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          break

        case 'bolter_EV':
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          /// /console.log(this.props.dataExplorer);
          break

        case 'emulsion_EV':
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          // console.log(this.props.dataExplorer);
          break

        case 'cassette_EV':
          /// /console.log(this.props.dataExplorer);
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          break

        case 'scissor_EV':
          // console.log(this.props.dataExplorer);
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          break

        case 'blockholer_EV':
          // console.log(this.props.dataExplorer);
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          break

        case 'grader_EV':
          // console.log(this.props.dataExplorer);
          dataExplorer = <DataExplorerEVs ajaxError={this.props.ajaxError} details={this.props.details} tables={this.props.tables} deProps={this.props.deProps} />
          break

        default:
          dataExplorer = <Alert color='primary'><center><h3>Data Explorer not available for vehicle type: {this.state.vehicleType}.</h3></center></Alert>
      }
    }

    return (
      <React.Fragment>
        &nbsp;
        {dataExplorer}
      </React.Fragment>
    )
  }
}

export default DataExplorer
