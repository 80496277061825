import React, { Component } from 'react'
import './FaultTable.css'
import FaultRow from './FaultRow/FaultRowHistory'
import { Alert, Table, Spinner } from 'reactstrap'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import Moment from 'moment'
import shared from '../../../../shared/shared'
import axios from 'axios'

class FaultsTableHistory extends Component {
  constructor (props) {
    super(props)

    this.state = {
      spns: this.props.spns,
      spnData: undefined,
      sortByColumn: 'SPN',
      spnSorted: '',
      lastSetSorted: '',
      titleSorted: ''
    }
    this.spns = Object.keys(this.props.spns)
    this.sortSameOld = false
    this.spnData = undefined
    this.arraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) { return false }
      for (var i = arr1.length; i--;) {
        if (arr1[i] !== arr2[i]) { return false }
      }
      return true
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!this.arraysEqual(Object.keys(nextProps.spns), Object.keys(this.state.spns))) {
      this.spns = Object.keys(nextProps.spns)
      this.spnData = undefined
      this.setState({
        spns: nextProps.spns,
        spnData: undefined
      })
    }
  }

  getSpns () {
    this.spnData = []

    for (let i in this.spns) {
      this.spnData.push({ spn: this.spns[i], lastSet: this.state.spns[this.spns[i]].date, title: undefined, description: undefined })
    }
    // console.log(this.spnData)
    this.setState({
      spnData: this.spnData
    })
    if ((this.props.table !== null) && (this.props.table !== undefined)) {
      for (let i in this.spns) {
        this.getSpn(this.spns[i])
      }
    }
  }

  getSpn (spn) {
    let body = {
      spn: spn,
      table: this.props.table
    }

    axios.post(
      shared.getVmsHost('getSPN'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.data.length > 0) {
        let tempData = this.state.spnData
        for (let i in tempData) {
          if (tempData[i].spn === spn) {
            tempData[i].title = response.data[0].title
            tempData[i].definition = response.data[0].description
            break
          }
        }
        this.setState({
          spnData: tempData
        })
      } else {

      }
    }).catch((error) => { shared.handleError(error) })
  }

  sortByColumns (e) {
    // console.log(e);
    if (this.state.spnData === undefined) return

    let tempSpnList = this.state.spnData

    if (e === 'SPN') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ spnSorted: <MdKeyboardArrowUp />, lastSetSorted: '', titleSorted: '' })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) { return Number(a.spn) - Number(b.spn) })
      } else {
        this.setState({ spnSorted: <MdKeyboardArrowDown />, lastSetSorted: '', titleSorted: '' })
        tempSpnList.sort(function (a, b) { return Number(b.spn) - Number(a.spn) })
        this.sortSameOld = true
      }
    } else if (e === 'Last Set') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        this.setState({ spnSorted: '', lastSetSorted: <MdKeyboardArrowUp />, titleSorted: '' })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) { return Moment(a.lastSet).valueOf() - Moment(b.lastSet).valueOf() })
      } else {
        this.setState({ spnSorted: '', lastSetSorted: <MdKeyboardArrowDown />, titleSorted: '' })
        tempSpnList.sort(function (a, b) { return Moment(b.lastSet).valueOf() - Moment(a.lastSet).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Title') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        this.setState({ spnSorted: '', lastSetSorted: '', titleSorted: <MdKeyboardArrowDown /> })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) {
          if (b.title === undefined) { return 1 }
          var x = b.title.toLowerCase()
          if (a.title === undefined) { return -1 }
          var y = a.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ spnSorted: '', lastSetSorted: '', titleSorted: <MdKeyboardArrowUp /> })
        this.sortSameOld = true
        tempSpnList.sort(function (a, b) {
          if (a.title === undefined) { return 1 }
          var x = a.title.toLowerCase()
          if (b.title === undefined) { return -1 }
          var y = b.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else {
      return
    }
    // console.log(tempSpnList);

    this.setState({
      spnData: tempSpnList,
      sortByColumn: e
    })
    this.forceUpdate()
  }

  renderTable () {
    return (
      <Table>
        <thead>
          <tr>
            <th className='sortable' onClick={() => this.sortByColumns('SPN')}>SPN {this.state.spnSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Last Set')}>Last Set {this.state.lastSetSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Title')}>Title {this.state.titleSorted}</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.spnData.map((el, index) => {
              return (<FaultRow key={index} spn={el.spn} lastSet={el.lastSet} title={el.title} definition={el.definition} />)
            })
          }
        </tbody>
      </Table>
    )
  }

  render () {
    let table = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if ((this.state.spns !== undefined) && (this.state.spnData === undefined)) {
      this.getSpns()
    }

    if ((this.state.spnData !== undefined) && (this.state.spnData.length > 0)) {
      table = this.renderTable()
    } else if ((this.state.spnData !== undefined) && (this.state.spnData.length < 1)) {
      table = <Alert color='primary'><center><h3>No faults found for selected time range.</h3></center></Alert>
    }
    return (
      <React.Fragment>
        {table}
      </React.Fragment>
    )
  }
}

export default FaultsTableHistory
