import React, { Component } from 'react'
import { Alert } from 'reactstrap'
class UserNotConfigured extends Component {
  componentDidMount () {
    setTimeout(() => { window.location.replace(`https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.origin}`) }, 10000)
  }
  render () {
    return (
      <React.Fragment>
        <Alert color='info'>
          <h3 style={{ fontWeight: 300, padding: '1% 0' }}>
            <center>
            You are not authorized to login with your current credentials. You will be redirected to the login page in a few seconds
            </center>
          </h3>
        </Alert>
      </React.Fragment>
    )
  }
}
export default UserNotConfigured
