import React, { Component } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import shared from '../../shared/shared'
import Blob from 'blob'
class ServiceReport extends Component {
  constructor (props) {
    super(props)

    this.state = {
      csaIds: Cookies.get('assignedCustomers') ? JSON.parse(Cookies.get('assignedCustomers')).map(customer => customer.value) : null
    }
    this.fetchReport = this.fetchReport.bind(this)
  }

  async fetchReport () {
    await axios.get(shared.getEnvironmentURL(`getServiceReportFile/${this.props.year}/${this.props.serviceReportNumber}`), { responseType: 'blob', withCredentials: true })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.props.serviceReportNumber}`)
        link.click()
      })
      .catch((error) => { shared.handleError(error) })
  }
  render () {
    return this.state.csaIds !== null
      ? new Date(this.props.reportDate).valueOf() > 1542603600000 ? <a href={this.state.csaIds.includes(this.props.csaNumber) ? `javascript:void(0)` : '/srs-not-authorized'} onClick={this.fetchReport}>{this.props.serviceReportNumber}</a> : this.props.serviceReportNumber
      : new Date(this.props.reportDate).valueOf() > 1542603600000 ? <a href={`https://memcoportal.sharepoint.com/_layouts/15/download.aspx?SourceUrl=/SRSExport/${this.props.serviceReportNumber}.pdf`} target='_blank' rel='noopener noreferrer'>{this.props.serviceReportNumber}</a> : this.props.serviceReportNumber
  }
}

export default ServiceReport
