import React, { Component } from 'react'
import MachineListItem from './MachineListItem/MachineListItem'
import './MachineList.css'
import Moment from 'moment'
import { Table } from 'reactstrap'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'

class machineList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machineIdentities: this.props.machineIdentities,
      sortByColumn: undefined,
      machineListItems: undefined,
      machinesSorted: '',
      lastDataSorted: '',
      lastConnectedSorted: '',
      hoursSorted: '',
      utilizationSorted: ''
    }

    this.allMachines = undefined
    this.allListItems = null
    this.firstRender = true
    this.sortByColumns = this.sortByColumns.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.machineIdentities !== this.state.machineIdentities) {
      this.setState({
        machineIdentities: nextProps.machineIdentities,
        machineListItems: undefined
      }, () => {
        this.firstRender = true
        this.buildAllMachines()
      })
    }
  }

  getBackChildData (machine, dataName, value) {
    for (let i in this.allMachines) {
      if (this.allMachines[i].serial_num === machine) {
        this.allMachines[i][dataName] = value
      }
    }
    this.setState({
      machineListItems: this.allMachines
    })

    this.props.getBackData(machine, dataName, value)
  }

  sortByColumns (e) {
    let tempIdentities = this.state.machineListItems
    if (e === 'Last Data') {
      if (this.state.sortByColumn === e) {
        e = undefined
        this.setState({
          machinesSorted: '',
          lastDataSorted: <MdKeyboardArrowUp />,
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return Moment(a.last_row.datetime).valueOf() - Moment(b.last_row.datetime).valueOf() })
      } else {
        this.setState({
          machinesSorted: '',
          lastDataSorted: <MdKeyboardArrowDown />,
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return Moment(b.last_row.datetime).valueOf() - Moment(a.last_row.datetime).valueOf() })
      }
    } else if (e === 'Last Connected') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if (this.state.sortByColumn === e) {
        e = undefined
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: <MdKeyboardArrowUp />,
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return Moment(a.last_data_received).valueOf() - Moment(b.last_data_received).valueOf() })
      } else {
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: <MdKeyboardArrowDown />,
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return Moment(b.last_data_received).valueOf() - Moment(a.last_data_received).valueOf() })
      }
    } else if (e === 'Machines') {
      if (this.state.sortByColumn === e) {
        e = undefined
        this.setState({
          machinesSorted: <MdKeyboardArrowDown />,
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) {
          var x = b.serial_num.toLowerCase()
          var y = a.serial_num.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({
          machinesSorted: <MdKeyboardArrowUp />,
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) {
          var x = a.serial_num.toLowerCase()
          var y = b.serial_num.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else if (e === '24hr Utilization') {
      // console.log(this.state.sortByColumn + ' ' + e);
      tempIdentities.sort(function (a, b) {
        if (a.utilization === null) {
          return 1
        } else {
          return -1
        }
      })

      if (this.state.sortByColumn === e) {
        e = undefined
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: <MdKeyboardArrowUp />
        })
        tempIdentities.sort(function (a, b) { return Number(a.utilization) - Number(b.utilization) })
      } else {
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: '',
          utilizationSorted: <MdKeyboardArrowDown />
        })
        tempIdentities.sort(function (a, b) { return Number(b.utilization) - Number(a.utilization) })
      }
    } else if (e === 'Hours') {
      // console.log(this.state.sortByColumn + ' ' + e);
      tempIdentities.sort(function (a, b) {
        if (a.totalHours === 'N/A') {
          return 1
        } else {
          return -1
        }
      })

      if (this.state.sortByColumn === e) {
        e = undefined
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: <MdKeyboardArrowUp />,
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return a.totalHours - b.totalHours })
      } else {
        this.setState({
          machinesSorted: '',
          lastDataSorted: '',
          lastConnectedSorted: '',
          hoursSorted: <MdKeyboardArrowDown />,
          utilizationSorted: ''
        })
        tempIdentities.sort(function (a, b) { return b.totalHours - a.totalHours })
      }
    } else {
      return
    }
    // console.log(tempIdentities)
    this.setState({
      machineListItems: tempIdentities,
      sortByColumn: e
    })
  }

  buildAllMachines () {
    this.allMachines = this.state.machineIdentities.map((el, key) => {
      return {
        serial_num: el.serial_num,
        last_data_received: el.last_data_received,
        owner_designation: el.owner_designation
      }
    })
    this.setState({
      machineListItems: this.allMachines
    })
  }

  render () {
    let machineListItems = null
    if ((this.state.machineIdentities !== undefined) && (this.firstRender)) {
      this.buildAllMachines()
      this.firstRender = false
    }

    if (this.state.machineListItems !== undefined) {
      // console.log(this.state.machineListItems)
      machineListItems = this.state.machineListItems.map((el, index) => {
        return <MachineListItem
          key={index}
          getBackChildData={this.getBackChildData.bind(this)} data={el}
          machineDetailsPane={this.props.machineDetailsPane}
          ajaxError={this.props.ajaxError}
        />
      })
    }

    return (
      <Table responsive size='sm'>
        <thead>
          <tr>
            <th className='machine-overview-header' onClick={this.props.allowListSort ? (e) => this.sortByColumns('Machines') : undefined}>Machines {this.state.machinesSorted}</th>
            <th className='machine-overview-header' onClick={this.props.allowListSort ? (e) => this.sortByColumns('Last Data') : undefined}>Last Data {this.state.lastDataSorted}</th>
            <th className='machine-overview-header' onClick={this.props.allowListSort ? (e) => this.sortByColumns('Last Connected') : undefined}>Last Connected {this.state.lastConnectedSorted}</th>
            <th className='machine-overview-header' onClick={this.props.allowListSort ? (e) => this.sortByColumns('Hours') : undefined}>Hours {this.state.hoursSorted}</th>
            <th className='machine-overview-header' onClick={this.props.allowListSort ? (e) => this.sortByColumns('24hr Utilization') : undefined}>24hr Utilization {this.state.utilizationSorted}</th>
          </tr>
        </thead>
        <tbody className='machine-overview-rows'>
          {machineListItems}
        </tbody>
      </Table>
    )
  }
}

export default machineList
