import React, { Component } from 'react'
import Controls from '../../Controls/Controls'
import Moment from 'moment'
import Plot from 'react-plotly.js'
import { Spinner, Alert } from 'reactstrap'
import './Plots.css'
import shared from '../../../../../shared/shared'
import axios from 'axios'

class BattDeltaTemp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: undefined,
      end: undefined,
      rangeStart: undefined,
      rangeEnd: undefined,
      span: undefined,
      stepUnit: undefined,
      step: undefined,
      noData: false,
      setEnd: true,
      getData: false,
      plotData: undefined
    }

    this.signals = [
      'BM1_min_T_TerminalTemp_C',
      'BM2_min_T_TerminalTemp_C',
      'BM3_min_T_TerminalTemp_C',
      'BM1_min_T_CellTemp_C',
      'BM2_min_T_CellTemp_C',
      'BM3_min_T_CellTemp_C',
      'BM1_max_T_TerminalTemp_C',
      'BM2_max_T_TerminalTemp_C',
      'BM3_max_T_TerminalTemp_C',
      'BM1_max_T_CellTemp_C',
      'BM2_max_T_CellTemp_C',
      'BM3_max_T_CellTemp_C'
    //   'AmbientTemperature'
    ]
    this.view = 'Battery Delta Temp'
    this.step = undefined
    this.stepUnit = undefined
  }

  getMachineDateRange () {
    let body = {
      machine: this.props.details.serial_num,
      fields: this.signals
    }

    axios.post(
      shared.getVmsHost('machineDaterange'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.data.starts !== undefined) {
        this.setState({
          rangeStart: response.data.starts,
          rangeEnd: response.data.ends
        }, () => {
          this.setFirstStart()
        })
      } else {
        this.setState({
          noData: true
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  setFirstStart () {
    if (this.props.start === undefined) {
      if (this.props.deProps.start === undefined) {
        this.stepUnit = 'hours'
        this.step = 1
        this.setState({
          start: Moment(this.state.rangeEnd).subtract(1, 'hours'),
          end: Moment(this.state.rangeEnd),
          span: 1,
          step: 1,
          stepUnit: 'hours',
          getData: true
        }, () => { this.props.getBack(Moment(this.state.rangeEnd).subtract(1, 'hours'), Moment(this.state.rangeEnd)) })
      } else { // case where start comes from outside (notifications)
        let start = this.props.deProps.start.clone().subtract(0.5, 'hours')
        let end = this.props.deProps.start.clone().add(0.5, 'hours')
        this.stepUnit = 'hours'
        this.step = 1
        this.setState({
          start: start,
          end: end,
          span: 1,
          step: 1,
          stepUnit: 'hours',
          getData: true
        }, () => { this.props.getBack(start, end) })
      }
    } else {
      this.stepUnit = 'hours'
      this.step = 1
      this.setState({
        start: this.props.start,
        end: this.props.end,
        span: 1,
        step: 1,
        stepUnit: 'hours',
        getData: true
      })
    }
  }

  setStart (e) {
    let start = Moment(e).format()

    start = Moment(start)
    let end = start.clone()
    end.add(this.state.span, this.state.stepUnit)

    // console.log(start);

    this.setState({
      start: start,
      end: end,
      setEnd: false,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  setEnd (e) {
    let end = Moment(e).format()
    end = Moment(end)
    let start = end.clone()
    start = start.subtract(this.state.span, this.state.stepUnit)

    this.setState({
      end: end,
      start: start,
      setEnd: true,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  setSpan (e) {
    if (this.state.setEnd) {
      let start = this.state.end.clone()
      start.subtract(e, this.state.stepUnit)

      this.setState({
        span: e,
        start: start,
        plotData: undefined,
        getData: true
      }, () => { this.props.getBack(start, this.state.end) })
    } else {
      let end = this.state.start.clone()
      end.add(e, this.state.stepUnit)

      this.setState({
        span: e,
        end: end,
        plotData: undefined,
        getData: true
      }, () => { this.props.getBack(this.state.start, end) })
    }
  }

  setUnit (e) {
    this.stepUnit = e.target.value
    let span = this.state.end.diff(this.state.start, e.target.value)
    this.setState({
      span: span,
      stepUnit: this.stepUnit
    })
    // console.log(this.stepUnit);
  }

  setStep (e) {
    this.step = e
    this.setState({
      step: this.step
    })
    // console.log(this.step);
  }

  forward () {
    // console.log(JSON.stringify(Moment(this.state.start)));
    let start = this.state.start.clone().subtract(this.step, this.stepUnit)
    let end = this.state.end.clone().subtract(this.step, this.stepUnit)

    this.setState({
      start: start,
      end: end,
      stepUnit: this.stepUnit,
      step: this.step,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  back () {
    // console.log(JSON.stringify(Moment(this.state.start).add(this.step, this.stepUnit)));
    let start = this.state.start.clone().add(this.step, this.stepUnit)
    let end = this.state.end.clone().add(this.step, this.stepUnit)

    this.setState({
      start: start,
      end: end,
      stepUnit: this.stepUnit,
      step: this.step,
      plotData: undefined,
      getData: true
    }, () => { this.props.getBack(start, end) })
  }

  getData () {
    let body = {
      machine: this.props.details.serial_num,
      fields: this.signals,
      date_end: this.state.end.format().replace('T', ' ').slice(0, 19),
      date_start: this.state.start.format().replace('T', ' ').slice(0, 19)
    }

    axios.post(
      shared.getVmsHost('getData'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(response.data)
      this.setState({
        plotData: response.data,
        getData: false
      })
    }).catch((error) => { shared.handleError(error) })
  }

  // "BM1_max_T_TerminalTemp_C",
  // "BM2_max_T_TerminalTemp_C",
  // "BM3_max_T_TerminalTemp_C",
  // "BM1_max_T_CellTemp_C",
  // "BM2_max_T_CellTemp_C",
  // "BM3_max_T_CellTemp_C"

  renderPlot () {
    // console.log('renderPlot');
    let plotData = {
      datetime: [],
      BM1_TerminalTemp_Delta_C: [],
      BM2_TerminalTemp_Delta_C: [],
      BM3_TerminalTemp_Delta_C: [],
      BM1_CellTemp_Delta_C: [],
      BM2_CellTemp_Delta_C: [],
      BM3_CellTemp_Delta_C: [],
      WarningTemperature: []
    }
    let range = []
    let timeKey = {}
    let start = this.state.start.clone()

    while (this.state.end.diff(start, 'seconds') > 0) {
      range.push(start.clone().format().slice(0, 19))
      start.add(1, 'seconds')
    }

    for (let i in this.state.plotData) {
      timeKey[Moment.utc(this.state.plotData[i].datetime.slice(0, 19)).format().slice(0, 19)] = this.state.plotData[i]
    }

    let timeKeyKeys = Object.keys(timeKey)

    for (let i in range) {
      // console.log(range[i], timeKeyKeys.indexOf(range[i]))
      if (timeKeyKeys.indexOf(range[i]) > -1) {
        plotData.datetime.push(range[i])
        plotData.BM1_TerminalTemp_Delta_C.push(timeKey[range[i]].BM1_max_T_TerminalTemp_C - timeKey[range[i]].BM1_min_T_TerminalTemp_C)
        plotData.BM2_TerminalTemp_Delta_C.push(timeKey[range[i]].BM2_max_T_TerminalTemp_C - timeKey[range[i]].BM2_min_T_TerminalTemp_C)
        plotData.BM3_TerminalTemp_Delta_C.push(timeKey[range[i]].BM3_max_T_TerminalTemp_C - timeKey[range[i]].BM3_min_T_TerminalTemp_C)
        plotData.BM1_CellTemp_Delta_C.push(timeKey[range[i]].BM1_max_T_CellTemp_C - timeKey[range[i]].BM1_min_T_CellTemp_C)
        plotData.BM2_CellTemp_Delta_C.push(timeKey[range[i]].BM2_max_T_CellTemp_C - timeKey[range[i]].BM2_min_T_CellTemp_C)
        plotData.BM3_CellTemp_Delta_C.push(timeKey[range[i]].BM3_max_T_CellTemp_C - timeKey[range[i]].BM3_min_T_CellTemp_C)
        plotData.WarningTemperature.push(5)
      } else {
        plotData.datetime.push(range[i])
        plotData.BM1_TerminalTemp_Delta_C.push(undefined)
        plotData.BM2_TerminalTemp_Delta_C.push(undefined)
        plotData.BM3_TerminalTemp_Delta_C.push(undefined)
        plotData.BM1_CellTemp_Delta_C.push(undefined)
        plotData.BM2_CellTemp_Delta_C.push(undefined)
        plotData.BM3_CellTemp_Delta_C.push(undefined)
        plotData.WarningTemperature.push(5)
      }
    }

    return <Plot
      config={{ displaylogo: false,
        // showSendToCloud: true,
        modeBarButtonsToRemove: ['lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage']
      }}
      data={[
        {
          name: 'BMM1 Terminal Delta',
          type: 'line',
          marker: { color: 'rgb(255, 51, 51)' },
          x: plotData.datetime,
          y: plotData.BM1_TerminalTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'BMM1 Cell Delta',
          type: 'line',
          marker: { color: 'rgb(168, 247, 49)' },
          x: plotData.datetime,
          y: plotData.BM1_CellTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'BMM2 Terminal Delta',
          type: 'line',
          marker: { color: 'rgb(47, 250, 233)' },
          x: plotData.datetime,
          y: plotData.BM2_TerminalTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'BMM2 Cell Delta',
          type: 'line',
          marker: { color: 'rgb(51, 82, 255)' },
          x: plotData.datetime,
          y: plotData.BM2_CellTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'BMM3 Terminal Delta',
          type: 'line',
          marker: { color: 'rgb(207, 51, 255)' },
          x: plotData.datetime,
          y: plotData.BM3_TerminalTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'BMM3 Cell Delta',
          type: 'line',
          marker: { color: 'rgb(255, 51, 136)' },
          x: plotData.datetime,
          y: plotData.BM3_CellTemp_Delta_C,
          connectgaps: false
        },
        {
          name: 'Warning',
          type: 'line',
          marker: { color: 'rgba(255, 255, 0, 1)' },
          fill: 'tozeroy',
          fillcolor: 'rgba(0, 255, 0, 0.1)',
          x: plotData.datetime,
          y: plotData.WarningTemperature,
          connectgaps: false
        }
      ]}
      useResizeHandler
      style={{ width: '100%', height: '50vh' }}
      layout={{
        xaxis: { range: [JSON.stringify(this.state.start.format()).slice(1, 20).replace('T', ' '),
          JSON.stringify(this.state.end.format()).slice(1, 20).replace('T', ' ')] },
        bargap: 0,
        autosize: true,
        title: this.props.details.serial_num + ': Battery Delta Temp',
        legend: { x: 1.05 },
        yaxis: { range: 'auto', ticksuffix: '°C', showgrid: false, anchor: 'x', tickfont: { color: 'rgb(60, 189, 58)' } }
      }}
    />
  }

  renderControls () {
    return (
      <Controls
        start={this.state.start}
        setStart={this.setStart.bind(this)}
        end={this.state.end}
        span={this.state.span}
        step={this.state.step}
        setStep={this.setStep.bind(this)}
        units={this.state.stepUnit}
        setEnd={this.setEnd.bind(this)}
        setSpan={this.setSpan.bind(this)}
        setUnit={this.setUnit.bind(this)}
        forward={this.forward.bind(this)}
        back={this.back.bind(this)}
        rangeStart={this.state.rangeStart}
        rangeEnd={this.state.rangeEnd}
      />
    )
  }

  render () {
    // console.log(this.props);

    let plot = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    let controls = null

    if ((this.state.noData === false) && (this.state.rangeStart === undefined)) {
      this.getMachineDateRange()
    }

    if (this.state.stepUnit !== undefined) {
      controls = this.renderControls()
    }

    if (this.state.getData) {
      this.getData()
    }

    if (this.state.plotData !== undefined) {
      if (this.state.plotData.length > 0) {
        plot = this.renderPlot()
      } else {
        plot = <Alert color='info'>No data available for selected timeframe</Alert>
      }
    }

    if (this.state.noData) {
      plot = <Alert color='info'>Required data missing for {this.view} view</Alert>
    }

    return (
      <React.Fragment>
        {controls}
        {plot}
      </React.Fragment>
    )
  }
}

export default BattDeltaTemp
