import React, { Component } from 'react'
import DetailView from './DetailView/DetailView'
import Moment from 'moment'
import LongTermPlot from './LongTermPlot/LongTermPlot'
import { Nav, NavItem, NavLink, Spinner, Row, Col } from 'reactstrap'
import shared from '../../../../shared/shared'
import axios from 'axios'

class utilExplorerEVs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machineDateRangeStart: undefined,
      machineDateRangeEnd: undefined,
      currentView: 'Detail',
      machine_ID: this.props.details.serial_num,
      noData: false
    }

    this.hoursFields = undefined
    this.usagePlotFields = ['BatteryState', 'InDrillMode', 'ManageCharger1State', 'ManageSystemState']
    this.passDetailStartEnd = [undefined, undefined]
  }

  getMachineDateRange () {
    let body = {
      machine: this.state.machine_ID,
      fields: this.usagePlotFields
    }

    axios.post(
      shared.getVmsHost('machineDaterange'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(response.data);
      if (response.data.starts !== undefined) {
        this.setState({
          machineDateRangeStart: response.data.starts,
          machineDateRangeEnd: response.data.ends
        })
      } else {
        this.setState({
          noData: true
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  handleChangeView (view) {
    if (view !== this.state.currentView) {
      this.setState({
        currentView: view,
        longTermClicked: false
      })
    }
    this.passDetailStartEnd = [undefined, undefined]
  }

  handleLongTermClick (e) {
    // console.log(e.points[0].x);
    this.passDetailStartEnd = [Moment(e.points[0].x), Moment(e.points[0].x).add(1, 'days')]
    this.setState({
      currentView: 'Detail',
      longTermClicked: true
    })
  }

  render () {
    let currentView = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if ((this.state.machineDateRangeStart === undefined) && (this.state.noData === false)) {
      this.getMachineDateRange()
    } else {
      switch (this.state.currentView) {
        case 'Detail':
          currentView = <DetailView ajaxError={this.props.ajaxError} details={this.props.details} fields={this.usagePlotFields} passDetailStartEnd={this.passDetailStartEnd}
            machineRangeStart={this.state.machineDateRangeStart} machineRangeEnd={this.state.machineDateRangeEnd} overviewData={this.props.overviewData}
            view={this.state.currentView} longTermClicked={this.state.longTermClicked} />
          // console.log(this.state.currentView);
          break

        case 'Long Term':
          currentView = <LongTermPlot ajaxError={this.props.ajaxError} details={this.props.details} fields={this.usagePlotFields}
            machineRangeStart={this.state.machineDateRangeStart} machineRangeEnd={this.state.machineDateRangeEnd} clickBarForDetail={this.handleLongTermClick.bind(this)} />
          // console.log(this.state.currentView);
          break

        case 'Calendar':
          // console.log(this.state.currentView);
          break
        default: break
      }
    }

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink onClick={() => this.handleChangeView('Detail')} active={(this.state.currentView === 'Detail')}>Detail</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => this.handleChangeView('Long Term')} active={(this.state.currentView === 'Long Term')}>Long Term</NavLink>
          </NavItem>
        </Nav>
        <Row>
          <Col sm={12}>
            &nbsp;
            {currentView}
            &nbsp;
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default utilExplorerEVs
