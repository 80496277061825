import React, { Component } from 'react'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import './footer.css'
class Footer extends Component {
  render () {
    return (
      <footer className='mt-auto py-3 bg-dark'>
        <Container>
          <Row>
            <Col sm='3'>
              <h3 className='text-white footer-title'>Company</h3>
              <Nav vertical>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/about-us/overview'>History</NavLink></NavItem>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/about-us/leadership'>Leadership</NavLink></NavItem>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/media-en/news'>News</NavLink></NavItem>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/careers/view-all-opportunities'>Careers</NavLink></NavItem>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/contact/corporate-and-dealer-locations'>Office Locations</NavLink></NavItem>
                <NavItem><NavLink className='text-white lead' href='http://www.macleanengineering.com/customer-support'>Customer Support</NavLink></NavItem>
              </Nav>
            </Col>
            <Col sm={{ size: 3, offset: 6 }}>
              <h3 className='text-white footer-title'>Mission</h3>
              <div className='text-white lead'>
                We provide mobile equipment and environmental technology solutions based on hard rock mining experience, multi-discipline engineering capability, unit-of-one manufacturing expertise, and worldwide customer service and support.
                <hr />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

    )
  }
}
export default Footer
