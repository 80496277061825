import React, { Component } from 'react'
import { Alert, Button } from 'reactstrap'
import shared from './shared'
class Unauthorized extends Component {
  render () {
    return (
      <React.Fragment>
        <Alert color='danger'>
          <h3 style={{ fontWeight: 300, padding: '1% 0' }}>
            <center>
              You are currently not authorized. Please log in to view the service portal
              &nbsp;
              <Button onClick={() => { window.location.replace(`https://login.microsoftonline.com/memcoportal.onmicrosoft.com/oauth2/v2.0/authorize?client_id=d3aa2366-0cec-4a25-a5e5-f4bb1c8d9b14&response_type=code&redirect_uri=${shared.getEnvironmentURL('auth/openid/return&response_mode=query&scope=User.Read&state=12345')}`) }}> Login </Button>
            </center>
          </h3>
        </Alert>
      </React.Fragment>
    )
  }
}
export default Unauthorized
