import React, { Component } from 'react'

class FaultRowHistory extends Component {
  render () {
    let title = null
    let definition = null

    if (this.props.title === undefined) {
      // console.log(this.state);
      title = 'N/A'
      definition = 'SPN definition could not be found'
    } else {
      title = this.props.title
      definition = this.props.definition
    }

    return (
      <tr>
        <td>{this.props.spn}</td>
        <td>{this.props.lastSet}</td>
        <td>{title}</td>
        <td>{definition}</td>
      </tr>
    )
  }
}

export default FaultRowHistory
