import React, { Component } from 'react'
import axios from 'axios'
import shared from './../shared/shared'
import { Alert, Spinner } from 'reactstrap'
import Vms from './vms'

class HomePage extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      hasMachines: false,
      loading: false
    }
  }
  async componentDidMount () {
    await axios.get(shared.getVmsHost('getMachineCount'), { withCredentials: true, params: { csaID: this.props.customer.value } })
      .then(
        count => {
          if (count.data.count > 0) {
            this.setState({ hasMachines: true, loading: false })
          } else {
            this.setState({ hasMachines: false, loading: false })
          }
        }
      ).catch((error) => { shared.handleError(error) })
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.customer.value !== this.props.customer.value) {
      this.componentDidMount()
      this.setState({ loading: true })
    }
  }
  render () {
    return (
      this.state.loading
        ? <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        : this.state.hasMachines
          ? <Vms customer={this.props.customer} />
          : <Alert color='warning'>
            <h3 style={{ fontWeight: 300, padding: '1% 0' }}>
              <center> No Machines Assigned To Account &nbsp; </center>
            </h3>
          </Alert>
    )
  }
}

export default HomePage
