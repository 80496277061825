import React from 'react'
import Plot from 'react-plotly.js'

class PlotlyChart extends React.Component {
  renderPlot () {
    // let xRange = [this.props.rangeStart, this.props.rangeEnd];
    // let xAutoRange = false;
    let xDtick = 'D1'
    let barClick = this.props.clickBarForDetail

    if (this.props.plotData.x.length > 24) {
      xDtick = undefined
    }

    if (this.props.ticks === 'months') {
      // xRange = undefined;
      // xAutoRange = true;
      xDtick = 'M1'
    }

    return <Plot
      data={[
        { name: 'Daily Util',
          type: 'bar',
          marker: { color: 'rgb(60, 189, 58)' },
          x: this.props.plotData.x,
          y: this.props.plotData.y,
          showlegend: false }
      ]}
      config={{
        displaylogo: false,
        // showSendToCloud: true,
        modeBarButtonsToRemove: ['lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage']
      }}
      onClick={(data) => { barClick(data) }}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      layout={{
        // legend:{x:.35,y:1.1}, margin:{r:50},
        autosize: true,
        xaxis: { dtick: xDtick }, // range:xRange, autorange:xAutoRange,
        yaxis: { range: [0, 100], showline: false, ticksuffix: '%' }
      }}
    />
  }

  render () {
    // console.log(this.props);

    let plot = null

    if (this.props.plotData !== undefined) {
      // console.log(this.props.chartData);
      plot = this.renderPlot()
    }

    return (
      <React.Fragment>
        {plot}
      </React.Fragment>
    )
  }
}

export default PlotlyChart
