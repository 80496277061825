import React, { Component } from 'react'
import { Row, Button, Spinner } from 'reactstrap'
import axios from 'axios'
import Cookies from 'js-cookie'
import InternalReportTable from './report-table/internal-report-table'
import ExternalReportTable from './report-table/external-report-table'
import shared from '../shared/shared'
class Invoices extends Component {
  constructor (props) {
    super(props)

    this.state = {
      timePeriod: 1,
      fetching: false,
      isInternal: Cookies.get('Permissions') !== 'Distributor'
    }
    this.fetchInternalInvoices = this.fetchInternalInvoices.bind(this)
    this.fetchExternalInvoices = this.fetchExternalInvoices.bind(this)
  }
  fetchInternalInvoices () {
    this.setState({ fetching: true })
    axios.get(shared.getEnvironmentURL('getInvoices'),
      {
        withCredentials: true,
        params: {
          period: this.state.timePeriod
        }
      }
    )
      .then((response) => {
        this.setState({ invoiceData: response.data, fetching: false })
      })
      .catch((error) => { shared.handleError(error) })
  }

  async fetchExternalInvoices () {
    this.setState({ fetching: true })
    await axios.get(shared.getEnvironmentURL('getInvoicesForDistributor'),
      { withCredentials: true, params: { period: this.state.timePeriod } }
    )
      .then((response) => {
        this.setState({ invoiceData: response.data.invoiceDetails, fetching: false, customerName: response.data.CustomerName })
      })
      .catch((error) => { shared.handleError(error) })
  }

  componentDidMount () {
    this.state.isInternal ? this.fetchInternalInvoices() : this.fetchExternalInvoices()
  }

  componentDidUpdate (prevProps, prevState) {
    if ((!this.state.isInternal && (prevState.timePeriod !== this.state.timePeriod)) || prevProps.customer.key !== this.props.customer.key) {
      this.fetchExternalInvoices()
      this.props.reRender()
    } else if ((this.state.isInternal && (prevState.timePeriod !== this.state.timePeriod)) && prevProps.customer.key !== this.props.customer.key) {
      this.fetchInternalInvoices()
      this.props.reRender()
    }
  }

  renderInternalView () {
    return (
      <React.Fragment>
        <h2 className='display-4 text-center'>Invoices</h2>
        <Row>
          <Button
            disabled={this.state.timePeriod === 1}
            outline={this.state.timePeriod !== 1}
            onClick={async () => {
              await this.setState({ timePeriod: 1 })
            }}>30 Days</Button>
          &nbsp;
          <Button
            disabled={this.state.timePeriod === 2}
            outline={this.state.timePeriod !== 2}
            onClick={async () => {
              await this.setState({ timePeriod: 2 })
            }}>60 Days</Button>
          &nbsp;
          <Button
            disabled={this.state.timePeriod === 3}
            outline={this.state.timePeriod !== 3}
            onClick={async () => {
              await this.setState({ timePeriod: 3 })
            }}>90 Days</Button>
          &nbsp;
          <Button
            disabled={this.state.timePeriod === 6}
            outline={this.state.timePeriod !== 6}
            onClick={async () => {
              await this.setState({ timePeriod: 6 })
            }}>180 Days</Button>
        </Row>
        <br />
        { this.state.fetching
          ? <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
          : <InternalReportTable data={this.state.invoiceData === undefined ? [] : this.state.invoiceData} />
        }
      </React.Fragment>
    )
  }

  renderExternalView () {
    return (<React.Fragment>
      <h4 className='display-4 text-center'>
          Invoice Information for {this.props.customer === undefined ? null : this.props.customer.key}
      </h4>
      <Row>
        <Button
          disabled={this.state.timePeriod === 1}
          outline={this.state.timePeriod !== 1}
          onClick={async () => {
            await this.setState({ timePeriod: 1 })
          }}>30 Days</Button>
        &nbsp;
        <Button
          disabled={this.state.timePeriod === 2}
          outline={this.state.timePeriod !== 2}
          onClick={async () => {
            await this.setState({ timePeriod: 2 })
          }}>60 Days</Button>
        &nbsp;
        <Button
          disabled={this.state.timePeriod === 3}
          outline={this.state.timePeriod !== 3}
          onClick={async () => {
            await this.setState({ timePeriod: 3 })
          }}>90 Days</Button>
        &nbsp;
        <Button
          disabled={this.state.timePeriod === 6}
          outline={this.state.timePeriod !== 6}
          onClick={async () => {
            await this.setState({ timePeriod: 6 })
          }}>180 Days</Button>
      </Row>
      <br />
      { this.state.fetching
        ? <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        : <ExternalReportTable data={this.state.invoiceData === undefined ? [] : this.state.invoiceData} customerName={this.state.customerName === undefined ? null : this.state.customerName} />
      }
    </React.Fragment>
    )
  }
  render () {
    return (
      this.state.isInternal
        ? this.renderInternalView()
        : this.renderExternalView()
    )
  }
}
export default Invoices
