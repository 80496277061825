import React, { Component } from 'react'
import { Alert, Spinner } from 'reactstrap'
import axios from 'axios'
import ReactTable from 'react-table'
import shared from '../../shared/shared'
class DailyBacklog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.state.row.map(row => { return (`${row[accessor]}` || '').length }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  async componentDidMount () {
    const URL = shared.getEnvironmentURL(`getBacklogsForDistributor`)
    this.setState({ loaded: false })
    await axios.get(URL, { withCredentials: true }).then((response) => {
      this.setState({ row: response.data.backlogDetails, loaded: true })
    }).catch((error) => {
      shared.handleError(error)
    })
    this.setState({
      columns: [
        { accessor: 'warehouse', Header: 'Warehouse', width: this.getColumnWidth('warehouse', 'Warehouse') },
        { accessor: 'customerReference', Header: 'Customer Reference', width: this.getColumnWidth('customerReference', 'Customer Reference') },
        { accessor: 'salesOrder', Header: 'Sales Order', width: this.getColumnWidth('salesOrder', 'Sales Order') },
        { accessor: 'shipDate', Header: 'Delivery', width: this.getColumnWidth('shipDate', 'Delivery') },
        { accessor: 'itemNumber', Header: 'Part Number', width: this.getColumnWidth('itemNumber', 'Part Number') },
        { accessor: 'productName', Header: 'Description', width: this.getColumnWidth('productName', 'Description') },
        { accessor: 'deliveryRemainder', Header: 'Remaining Qty', width: this.getColumnWidth('deliveryRemainder', 'Remaining Qty') },
        { accessor: 'lineAmount', Header: 'Line Amount', width: this.getColumnWidth('lineAmount', 'Line Amount') }
      ]
    })
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      <React.Fragment>
        <Alert color='info'><h4 className='display-4'><center> Daily Backlog </center></h4></Alert>
        {
          this.state.loaded
            ? <ReactTable defaultPageSize={10} filterable data={this.state.row} columns={this.state.columns} defaultFilterMethod={this.filterCaseInsensitive} className='-striped -highlight' />
            : <Spinner color='info' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        }
      </React.Fragment>
    )
  }
}

export default DailyBacklog
