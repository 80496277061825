import env from 'dotenv'
import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from './component/header/header'
import Footer from './component/footer/footer'
import { Container, Row, Col } from 'reactstrap'
import HomeComponent from './component/home-component/HomeComponent'
import InvoicesComponent from './component/invoices/invoices'
import BacklogsComponent from './component/backlogs/backlogs'
import RigsInProgressComponent from './component/rigs-in-process/rigsInProcess'
import RigsComponent from './component/rigs/Rigs'
import ServiceReportsComponent from './component/service-report-library/subheader'
import PPRListComponent from './component/ppr/ListReportsComponent/ListReportsComponent'
import PPRComponent from './component/ppr/ListReportsComponent/ListReport'
import DashboardComponent from './component/dashboard/Dashboard'
import LoginComponent from './component/authentication/login'
import LogoutComponent from './component/authentication/logout'
import Unauthorized from './component/shared/unauthorized'
import InternalServerError from './component/shared/InternalServerError'
import NotPermitted from './component/shared/NotPermitted'
import WorkInProgress from './component/shared/WorkInProgress'
import UserNotConfigured from './component/shared/UserNotConfigured'
import VMS from './component/vms/home'
import Cookies from 'js-cookie'
import './App.css'

connect((store) => {
  return {
    header: store.header
  }
})
class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      component: null
    }
    this.reRender = this.reRender.bind(this)
  }
  pointlessUseOfEnv () {
    return env
  }
  reRender () {
    this.forceUpdate()
  }
  loadComponent (componentName = null, id = null) {
    let component
    switch (componentName) {
      case 'documoto':
        component = <WorkInProgress />
        break
      case 'vms':
        component = <VMS customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'service-reports':
        component = <ServiceReportsComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'invoices':
        component = <InvoicesComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'backlogs':
        component = <BacklogsComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'rigs':
        component = <RigsComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'rip':
        component = <RigsInProgressComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'list-reports':
        component = <PPRListComponent />
        break
      case 'list-report':
        component = <PPRComponent id={parseInt(id)} />
        break
      case 'dashboard':
        component = <DashboardComponent customer={Cookies.get('selectedCustomer') === undefined ? {} : JSON.parse(Cookies.get('selectedCustomer'))} reRender={this.reRender} />
        break
      case 'login':
        component = <LoginComponent />
        break
      case 'logout':
        component = <LogoutComponent />
        break
      case 'unauthorized':
        component = <Unauthorized />
        break
      case 'not-permitted':
        component = <NotPermitted />
        break
      case 'internal-server-error':
        component = <InternalServerError />
        break
      case 'wip':
        component = <WorkInProgress />
        break
      case 'user-not-configured':
        component = <UserNotConfigured />
        break
      default:
        component = <HomeComponent />
    }
    return component
  }

  render () {
    return (
      <React.Fragment>
        <Header component={this.state.component} reRender={this.reRender} />
        <Container>
          &nbsp;
          <Row>
            <Col sm='12'>
              <BrowserRouter>
                <Route path='/' exact render={() => this.loadComponent()} />
                <Route path='/login' exact component={() => {
                  window.location.replace(`https://login.microsoftonline.com/memcoportal.onmicrosoft.com/oauth2/v2.0/authorize?client_id=d3aa2366-0cec-4a25-a5e5-f4bb1c8d9b14&response_type=code&redirect_uri=${process.env.REACT_APP_ENVIRONMENT.trim() === 'development' ? 'http://localhost:3000/' : 'https://serviceportal_preprod.maclean.app/api/'}auth/openid/return&response_mode=query&scope=User.Read&state=12345`)
                  return null
                }} />
                <Route path='/logout' exact render={() => this.loadComponent('logout')} />
                <Route path='/unauthorized' exact render={() => this.loadComponent('unauthorized')} />
                <Route path='/internal-server-error' exact render={() => this.loadComponent('internal-server-error')} />
                <Route path='/no-permissions' render={() => this.loadComponent('not-permitted')} />
                <Route path='/user-not-configured' render={() => this.loadComponent('user-not-configured')} />
                <Route path='/invoices' render={() => this.loadComponent('invoices')} />
                <Route path='/backlogs' render={() => this.loadComponent('backlogs')} />
                <Route path='/rigs-in-process' render={() => this.loadComponent('rip')} />
                <Route path='/customer-dashboard' render={() => this.loadComponent('dashboard')} />
                <Route path='/service-reports' render={() => this.loadComponent('service-reports')} />
                <Route path='/rigs' render={() => this.loadComponent('rigs')} />
                <Route path='/documoto' render={() => this.loadComponent('documoto')} />
                <Route path='/vms' render={() => this.loadComponent('vms')} />
                <Route path='/list-reports' render={() => this.loadComponent('list-reports')} />
                <Route path='/list-report/:id' render={() => this.loadComponent('list-report', window.location.pathname.split('/')[window.location.pathname.split('/').length - 1])} />
                <Route path='/fr-ca' render={() => this.loadComponent('wip')} />
                <Route path='/es-mx' render={() => this.loadComponent('wip')} />
              </BrowserRouter>
            </Col>
          </Row>
          &nbsp;
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default App
