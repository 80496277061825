import React, { Component } from 'react'
import TopBar from '../TopBar/TopBar'
import MachineList from './MachinePreviews/MachineList/MachineList'
import './MachinePreviewPane.css'
import { Col, Spinner } from 'reactstrap'

class machinePreviewPane extends Component {
  render () {
    let machinePreviews = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if (this.props.machineIdentities !== undefined) {
      machinePreviews = <MachineList machineIdentities={this.props.machineIdentities} allowListSort={this.props.allowListSort}
        machineDetailsPane={this.props.machineDetailsPane} getBackData={this.props.getBackData} ajaxError={this.props.ajaxError} />
    }
    return (
      <Col sm='12'>
        <TopBar hasPopOver companyName={this.props.companyName} sortVehicleType={this.props.sortVehicleType} showMachines={this.props.machineIdentities}
          headerText='Machine Overview' companyMachines={this.props.companyMachines} />
        {machinePreviews}
      </Col>
    )
  }
}

export default machinePreviewPane
