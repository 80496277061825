import React, { Component } from 'react'
import Moment from 'moment'
import PlotlyChart from './PlotlyChart'
import { Alert, Table, Spinner } from 'reactstrap'
import shared from '../../../../../shared/shared'
import axios from 'axios'

class usagePlot extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedEndDate: this.props.details.last_row.datetime,
      rangeStart: undefined,
      rangeEnd: undefined,
      barData: undefined,
      trammingHours: undefined,
      auxHours: undefined,
      chargingHours: undefined,
      noDataTimeFrame: false
    }

    this.fields = this.props.usagePlotFields
    this.isUsagePlotSet = this.props.isUsagePlotSet
    this.usageData = undefined
    this.allowGetData = true
    this.date_start = undefined
    this.date_end = undefined
  }

  getData () {
    this.allowGetData = false
    this.date_start = Moment(this.state.selectedEndDate).subtract(24, 'hours')

    let body = {
      machine: this.props.details.serial_num,
      fields: this.fields,
      date_end: Moment(this.state.selectedEndDate).format().replace('T', ' ').slice(0, 19),
      date_start: this.date_start.format().replace('T', ' ').slice(0, 19)
    }

    this.date_start = body.date_start
    this.date_end = body.date_end

    axios.post(
      shared.getVmsHost('getData'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.status > 298) {
        this.setState({
          noDataTimeFrame: true
        })
      } else {
        this.usageData = response.data
        this.props.getBackFirstUtilData(response.data)
        this.convertToBarData()
      }
    }).catch((err) => {
      // if (response.status === undefined) {
      console.log(err)
      this.props.ajaxError(err)
      // }
    })
  }

  convertToBarData () {
    if (this.usageData.length > 0) {
      let barData = { tramMode: { datetime: [], y: [], base: [] }, chargeMode: { datetime: [], y: [], base: [] } }
      let chargingCount = 0
      let tramCount = 0

      for (let i = 0; i < this.usageData.length; i += 5) {
        if ((this.usageData[i].ManageSystemState === 3) && (this.usageData[i].BatteryState === 3)) {
          barData.tramMode.datetime.push(this.usageData[i].datetime)
          barData.tramMode.y.push(1)
          barData.tramMode.base.push(0)
          tramCount += 5
        }
        if ((this.usageData[i].ManageCharger1State === 5) && (this.usageData[i].BatteryState === 3)) {
          barData.chargeMode.datetime.push(this.usageData[i].datetime)
          barData.chargeMode.y.push(1)
          barData.chargeMode.base.push(1)
          chargingCount += 5
        }
      }

      this.usageData = undefined

      if ((barData.chargeMode.datetime.length > 0) || (barData.tramMode.datetime.length > 0)) {
        this.setState({
          barData: barData,
          chargingHours: (chargingCount / 3600),
          trammingHours: (tramCount / 3600),
          rangeStart: this.date_start,
          rangeEnd: this.date_end,
          noDataTimeFrame: false
        })
      } else {
        this.setState({
          noDataTimeFrame: true
        })
      }
    } else {
      this.setState({
        noDataTimeFrame: true
      })
    }
  }

  renderPlotTable () {
    let makeTable = (
      <React.Fragment>
        <h3><center>24hr Utilization Detail</center></h3>
        <Table size='sm' responsive bordered>
          <tbody>
            <tr>
              <th>Beginning</th><th>Ending</th>
            </tr>
            <tr>
              <td>{this.state.rangeStart}</td><td>{this.state.rangeEnd}</td>
            </tr>
          </tbody>
        </Table>
        <Table size='sm' responsive bordered>
          <tbody>
            <tr>
              <th>Tramming Hours</th><th>Charging Hours</th>
            </tr>
            <tr>
              <td>{this.state.trammingHours.toFixed(2)}</td><td>{this.state.chargingHours.toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>

    )

    return makeTable
  }

  render () {
    // console.log(this.state)

    let plotTable = null
    let plotlychart = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if (this.allowGetData) {
      this.getData()
    }

    if (this.state.barData !== undefined) {
      plotlychart = <PlotlyChart isUsagePlotSet={this.isUsagePlotSet.bind(this)} rangeStart={this.state.rangeStart} rangeEnd={this.state.rangeEnd} barData={this.state.barData} />
      plotTable = this.renderPlotTable()
    }

    if (this.state.noDataTimeFrame) {
      plotlychart = <Alert color='primary'><center><h3>No data available from selected timeframe.</h3></center></Alert>
    }

    return (
      <React.Fragment>{plotTable !== null ? plotTable : null} {plotlychart !== null ? plotlychart : null} </React.Fragment>
    )
  }
}

export default usagePlot
