import React, { Component } from 'react'
import PlotlyChart from './PlotlyChart'
import DeTable from './DeTable'
import { Spinner, Progress } from 'reactstrap'
import Moment from 'moment'
import shared from '../../../../../../shared/shared'
import axios from 'axios'

class usagePlot extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: this.props.start,
      end: this.props.end,
      timeUnits: this.props.units,
      plotData: undefined,
      perAvg: undefined,
      noDataTimeFrame: false,
      plot: <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    }

    this.fields = this.props.fields
    this.allowGetData = true
    this.range = undefined
    this.rangeCounter = undefined
  }

  componentWillReceiveProps (nextProps) {
    if ((nextProps.end !== this.state.end) || (nextProps.start !== this.state.start) || (nextProps.units !== this.state.timeUnits)) {
      this.allowGetData = true
      this.rangeCounter = undefined
      this.range = undefined
      this.setState({
        start: nextProps.start,
        end: nextProps.end,
        timeUnits: nextProps.units,
        plotData: undefined,
        plot: <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
      })
    } else {

    }
  }

  createTimeRanges () {
    let range = []
    let start

    // console.log('createTimeRanges');

    if (this.state.timeUnits === 'days') {
      start = this.state.start.format().slice(0, 10)
      start = start + ' 00:00:00'
      start = Moment.utc(start)
      // console.log(this.state.end.diff(start, 'days'));
    } else {
      start = Moment(this.state.start).format().slice(0, 8)
      start = start + '01 00:00:00'
      // console.log(start);
      start = Moment.utc(start)
    }

    while (this.state.end.diff(start, 'minutes') > 0) {
      range.push([start.clone()])
      start.add(1, this.state.timeUnits)
    }

    this.range = range
    if (range.length > 0) {
      this.rangeCounter = 0

      for (let i in this.range) {
        // console.log(this.range[i])
        this.getCounts(i)
      }
    }
  }

  getCounts (ind) {
    let start = this.range[ind][0].format().replace('T', ' ').slice(0, 19)
    let end = this.range[ind][0].clone().add(1, this.state.timeUnits).format().replace('T', ' ').slice(0, 19)

    let body = {
      machine_id: this.props.details.serial_num,
      date_end: end,
      date_start: start
    }

    axios.post(
      shared.getVmsHost('utilCounts'),
      body, { withCredentials: true }
    ).then((response) => {
      this.setState({
        plot: (
          <div className='progressBarUtil'>
            <h1>fetching: {this.state.timeUnits === 'days' ? start.slice(0, 10) : start.slice(0, 7)}</h1>
            <Progress animated color='succcess' value={(this.rangeCounter / this.range.length) * 100} />
          </div>
        )
      })
      this.range[ind].push(response.data[0])
      this.rangeCounter++
      if (this.rangeCounter === this.range.length) {
        this.convertToPlotData()
      }
    }).catch((error) => { shared.handleError(error) })
  }

  convertToPlotData () {
    let plotData = { x: [], y: [] }
    let perAvg = 0

    for (let i in this.range) {
      let y
      let counter
      try {
        if (this.range[i][1].count !== undefined) {
          counter = this.range[i][1].count
        } else {
          counter = 0
        }
      } catch (e) {
        counter = 0
      } finally {}

      if (this.state.timeUnits === 'days') {
        y = (counter / 864) // percent
        perAvg += y
        plotData.y.push(y.toFixed(0))

        // console.log(y, perAvg)
      } else {
        y = (counter / 26280) // percent
        perAvg += y
        plotData.y.push(y.toFixed(0))
      }
      plotData.x.push(this.range[i][0].format().slice(0, 19).replace('T', ' '))
    }

    perAvg = perAvg / this.range.length

    // console.log(plotData)

    if (plotData.x.length > 0) {
      this.setState({
        plotData: plotData,
        noDataTimeFrame: false,
        perAvg: perAvg,
        plot: (<PlotlyChart rangeStart={this.range[0][0].format().slice(0, 19).replace('T', ' ')}
          rangeEnd={this.range[this.range.length - 1][0].format().slice(0, 19).replace('T', ' ')} plotData={plotData}
          ticks={this.state.timeUnits} clickBarForDetail={this.props.clickBarForDetail} />)
      })
    } else {
      this.setState({
        noDataTimeFrame: true,
        plot: <h1 style={{ color: 'blue', opacity: '0.5', marginLeft: '20%', marginTop: '10%' }}>no data available from selected timeframe</h1>
      })
    }
  }

  render () {
    let table = null

    if (this.allowGetData) {
      this.createTimeRanges()
      this.allowGetData = false
    }

    if (this.state.plotData !== undefined) {
      table = <DeTable perAvg={this.state.perAvg} />
    }

    return (
      <React.Fragment>
        {table}
        {this.state.plot}
      </React.Fragment>
    )
  }
}

export default usagePlot
