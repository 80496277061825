import React, { Component } from 'react'
import DateTimePicker from 'react-datepicker'
import ControlledInputSpan from './ControlledInputSpan'
import { Form, FormGroup, Label, Row, Col } from 'reactstrap'
import Moment from 'moment'
import './Controls.css'
import '../../../../../../node_modules/react-datepicker/dist/react-datepicker.css'

class controls extends Component {
  render () {
    return (
      <Form className='fault-history-form'>
        <Row form>
          <Col md={{ size: 4, offset: 1 }}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='beginning-date-time'>Beginning</Label>
              <DateTimePicker
                className='form-control'
                id='beginning-date-time'
                selected={this.props.start.toDate()}
                onChange={this.props.setStart}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy h:mm aa'
                minDate={Moment(this.props.rangeStart).toDate()}
                maxDate={Moment(this.props.rangeEnd).toDate()} />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='span-days'>Span (days)</Label>
              <ControlledInputSpan id='span-days' units={this.props.units} onChange={this.props.setSpan} value={this.props.span} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
              <Label for='ending-date-time'>End</Label>
              <DateTimePicker
                id='ending-date-time'
                className='form-control'
                selected={this.props.end.toDate()}
                onChange={this.props.setEnd}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy h:mm aa'
                minDate={Moment(this.props.rangeStart).toDate()}
                maxDate={Moment(this.props.rangeEnd).toDate()} />
            </FormGroup>
          </Col>
        </Row>
        &nbsp;
      </Form>
    )
  }
}

export default controls
