import React, { Component } from 'react'
import { Button, Progress } from 'reactstrap'
import ReactTable from 'react-table'
import ReactExport from 'react-export-excel'
import moment from 'moment'
import 'react-table/react-table.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: [
        { accessor: 'ms', Header: 'Status', width: this.getColumnWidth('ms', 'Status') },
        { accessor: 'plant', Header: 'Plant', width: this.getColumnWidth('plant', 'Plant') },
        { accessor: 'type', Header: 'Machine Type', width: this.getColumnWidth('type', 'Machine Type') },
        { accessor: 'fabrication',
          Header: 'Fabrication',
          width: this.getColumnWidth('fabrication', 'Fabrication'),
          Cell: props => {
            let value = props.value * 100
            let color = value >= 75
              ? 'success'
              : value >= 50
                ? 'warning'
                : 'danger'
            return <React.Fragment>
              <div className='text-center'>{value.toFixed(2) + ' %'}</div>
              <Progress color={color} value={value} />
            </React.Fragment>
          }
        },
        { accessor: 'completion',
          Header: 'Assembly',
          width: this.getColumnWidth('partNumber', 'Assembly'),
          Cell: props => {
            let value = props.value * 100
            let color = value >= 75
              ? 'success'
              : value >= 50
                ? 'warning'
                : 'danger'
            return <React.Fragment>
              <div className='text-center'>{value.toFixed(2) + ' %'}</div>
              <Progress color={color} value={value} />
            </React.Fragment>
          }
        },
        { accessor: 'customer', Header: 'Customer', width: this.getColumnWidth('customer', 'Customer') },
        { accessor: 'site', Header: 'Site', width: this.getColumnWidth('site', 'Site') },
        { accessor: 'model', Header: 'Model', width: this.getColumnWidth('model', 'Model') },
        { accessor: 'number', Header: 'Number', width: this.getColumnWidth('number', 'Number') },
        { accessor: 'partNumber', Header: 'Part Number', width: this.getColumnWidth('partNumber', 'Part Number') },
        { accessor: 'description', Header: 'Description', width: this.getColumnWidth('description', 'Description') },
        { accessor: 'accRep', Header: 'Account Rep', width: this.getColumnWidth('accRep', 'Account Rep') },
        { accessor: 'region', Header: 'Region', width: this.getColumnWidth('region', 'Region') },
        { accessor: 'commitFactoryComplete', Header: 'Customer Date', width: this.getColumnWidth('commitFactoryComplete', 'Customer Date'), Cell: props => { return props.value !== null ? moment(props.value).format('YYYY-MM-DD') : null } }
      ]
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => { return (`${row[accessor]}` || '').length }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }

  render () {
    return (
      <React.Fragment>
        <ExcelFile filename={`Invoice_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
          <ExcelSheet data={this.props.data} name='Invoice Data'>
            { this.state.columns.map((column) => {
              return (<ExcelColumn
                key={column}
                label={column.Header}
                value={
                  (col) => {
                    return col[column.accessor]
                  }
                }
              />)
            })}
          </ExcelSheet>
        </ExcelFile>
        <br />
        <br />
        <ReactTable
          filterable
          defaultPageSize={10}
          data={this.props.data}
          columns={this.state.columns}
          defaultFilterMethod={this.filterCaseInsensitive}
          className='-striped -highlight' />
        <br />
        <ExcelFile filename={`Invoice_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
          <ExcelSheet data={this.props.data} name='Invoice Data'>
            { this.state.columns.map((column) => {
              return (<ExcelColumn
                key={column}
                label={column.Header}
                value={
                  (col) => {
                    return col[column.accessor]
                  }
                }
              />)
            })}
          </ExcelSheet>
        </ExcelFile>
      </React.Fragment>
    )
  }
}

export default ReportTable
