import React, { Component } from 'react'
import { Table, Alert } from 'reactstrap'
import Moment from 'moment'

class deTable extends Component {
  constructor (props) {
    super(props)
    this.tableFields = this.props.deTableFields

    this.exists = (field) => {
      try {
        if (field !== undefined) {
          return true
        } else {
          return false
        }
      } catch {
        return false
      } finally {}
    }
  }

  renderTable () {
    this.props.isTableSet(true)
    return (
      <Table size='sm' responsive bordered>
        <tbody>
          <tr>
            <th scope='row'>Vehicle Type</th>
            <td>{this.props.details.type.replace('_', ' ')}</td>
            <th scope='row'>Last Data Timestamp</th>
            <td>{this.exists(this.props.details.last_row.datetime) ? this.props.details.last_row.datetime.replace('T', ' ').replace('.000Z', '') : 'N/A'}</td>
            <th scope='row'>Last Data Received (GMT)</th>
            <td>{this.exists(this.props.details.last_data_received) ? Moment(Moment.utc(this.props.details.last_data_received).format()).format().slice(0, 19).replace('T', ' ') : 'N/A'}</td>
          </tr>
          <tr>
            <th scope='row'>Total Hours</th>
            <td>{this.exists(this.props.details.totalHours) ? this.props.details.totalHours.toFixed(2) : 'N/A'}</td>
            <th scope='row'>Tramming Hours</th>
            <td>{this.exists(this.props.details.last_row.TrammingHourMeter) ? this.props.details.last_row.TrammingHourMeter.toFixed(2) : 'N/A'}</td>
            <th scope='row'>Battery Hours</th>
            <td>{this.exists(this.props.details.last_row.BatteryHourMeter) ? this.props.details.last_row.BatteryHourMeter.toFixed(2) : 'N/A'}</td>
          </tr>
          <tr>
            <th scope='row'>Charging Hours</th>
            <td>{this.exists(this.props.details.last_row.ChargingHourMeter) ? this.props.details.last_row.ChargingHourMeter.toFixed(2) : 'N/A'}</td>
            <th scope='row'>Regen Hours</th>
            <td>{this.exists(this.props.details.last_row.RegenBrakingHourMeter) ? this.props.details.last_row.RegenBrakingHourMeter.toFixed(2) : 'N/A'}</td>
            <th scope='row'>Battery State of Charge</th>
            <td>{this.exists(this.props.details.last_row.StateOfCharge) ? this.props.details.last_row.StateOfCharge.toString() + '%' : 'N/A'}</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  render () {
    let table = null // <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    let renderTable = true

    if (this.props.details !== undefined) {
      let theseKeys = Object.keys(this.props.details.last_row)

      for (let i = 0; i < this.tableFields.length; i++) {
        let isIn = false
        for (let j = 0; j < theseKeys.length; j++) {
          if (this.tableFields[i] === theseKeys[j]) {
            // console.log(this.tableFields[i])
            isIn = true
            break
          }
        }
        if (!isIn) {
          renderTable = false
          break
        }
      }

      if (renderTable) {
        table = this.renderTable()
      } else {
        table = <Alert color='primary'><center><h3>Overview Data Not Available At This Time</h3></center></Alert>
      }
    }
    return (<React.Fragment> {table} </React.Fragment>)
  }
}

export default deTable
