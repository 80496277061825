import React, { Component } from 'react'
import { Table } from 'reactstrap'
class deTable extends Component {
  renderTable () {
    let table =
      <React.Fragment>
        <Table>
          <thead>
            <tr>
              <th>Tramming Hours</th>
              <th>Aux Hours</th>
              <th>Charging Hours</th>
              <th>Total Hours</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.props.trammingHours.toFixed(2)}</td>
              <td>{this.props.auxHours.toFixed(2)}</td>
              <td>{this.props.chargingHours.toFixed(2)}</td>
              <td>{this.props.totalHours.toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>

    return table
  }

  render () {
    // console.log(this.props);

    let table = null

    if (this.props.trammingHours !== undefined) {
      table = this.renderTable()
    }

    return (
      <React.Fragment>
        {table}
      </React.Fragment>
    )
  }
}

export default deTable
