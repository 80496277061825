import React, { Component } from 'react'
import Moment from 'moment'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import './NotificationListTable.css'
import { Table, Button, Input, FormGroup, Popover, PopoverHeader, PopoverBody, Spinner } from 'reactstrap'
import axios from 'axios'
import shared from '../../../../shared/shared'

class machineListTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      notifications: this.props.notifications,
      sortByColumn: 'Machine Time',
      nameSorted: '',
      machineTimeSorted: <MdKeyboardArrowDown />,
      timeReceivedSorted: '',
      severitySorted: '',
      machineSorted: '',
      toDismiss: [],
      comment: undefined,
      popoverIsOpen: false,
      allowSubmit: false,
      table: null
    }
    this.sortSameOld = false
    this.toDismiss = []
    this.lastNotifications = undefined

    this.checked = (e) => {
      if (this.toDismiss.indexOf(e) > -1) {
        return true
      } else {
        return false
      }
    }
  }

  arraysEqual (arr1, arr2) {
    try {
      if (arr1.length !== arr2.length) { return false }
    } catch {
      return false
    }

    try {
      for (var i = arr1.length; i--;) {
        if (arr1[i].id !== arr2[i].id) { return false }
      }
    } catch {
      return false
    }

    return true
  }

  handleClicked (el, view) {
    this.props.machineDetailPane(el, view)
    this.props.popOpenList(undefined, undefined, undefined)
  }

  handleCheckbox (e) {
    if (this.toDismiss.indexOf(e) > -1) {
      this.toDismiss.splice(this.toDismiss.indexOf(e), 1)
    } else {
      this.toDismiss.push(e)
    }
    this.setState({
      toDismiss: this.toDismiss
    })
  }

  handleDismiss () {
    let controlState = (res) => {
      if (res.status !== 200) {
        throw new Error('notification acknowledgement failed!')
      } else {
        let keepNotifications = []
        for (let i in this.state.notifications) {
          if (this.state.toDismiss.indexOf(this.state.notifications[i].id) < 0) {
            keepNotifications.push(this.state.notifications[i])
          } else {
          // console.log(this.state.notifications[i].id, this.state.toDismiss.indexOf(this.state.notifications[i].id))
          }
        }
        this.setState({
          notifications: keepNotifications,
          toDismiss: [],
          comment: undefined
        })
      }
    }

    let body = {
      ids: [this.state.toDismiss],
      timestamp: JSON.stringify(Moment().format()).slice(1, 20).replace('T', ' '),
      comment: this.state.comment
    }

    axios.post(
      shared.getVmsHost('acknowledgeCustomerNotifications'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(response.data);
      controlState(response)
    }).catch((error) => { shared.handleError(error) })
  }

  validator (e) {
    if ((e.replace(/\s/g, '').length >= 1) && (e.replace(/\s/g, '').length <= 240)) {
      this.setState({
        allowSubmit: true,
        comment: e
      })
    } else {
      this.setState({
        allowSubmit: false,
        comment: e
      })
    }
  }

  submit () {
    if (this.state.allowSubmit) {
      this.setState({
        popoverIsOpen: false,
        allowSubmit: false,
        table: <tr><Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' /></tr>
      }, () => this.handleDismiss())
    } else {

    }
  }

  cancel () {
    this.setState({
      popoverIsOpen: false,
      comment: undefined,
      allowSubmit: false
    })
  }

  toggle () {
    this.setState({
      popoverIsOpen: !this.state.popoverIsOpen
    })
  }

  sortByColumns (e, notiList = undefined) {
    // console.log(e);
    let tempNotiList

    if (notiList === undefined) {
      tempNotiList = this.state.notifications
    } else {
      tempNotiList = notiList
    }

    if (e === undefined) {
      e = this.state.sortByColumn
      this.sortSameOld = !this.sortSameOld
    }

    if (e === 'Machine Time') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowUp />, timeReceivedSorted: '', machineSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) { return Moment(a.hmi_time).valueOf() - Moment(b.hmi_time).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowDown />, timeReceivedSorted: '', machineSorted: '', severitySorted: '' })
        tempNotiList.sort(function (a, b) { return Moment(b.hmi_time).valueOf() - Moment(a.hmi_time).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Time Received') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowUp />, machineSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) { return Moment(a.server_time).valueOf() - Moment(b.server_time).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowDown />, machineSorted: '', severitySorted: '' })
        tempNotiList.sort(function (a, b) { return Moment(b.server_time).valueOf() - Moment(a.server_time).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Name') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: <MdKeyboardArrowDown />, machineTimeSorted: '', timeReceivedSorted: '', machineSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) {
          var x = b.name.toLowerCase()
          var y = a.name.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ nameSorted: <MdKeyboardArrowUp />, machineTimeSorted: '', timeReceivedSorted: '', machineSorted: '', severitySorted: '' })
        this.sortSameOld = true
        tempNotiList.sort(function (a, b) {
          var x = a.name.toLowerCase()
          var y = b.name.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else if (e === 'Severity') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', machineSorted: '', severitySorted: <MdKeyboardArrowUp /> })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) {
          if (a.severity === null) a.severity = 10
          if (b.severity === null) b.severity = 10
          return b.severity - a.severity
        })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', machineSorted: '', severitySorted: <MdKeyboardArrowDown /> })
        tempNotiList.sort(function (a, b) {
          if (a.severity === null) a.severity = 10
          if (b.severity === null) b.severity = 10
          return a.severity - b.severity
        })
        this.sortSameOld = true
      }
    } else if (e === 'Machine') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', machineSorted: <MdKeyboardArrowDown />, severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) {
          var x = b.serial_num.toLowerCase()
          var y = a.serial_num.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', machineSorted: <MdKeyboardArrowUp />, severitySorted: '' })
        this.sortSameOld = true
        tempNotiList.sort(function (a, b) {
          var x = a.serial_num.toLowerCase()
          var y = b.serial_num.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else {
      return
    }

    this.setState({
      notifications: tempNotiList,
      sortByColumn: e
    }, () => this.renderMachineListTable())
  }

  handleSeverityColor (severity) {
    switch (severity) {
      case 1:
        return <img src={require('../../../Octagon_Danger_WHMIS.jpg')} alt='' />
      case 2:
        return <img src={require('../../../Square_Warning_WHMIS.jpg')} alt='' />
      case 3:
        return <img src={require('../../../Triangle_Caution_WHMIS.jpg')} alt='' />
      default:
        return <span className='dotgrey' />
    }
  }

  renderMachineListTable () {
    this.setState({
      table: this.state.notifications.map((el, index) => {
        return (
          <tr key={index}>
            <td><div className='dotContainer'>{this.handleSeverityColor(el.severity)}</div></td>
            <td>{el.serial_num}</td>
            <td onClick={(e) => this.handleClicked(el.serial_num, 'Notification History')} className='clickable'>{el.name.replace(/_/g, ' ')}</td>
            <td>{el.hmi_time.slice(0, 19).replace('T', ' ')}</td>
            <td>{Moment(Moment.utc(el.server_time).format()).format().slice(0, 19).replace('T', ' ')}</td>
            <td>{el.notification.slice(0, -27)}</td>
            <td><Input type='checkbox' onClick={(e) => this.handleCheckbox(el.id)} style={{ marginLeft: '40%' }} /></td>
          </tr>
        )
      })
    })
  }

  render () {
    if ((this.state.notifications !== undefined) && (!this.arraysEqual(this.state.notifications, this.lastNotifications))) {
      this.renderMachineListTable()
      this.lastNotifications = [...this.state.notifications]
    }

    return (
      <React.Fragment>
        <Table responsive size='sm'>
          <thead>
            <tr>
              <th className='sortable' onClick={() => this.sortByColumns('Severity')}>Severity {this.state.severitySorted}</th>
              <th className='sortable' onClick={() => this.sortByColumns('Machine')}>Machine {this.state.machineSorted}</th>
              <th className='sortable' onClick={() => this.sortByColumns('Name')}>Name {this.state.nameSorted}</th>
              <th className='sortable' onClick={() => this.sortByColumns('Machine Time')}>Machine Time {this.state.machineTimeSorted}</th>
              <th className='sortable' onClick={() => this.sortByColumns('Time Received')}>Time Received {this.state.timeReceivedSorted}</th>
              <th>Message</th>
              <th>
                <Button id='DisssmissssButttonnn' disabled={!(this.state.toDismiss.length > 0)}>Dismiss</Button>
                <Popover style={{ width: '350px' }} isOpen={this.state.popoverIsOpen} target='DisssmissssButttonnn' toggle={this.toggle.bind(this)} trigger='legacy'>
                  <PopoverHeader>
                    {'Please enter your comments to dismiss ' + this.state.toDismiss.length.toString() + ' notification(s):'}
                  </PopoverHeader>
                  <PopoverBody>
                    <FormGroup>
                      <Input
                        valid={this.state.allowSubmit}
                        invalid={!this.state.allowSubmit}
                        onChange={(e) => this.validator(e.target.value)}
                        type='textarea'
                        name='text'
                        id='exampleText'
                        value={this.state.comment}
                        style={{ height: '150px' }} />
                    </FormGroup>
                    <Button color='primary' onClick={() => this.cancel()} id='cancelbutton'>Cancel</Button>
                    <Button color='primary' disabled={!this.state.allowSubmit} className='float-right' onClick={() => this.submit(this.props.id)} id='submitbutton'>Submit</Button>
                  </PopoverBody>
                </Popover>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.table}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }
}

export default machineListTable
