import React, { Component } from 'react'
import { Jumbotron, Button } from 'reactstrap'
import shared from '../shared/shared'
import './HomeComponent.css'
class HomeComponent extends Component {
  render () {
    let loginButton = document.cookie.split('Profile=')[1] !== undefined
      ? null
      : <Button onClick={() => { window.location.replace(`https://login.microsoftonline.com/memcoportal.onmicrosoft.com/oauth2/v2.0/authorize?client_id=d3aa2366-0cec-4a25-a5e5-f4bb1c8d9b14&response_type=code&redirect_uri=${shared.getEnvironmentURL('auth/openid/return&response_mode=query&scope=User.Read&state=12345')}`) }}> Login </Button>
    return (
      <React.Fragment>
        <br />
        <Jumbotron style={{ backgroundSize: 'cover' }}>
          <div style={{ backgroundColor: '#505050e0', padding: '2rem', borderRadius: '0.3rem' }}>
            <h2 className='display-4 text-center text-white'>MacLean Engineering Customer Portal</h2>
            <br />
            <p className='lead py-3 text-white text-center' style={{ borderBottom: '1px solid white' }} />
            {loginButton}
          </div>
        </Jumbotron>
      </React.Fragment>
    )
  }
}

export default HomeComponent
