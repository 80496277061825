import React, { Component } from 'react'
import { Button } from 'reactstrap'
import ReactTable from 'react-table'
import ReactExport from 'react-export-excel'
import 'react-table/react-table.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: [
        { accessor: 'InvoiceID', Header: 'Invoice ID', width: this.getColumnWidth('InvoiceID', 'Invoice ID') },
        { accessor: 'InvoiceDate', Header: 'Invoice Date', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('InvoiceDate', 'Invoice Date') },
        { accessor: 'SalesOrder', Header: 'Sales Order', width: this.getColumnWidth('SalesOrder', 'Sales Order') },
        { accessor: 'PartNumber', Header: 'Part Number', width: this.getColumnWidth('PartNumber', 'Part Number') },
        { accessor: 'Quantity', Header: 'Quantity', width: this.getColumnWidth('Quantity', 'Quantity') },
        { accessor: 'UnitPrice', Header: 'Unit Price', width: this.getColumnWidth('UnitPrice', 'Unit Price') },
        { accessor: 'ExtInvoice', Header: 'Ext Invoice', Cell: props => `$${props.value.toFixed(2)}`, width: this.getColumnWidth('ExtInvoice', 'Ext Invoice') },
        { accessor: 'Site', Header: 'Site', width: this.getColumnWidth('Site', 'Site') },
        { accessor: 'SiteName', Header: 'Site Name', width: this.getColumnWidth('SiteName', 'Site Name') },
        { accessor: 'Warehouse', Header: 'Warehouse', width: this.getColumnWidth('Warehouse', 'Warehouse ID') },
        { accessor: 'Location', Header: 'Location', width: this.getColumnWidth('Location', 'Location') },
        { accessor: 'CustomerAccount', Header: 'Customer Account', width: this.getColumnWidth('CustomerAccount', 'Customer Account') },
        { accessor: 'CustomerName', Header: 'Customer Name', width: this.getColumnWidth('CustomerName', 'Customer Name') },
        { accessor: 'Reference', Header: 'Line Pool', width: this.getColumnWidth('Reference', 'Reference ID') },
        { accessor: 'CustomerPO', Header: 'Customer PO', width: this.getColumnWidth('CustomerPO', 'CustomerPO') },
        { accessor: 'CreateDate', Header: 'Create Date', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('CreateDate', 'Create Date') },
        { accessor: 'LineNumber', Header: 'Line Number', width: this.getColumnWidth('LineNumber', 'Line Number') },
        { accessor: 'DueDate', Header: 'Due Date', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('DueDate', 'Due Date') },
        { accessor: 'ExtBacklog', Header: 'Ext Backlog', width: this.getColumnWidth('ExtBacklog', 'Ext Backlog') },
        { accessor: 'ShipToName', Header: 'Ship To Name', width: this.getColumnWidth('ShipToName', 'Ship To Name') },
        { accessor: 'PartType', Header: 'Part Type', width: this.getColumnWidth('PartType', 'Part Type') },
        { accessor: 'Commodity', Header: 'Commodity', width: this.getColumnWidth('Commodity', 'Commodity') },
        { accessor: 'ProductCategory', Header: 'Product Category', width: this.getColumnWidth('ProductCategory', 'Product Category') },
        { accessor: 'SalesUOM', Header: 'Sales UOM', width: this.getColumnWidth('SalesUOM', 'Sales UOM') },
        { accessor: 'CurrentListPrice', Header: 'Current List Price', Cell: props => `$${props.value.toFixed(2)}`, width: this.getColumnWidth('CurrentListPrice', 'Current List Price') },
        { accessor: 'MasterDescription', Header: 'Master Description', width: this.getColumnWidth('MasterDescription', 'Master Description') },
        { accessor: 'PrimaryRep', Header: 'Primary Rep', width: this.getColumnWidth('PrimaryRep', 'Primary Rep') },
        { accessor: 'CustomerRep', Header: 'Customer Rep', width: this.getColumnWidth('CustomerRep', 'Customer Rep') },
        { accessor: 'Delivery', Header: 'Packing Slip Number', width: this.getColumnWidth('Delivery', 'Packing Slip Number') },
        { accessor: 'MLWaybill', Header: 'Waybill', width: this.getColumnWidth('MLWaybill', 'Waybill') },
        { accessor: 'ShipQuantity', Header: 'Ship Quantity', width: this.getColumnWidth('ShipQuantity', 'Ship Quantity') },
        { accessor: 'ShipDate', Header: 'Ship Date', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('ShipDate', 'Ship Date') },
        { accessor: 'GLRegionCountry', Header: 'GL Region Country', width: this.getColumnWidth('GLRegionCountry', 'GL Regio nCountry') },
        { accessor: 'GLRegionLocations', Header: 'GL Region Locations', width: this.getColumnWidth('GLRegionLocations', 'GL Region Locations') },
        { accessor: 'GLRegionProvince', Header: 'GL Region Province', width: this.getColumnWidth('GLRegionProvince', 'GL Region Province') },
        { accessor: 'SafetyStock', Header: 'Safety Stock', width: this.getColumnWidth('SafetyStock', 'Safety Stock') },
        { accessor: 'SalesLeadTime', Header: 'Sales Lead Time', width: this.getColumnWidth('SalesLeadTime', 'Sales Lead Time') },
        { accessor: 'PurchaseLeadTime', Header: 'Purchase Lead Time', width: this.getColumnWidth('PurchaseLeadTime', 'Purchase Lead Time') },
        { accessor: 'Category', Header: 'Category', width: this.getColumnWidth('Category', 'Category') },
        { accessor: 'CriticalQty', Header: 'Critical Quantity', width: this.getColumnWidth('CriticalQty', 'Critical Qty') },
        { accessor: 'SiteCategory', Header: 'Site Category', width: this.getColumnWidth('SiteCategory', 'Site Category') },
        { accessor: 'BarrieCategory', Header: 'Barrie Category', width: this.getColumnWidth('BarrieCategory', 'Barrie Category') },
        { accessor: 'DaysToShip', Header: 'Days To Ship', width: this.getColumnWidth('DaysToShip', 'Days To Ship') },
        { accessor: 'Capital', Header: 'Capital', width: this.getColumnWidth('Capital', 'Capital'), Cell: props => props.value ? 'Yes' : 'No' },
        { accessor: 'ReportRunAt', Header: 'Report Run At', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('ReportRunAt', 'Report Run At') },
        { accessor: 'ReportUploadAt', Header: 'Report Upload At', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('ReportUploadAt', 'Report Upload At') }
      ]
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => {
      if (accessor === 'InvoiceDate' || accessor === 'CreateDate' || accessor === 'ShipDate' || accessor === 'ReportRunAt' || accessor === 'ReportUploadAt') {
        return new Date(row[accessor]).toLocaleDateString().length
      } else if (accessor === 'ExtPrice' || accessor === 'ExtInvoice' || accessor === 'Markup' || accessor === 'GrossMargin') {
        return row[accessor].toFixed(2).length
      } else {
        return (`${row[accessor]}` || '').length
      }
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      <React.Fragment>
        <ReactTable
          filterable
          data={this.props.data}
          onFetchData={(state, instance) => {
            this.setState({ dataToExport: state.sortedData.map((data) => { return (data._original) }) })
          }}
          defaultPageSize={10}
          columns={this.state.columns}
          defaultFilterMethod={this.filterCaseInsensitive}
          className='-striped -highlight' />
        <br />
        <ExcelFile filename={`Invoice_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
          <ExcelSheet data={this.state.dataToExport} name='Invoice Data'>
            { this.state.columns.map((column) => {
              return (<ExcelColumn
                key={column}
                label={column.Header}
                value={
                  (col) => {
                    // console.log(col[column.accessor])
                    return col[column.accessor]
                  }
                }
              />)
            })}
          </ExcelSheet>
        </ExcelFile>
      </React.Fragment>
    )
  }
}

export default ReportTable
