import React, { Component } from 'react'
import axios from 'axios'
import { Spinner, Row, Col, Alert, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import moment from 'moment-timezone'
import ImageUploader from 'react-images-upload'
import { Document, Page, pdfjs } from 'react-pdf'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import shared from '../../shared/shared'
import './ListReport.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class ProductProblemReportComponent extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      modal: false,
      activeIndex: 0,
      numPages: null,
      pageNumber: 1,
      taskResponseModal: false,
      interimActionTaskID: null,
      interimActionTaskComment: null,
      reworkNumber: null,
      dateComplete: null,
      interimActionFiles: [],
      interimActionFileData: [],
      disposition: null,
      numberOfNonConformingParts: '',
      reworkValid: undefined,
      commentsValid: undefined,
      numberOfNonConformingPartsValid: undefined,
      dateCompleteValid: undefined,
      role: Cookies.get('Permissions')
    }

    this.toggle = this.toggle.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.respondToTaskModal = this.respondToTaskModal.bind(this)
    this.respondToTask = this.respondToTask.bind(this)
    this.respondToTaskToggle = this.respondToTaskToggle.bind(this)
  }

  onExiting () {
    this.animating = true
  }

  onExited () {
    this.animating = false
  }

  next () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === this.state.reportData.supportFiles[this.state.supportFileType].length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.state.reportData.supportFiles[this.state.supportFileType].length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex (newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  onDocumentLoadSuccess (document) {
    const { numPages } = document
    this.setState({
      numPages
    })
  }

  toggle (supportFileType) {
    this.setState({
      modal: !this.state.modal,
      supportFileType: supportFileType
    })
  }

  respondToTaskToggle () {
    this.setState(prevState => ({ taskResponseModal: !prevState.taskResponseModal }))
  }

  async componentDidMount () {
    let URL = shared.getEnvironmentURL(`getPPR/${this.props.id}`)
    let { transientID } = queryString.parse(window.location.search)
    this.setState({ loaded: false })
    URL = transientID ? URL + '?transient=true' : URL
    await axios
      .get(URL, { withCredentials: true })
      .then((response) => {
        this.setState({ reportData: response.data, loaded: true })
      })
      .catch((error) => {
        shared.handleError(error)
      })
  }

  renderTableRow (label = null, value = null) {
    let unclearValues = ['unknown', 'n/a', 'null', 'na', 'undefined', 'n\\a', '']
    if (unclearValues.indexOf(String(value).toLowerCase()) !== -1) {
      value = null
    }
    let row = value ? <tr><th scope='row'>{label}</th><td>{value}</td></tr> : null
    return row
  }

  onDrop (picture, pictureDataURLs) {
    this.setState({
      interimActionFiles: picture,
      interimActionFileData: pictureDataURLs
    })
  }
  async respondToTask () {
    if ([this.props.id, this.state.interimActionTaskID, this.state.reworkNumber, this.state.interimActionTaskComment, this.state.dateComplete, this.state.numberOfNonConformingParts].every(field => { return field !== '' })) {
      await axios({
        method: 'post',
        url: shared.getEnvironmentURL('respondToTask'),
        withCredentials: true,
        data: {
          id: this.props.id,
          interimActionTaskID: this.state.interimActionTaskID,
          reworkNumber: this.state.reworkNumber,
          interimActionTaskComment: this.state.interimActionTaskComment,
          dateComplete: this.state.dateComplete,
          ncnfprts: this.state.numberOfNonConformingParts,
          interimActionFileData: this.state.interimActionFileData,
          interimActionFiles: this.state.interimActionFiles.map((file) => { return ({ name: file.name, size: file.size, mimetype: file.type }) })
        }
      }).catch((error) => {
        shared.handleError(error)
      })
    } else {
      this.state.reworkNumber ? this.setState({ reworkValid: undefined }) : this.setState({ reworkValid: true })
      this.state.interimActionTaskComment ? this.setState({ commentsValid: undefined }) : this.setState({ commentsValid: true })
      this.state.numberOfNonConformingParts ? this.setState({ numberOfNonConformingPartsValid: undefined }) : this.setState({ numberOfNonConformingPartsValid: true })
      this.state.dateComplete ? this.setState({ dateCompleteValid: undefined }) : this.setState({ dateCompleteValid: true })
    }
  }

  async taskLockToggle (taskID) {
    await axios({
      method: 'post',
      url: shared.getEnvironmentURL('toggleTaskLock'),
      withCredentials: true,
      data: {
        taskID: taskID
      }
    })
  }

  respondToTaskModal () {
    let format = 'YYYY-MM-DD'
    return (
      this.state.taskResponseModal
        ? <Modal isOpen={this.state.taskResponseModal} toggle={async () => {
          this.respondToTaskToggle()
        }}>
          <Form>
            <center>
              <ModalHeader toggle={async () => {
                if (this.state.interimActionTaskID !== null) {
                  this.taskLockToggle(this.state.interimActionTaskID)
                }
                this.respondToTaskToggle()
              }}>Respond To Task</ModalHeader>
            </center>
            <ModalBody>
              <FormGroup row>
                <Label for='rework-no' sm={6}>Rework No/Return PO No</Label>
                <Col sm={6}>
                  <Input type='text' name='rework-no' id='rework-no' onChange={(reworkNumber) => {
                    this.setState({ reworkNumber: reworkNumber.target.value })
                  }} invalid={this.state.reworkValid} />
                  <FormFeedback valid={!this.state.reworkValid}>Please enter a rework number</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='comments' sm={6}>Comments</Label>
                <Col sm={6}>
                  <Input type='textarea' name='comments' id='comments' onChange={(interimActionTaskComment) => { this.setState({ interimActionTaskComment: interimActionTaskComment.target.value }) }} invalid={this.state.commentsValid} />
                  <FormFeedback valid={!this.state.commentsValid}>Please enter comments</FormFeedback>
                </Col>
              </FormGroup>
              { ['Transfer to Quarantine', 'Transfer from Quarantine', 'Inspect', 'Return', 'Scrap', 'First Article Inspection'].includes(this.state.disposition)
                ? <FormGroup row>
                  <Label for='comments' sm={6}>Number of Non-Conforming Parts</Label>
                  <Col sm={6}>
                    <Input
                      type='text'
                      name='non-conforming-parts'
                      id='non-conforming-parts'
                      value={this.state.numberOfNonConformingParts}
                      onChange={(numberOfNonConformingParts) => {
                        this.setState({ numberOfNonConformingParts: !isNaN(parseInt(numberOfNonConformingParts.target.value)) ? parseInt(numberOfNonConformingParts.target.value) : null })
                      }}
                      invalid={this.state.numberOfNonConformingPartsValid}
                    />
                    <FormFeedback valid={!this.state.numberOfNonConformingPartsValid}>Please enter the number of Non-Conforming Parts</FormFeedback>
                  </Col>
                </FormGroup>
                : null
              }
              <FormGroup row>
                <Label for='date-complete' sm={6}>Date Complete</Label>
                <Col sm={6}>
                  <Input type='date' name='date-complete' id='date-complete'
                    onChange={
                      (dateComplete) => { this.setState({ dateComplete: moment(dateComplete.target.value).format(format) }) }
                    } invalid={this.state.dateCompleteValid} />
                  <FormFeedback valid={!this.state.dateCompleteValid}>Please enter the number of Date of Completion</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <ImageUploader withIcon withPreview buttonText='Choose images' onChange={this.onDrop} maxFileSize={5242880} />
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button onClick={async () => {
                await this.respondToTask()
                if (!this.state.reworkValid && !this.state.commentsValid && !this.state.numberOfNonConformingPartsValid && !this.state.dateCompleteValid) {
                  this.respondToTaskToggle()
                  this.taskLockToggle(this.state.interimActionTaskID)
                }
              }}>Submit</Button>
            </ModalFooter>
          </Form>
        </Modal>
        : null
    )
  }

  renderInterimActionTasks (interimActionTasks) {
    let interimActionTasksRows = []
    let unclearValues = ['unknown', 'n/a', 'null', 'na', 'undefined', 'n\\a', '']

    for (let index in interimActionTasks) {
      interimActionTasksRows.push(
        <tr key={index}>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {/* { this.state.reportData.status !== 'Complete' ? */}
            {this.state.role === 'Distributor'
              ? interimActionTasks[index].dispositionAction === 'Other'
                ? <Button color='primary' outline onClick={async () => {
                  this.setState(prevState =>
                    ({
                      id: this.props.id,
                      interimActionTaskID: interimActionTasks[index].id,
                      taskResponseModal: !prevState.taskResponseModal,
                      disposition: interimActionTasks[index].dispositionAction
                    }),
                  async () => {
                    if (this.state.interimActionTaskID !== null) {
                      this.taskLockToggle(this.state.interimActionTaskID)
                    }
                  }
                  )
                }}>Respond To Task</Button>
                : null
              : <Button color='primary' outline onClick={async () => {
                this.setState(prevState =>
                  ({
                    id: this.props.id,
                    interimActionTaskID: interimActionTasks[index].id,
                    taskResponseModal: !prevState.taskResponseModal,
                    disposition: interimActionTasks[index].dispositionAction
                  }),
                async () => {
                  if (this.state.interimActionTaskID !== null) {
                    this.taskLockToggle(this.state.interimActionTaskID)
                  }
                }
                )
              }
              }>Respond To Task</Button>
            }
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {moment.utc(interimActionTasks[index].initiated).local().format('MMMM Do YYYY')}
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {interimActionTasks[index].location}
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {interimActionTasks[index].dispositionAction}
          </td>
          <td>
            {interimActionTasks[index].actionDetails !== null ? interimActionTasks[index].actionDetails.replace(/~On.* wrote:?/g, '').trim() : null}
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {unclearValues.indexOf(String(interimActionTasks[index].reworkNumber).toLowerCase()) !== -1 ? null : interimActionTasks[index].reworkNumber.join().split(',').join('\n').trim()}
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {interimActionTasks[index].nonConformingParts}
          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {interimActionTasks[index].responsiblePeople.join().split(',').join('\n')}
          </td>
          <td>
            <ul>
              {interimActionTasks[index].comments !== null
                ? interimActionTasks[index].comments.filter(comment => { return comment !== null }).map((comment, index) =>
                  <li key={index}>{comment.replace(/~On.* wrote:?/g, '').trim()}</li>
                )
                : null}
            </ul>

          </td>
          <td style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}>
            {/* {moment.utc(interimActionTasks[index].dateComplete).local().format('YYYY-MM-DD') !== 'Invalid date' ? moment.utc(interimActionTasks[index].dateComplete).local().format('YYYY-MM-DD') : null} */}
            <ul>
              {
                moment.utc(Math.max(...interimActionTasks[index].dateComplete.filter(dateComplete => { return (dateComplete !== null && dateComplete !== '0001-01-01T00:00:00.000Z') }).map((dateComplete, index) => new Date(dateComplete)))).format('YYYY-MM-DD') !== 'Invalid date'
                  ? moment.utc(Math.max(...interimActionTasks[index].dateComplete.filter(dateComplete => { return (dateComplete !== null && dateComplete !== '0001-01-01T00:00:00.000Z') }).map((dateComplete, index) => new Date(dateComplete)))).format('YYYY-MM-DD')
                  : null
              }
            </ul>
          </td>
        </tr>)
    }
    return (interimActionTasksRows)
  }

  renderReport () {
    let reportData = this.state.reportData
    if (queryString.parse(window.location.search).transientID) {
      return this.renderTransientReport()
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col sm={{ size: 1 }}>
              <Button color='link'>
                <Link to='/list-reports'>Back to report list</Link>
              </Button>
            </Col>
            <Col sm={{ size: 12 }}>
              <h2 className='display-4 text-center'>{reportData.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Alert color='info'>
                <h4 className='display-4'>
                  <center>Initiation</center>
                </h4>
              </Alert>
              <Table size='sm' responsive>
                <tbody>
                  {this.renderTableRow('ID', reportData.id)}
                  {this.renderTableRow('Branch', reportData.branch)}
                  {this.renderTableRow('PPR Type', reportData.type)}
                  {this.renderTableRow('Severity', reportData.severity)}
                  {this.renderTableRow('Part Number', reportData.partNumber)}
                  {this.renderTableRow('Quantity', reportData.quantity)}
                  {this.renderTableRow('Created By', reportData.createdBy)}
                  {this.renderTableRow('Created On', moment.utc(reportData.created).format('MMMM Do YYYY, h:mm:ss a'))}
                  {this.renderTableRow('Customer', reportData.customerName)}
                  {this.renderTableRow('Site Name', reportData.customerSiteName)}
                  {this.renderTableRow('Plant', reportData.plant)}
                  {this.renderTableRow('Plant Location', reportData.plantLocation)}
                  {this.renderTableRow('Rig Type', reportData.rigType)}
                  {this.renderTableRow('Rig Number', reportData.rig)}
                  {this.renderTableRow('Mine Unit Number', reportData.mineUnitNumber)}
                  {this.renderTableRow('Engine Hours', reportData.engineHours)}
                  {this.renderTableRow('Power Pack Hours', reportData.powerPackHours)}
                  {this.renderTableRow('Drill Percussion Hours', reportData.drillPercussionHours)}
                  {this.renderTableRow('Compressor Hours', reportData.compressorHours)}
                  {this.renderTableRow('PDI Details', reportData.pdiDetails)}
                  {this.renderTableRow('Root Cause Details', reportData.rootCauseDetails)}
                  {this.renderTableRow('Root Cause Category', reportData.rootCauseCategory)}
                  {this.renderTableRow('Root Cause Source', reportData.rootCauseSource)}
                  {this.renderTableRow('Containment Actions', reportData.containmentActions)}
                  {this.renderTableRow('Warranty Type', reportData.warrantyType)}
                  {this.renderTableRow('Warranty Status', reportData.warrantyStatus)}
                  {this.renderTableRow('Warranty Details', reportData.warrantyDetails !== null ? reportData.warrantyDetails.replace(/~On.* wrote:?/g, '').trim() : null)}
                  {this.renderTableRow('Warranty Cost', reportData.warrantyCost)}
                </tbody>
              </Table>
            </Col>
          </Row>
          {reportData.supportFiles.inspectionContainmentSupportFilesMetadata.length > 0
            ? <Row>
              <Col sm='12'>
                <Button color='info' onClick={() => this.toggle('inspectionContainmentSupportFilesMetadata')}>View Inspection Containment Support Files</Button>
              </Col>
              <Col sm='12'> &nbsp; </Col>
            </Row>
            : null
          }
          {reportData.supportFiles.rootCauseSupportFilesMetadata.length > 0
            ? <Row>
              <Col sm='12'>
                <Button color='info' onClick={() => this.toggle('rootCauseSupportFilesMetadata')}>View Root Cause Support Files</Button>
              </Col>
              <Col sm='12'> &nbsp; </Col>
            </Row>
            : null
          }
          <Row>
            <Col sm='12'>
              <Alert color='info'>
                <h4 className='display-4'>
                  <center>Problem Definition</center>
                </h4>
              </Alert>
              <Table size='sm' responsive>
                <tbody>
                  {this.renderTableRow('Category', reportData.problemDefinitionCategory)}
                  {this.renderTableRow('Problem Details', reportData.problemDefinitionDetails !== null ? reportData.problemDefinitionDetails.replace(/~On.* wrote:?/g, '').trim() : null)}
                  {this.renderTableRow('Responsible Group', reportData.problemDefinitionGroupResponsible)}
                  {this.renderTableRow('Repair/Rework Details', reportData.repairReworkDetails)}
                </tbody>
              </Table>
            </Col>
          </Row>
          {reportData.supportFiles.problemDefinitionSupportFilesMetadata.length > 0
            ? <Row>
              <Col sm='12'>
                <Button color='info' onClick={() => this.toggle('problemDefinitionSupportFilesMetadata')}>View Problem Definition Support Files</Button>
              </Col>
              <Col sm='12'> &nbsp; </Col>
            </Row>
            : null
          }

          {reportData.interimActionTasks.length > 0
            ? <Row>
              <Col sm='12'>
                <Alert color='info'>
                  <h4 className='display-4'>
                    <center>Interim Action Tasks</center>
                  </h4>
                </Alert>
                <Table size='sm' className='action-task-table' bordered responsive>
                  <thead>
                    <tr>
                      <th>Actions</th>
                      <th>Initiated</th>
                      <th>Location</th>
                      <th>Disposition</th>
                      <th>Action Details</th>
                      <th>Rework Number</th>
                      <th>Non-Conforming Parts</th>
                      <th>Assigned To</th>
                      <th>Comments</th>
                      <th>Date Complete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderInterimActionTasks(reportData.interimActionTasks)}
                  </tbody>
                </Table>
                {reportData.supportFiles.interimActionSupportFilesMetadata.length > 0
                  ? <Row>
                    <Col sm='12'>
                      <Button color='info' onClick={() => this.toggle('interimActionSupportFilesMetadata')}>View Interim Action Support Files</Button>
                    </Col>
                    <Col sm='12'> &nbsp; </Col>
                  </Row>
                  : null
                }
              </Col>
            </Row>
            : null
          }
          {this.renderModal()}
          {this.respondToTaskModal()}
        </React.Fragment>
      )
    }
  }
  renderTransientReport () {
    let reportData = this.state.reportData
    return (
      <React.Fragment>
        <Row>
          <Col sm={{ size: 1 }}>
            <Button link color='link'>
              <Link to='/list-reports'>Back to report list</Link>
            </Button>
          </Col>
          <Col sm={{ size: 12 }}>
            <h2 className='display-4 text-center'>{reportData.title}</h2>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Alert color='info'>
              <h4 className='display-4'>
                <center>Initiation</center>
              </h4>
            </Alert>
            <Table size='sm' responsive>
              <tbody>
                {this.renderTableRow('ID', reportData.id)}
                {this.renderTableRow('Branch', reportData.branch)}
                {this.renderTableRow('PPR Type', reportData.type.value)}
                {this.renderTableRow('Severity', reportData.severity.value)}
                {this.renderTableRow('Part Number', reportData.partNumber)}
                {this.renderTableRow('Quantity', reportData.quantity)}
                {this.renderTableRow('Created By', reportData.createdBy.createdBy)}
                {this.renderTableRow('Customer', reportData.customerName)}
                {this.renderTableRow('Site Name', reportData.customerSiteName)}
                {this.renderTableRow('Location Type', reportData.locationType)}
                {this.renderTableRow('Plant', reportData.plant.value)}
                {this.renderTableRow('Plant Location', reportData.plantLocation.value)}
                {this.renderTableRow('Rig', reportData.rig)}
                {this.renderTableRow('Rig Type', reportData.rigType.value)}
                {this.renderTableRow('Repair/Rework Details', reportData.correctiveActions)}
                {this.renderTableRow('Engine Hours', reportData.engineHours)}
                {this.renderTableRow('Power Pack Hours', reportData.powerPackHours)}
                {this.renderTableRow('Drill Percussion Hours', reportData.drillPercussionHours)}
                {this.renderTableRow('Containment Actions', reportData.containmentActions)}
                {this.renderTableRow('Warranty Selected', reportData.warrantySelected ? 'Yes' : 'No')}
                {this.renderTableRow('Error In Process', reportData.errorInProcess)}
                {this.renderTableRow('State', reportData.state)}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Alert color='info'><h4 className='display-4'><center>Problem Definition</center></h4></Alert>
            <Table size='sm' responsive>
              <tbody>
                {this.renderTableRow('Category', reportData.problemDefinitionCategory.value)}
                {this.renderTableRow('Problem Details', reportData.problemDefinitionDetails)}
                {this.renderTableRow('Responsible Group', reportData.problemDefinitionGroupResponsible.value)}
                {this.renderTableRow('Repair/Rework Details', reportData.repairReworkDetails)}
              </tbody>
            </Table>
          </Col>
        </Row>
        {reportData.supportFiles.problemDefinitionSupportFilesMetadata.length > 0
          ? <Row>
            <Col sm='12'>
              <Button color='info' onClick={() => this.toggle('problemDefinitionSupportFilesMetadata')}>View Problem Definition Support Files</Button>
            </Col>
            <Col sm='12'> &nbsp; </Col>
          </Row>
          : null
        }
        {this.renderModal()}
      </React.Fragment>
    )
  }
  renderModal () {
    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle(this.state.supportFileType)} className='support-file-modal'>
        <center>
          <ModalHeader toggle={() => this.toggle(this.state.supportFileType)}>{this.state.reportData.title}</ModalHeader>
        </center>
        <ModalBody>
          {this.carousel(this.state.supportFileType)}
        </ModalBody>
        <ModalFooter>
          <center>
            <Button color='primary' onClick={() => this.toggle(this.state.supportFileType)}>Dismiss</Button>
          </center>
        </ModalFooter>
      </Modal>
    )
  }
  carousel (supportFileType = 'problemDefinitionSupportFilesMetadata') {
    const { activeIndex, numPages } = this.state
    let imageMimeTypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/tiff']
    let videoMimeTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo']
    const slides = this.state.reportData.supportFiles[supportFileType].map((item, index) => {
      return (
        <CarouselItem
          key={index}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          { imageMimeTypes.indexOf(item.mimeType) >= 0
            ? <img className='carousel-media' src={shared.getEnvironmentURL('streamMedia') + `/${this.props.id}?${queryString.parse(window.location.search).transientID ? `transient=${true}&id=${encodeURI(item.id)}` : `fileName=${encodeURI(item.fileName)}&mimeType=${encodeURI(item.mimeType)}&type=${encodeURI(item.type)}`}`} alt={item.description} />
            : null
          }
          { videoMimeTypes.indexOf(item.mimeType) >= 0
            ? <video className='carousel-media' controls autoPlay name='media'><source src={`${shared.getEnvironmentURL('streamMedia')}/${this.props.id}?fileName=${encodeURI(item.fileName)}&mimeType=${encodeURI(item.mimeType)}&type=${encodeURI(item.type)}`} type={item.mimeType} /></video>
            : null
          }
          { ['application/pdf'].indexOf(item.mimeType) >= 0
            ? <React.Fragment>
              <Document renderMode='svg' file={`${shared.getEnvironmentURL(`streamMedia/${this.props.id}`)}?fileName=${encodeURI(item.fileName)}&mimeType=${encodeURI(item.mimeType)}&type=${encodeURI(item.type)}`} onLoadSuccess={this.onDocumentLoadSuccess}>
                {Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={this.state.numPages}
                      onLoadSuccess={(page) => console.log(page)}
                      onLoadError={(error) => console.log(error)}
                      error={<Alert color='danger'>Error Loading PDF File.</Alert>}
                    />
                  )
                )}
              </Document>
            </React.Fragment>
            : null
          }
          {
            (imageMimeTypes.indexOf(item.mimeType) < 0 && videoMimeTypes.indexOf(item.mimeType) < 0 && ['application/pdf'].indexOf(item.mimeType) < 0)
              ? <Row>
                <Col sm={{ size: 2, offset: 5 }}>
                  <Button className='' size='lg' color='outline'>
                    <a href={`${shared.getEnvironmentURL(`streamMedia/${this.props.id}`)}?fileName=${encodeURI(item.fileName)}&mimeType=${encodeURI(item.mimeType)}&type=${encodeURI(item.type)}`} target='_blank' rel='noopener noreferrer'>
                    Download File
                    </a>
                  </Button>
                </Col>
              </Row>

              : null
          }
          <CarouselCaption captionText={item.title} captionHeader={item.description} />
        </CarouselItem>
      )
    })

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        interval={false}
      >
        <CarouselIndicators items={this.state.supportFileType !== undefined ? this.state.reportData.supportFiles[this.state.supportFileType] : []} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction='prev' directionText='Previous' onClickHandler={this.previous} />
        <CarouselControl direction='next' directionText='Next' onClickHandler={this.next} />
      </Carousel>
    )
  }
  render () {
    return (
      this.state.loaded
        ? this.renderReport()
        : <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '10% auto', display: 'block' }} />
    )
  }
}
export default ProductProblemReportComponent
