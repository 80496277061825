import React, { Component } from 'react'
import { Alert, Button } from 'reactstrap'
import Cookies from 'js-cookie'
class WorkInProgress extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isInternal: Cookies.get('Permissions') !== 'Distributor'
    }
  }

  render () {
    return (
      <React.Fragment>
        <Alert color='info'>
          <h3 style={{ fontWeight: 300, padding: '1% 0' }}>
            <center>
              To be updated
              &nbsp;
              <Button onClick={() => { window.location.replace(this.state.isInternal ? './' : '/customer-dashboard') }}> Home </Button>
            </center>
          </h3>
        </Alert>
      </React.Fragment>
    )
  }
}
export default WorkInProgress
