import React, { Component } from 'react'
import { Button, Progress } from 'reactstrap'
import ReactTable from 'react-table'
import ReactExport from 'react-export-excel'
import moment from 'moment'
import 'react-table/react-table.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: [
        { accessor: 'ms', Header: 'Status', width: this.getColumnWidth('ms', 'Status') },
        { accessor: 'plant', Header: 'Plant', width: this.getColumnWidth('plant', 'Plant') },
        { accessor: 'type', Header: 'Machine Type', width: this.getColumnWidth('type', 'Machine Type') },
        { accessor: 'fabrication',
          Header: 'Fabrication',
          width: this.getColumnWidth('fabrication', 'Fabrication'),
          Cell: props => {
            let value = props.value * 100
            let color = value >= 75
              ? 'success'
              : value >= 50
                ? 'warning'
                : 'danger'
            return <React.Fragment>
              <div className='text-center'>{value.toFixed(2) + ' %'}</div>
              <Progress color={color} value={value} />
            </React.Fragment>
          }
        },
        { accessor: 'completion',
          Header: 'Assembly',
          width: this.getColumnWidth('partNumber', 'Assembly'),
          Cell: props => {
            let value = props.value * 100
            let color = value >= 75
              ? 'success'
              : value >= 50
                ? 'warning'
                : 'danger'
            return <React.Fragment>
              <div className='text-center'>{value.toFixed(2) + ' %'}</div>
              <Progress color={color} value={value} />
            </React.Fragment>
          }
        },
        { accessor: 'customer', Header: 'Customer', width: this.getColumnWidth('customer', 'Customer') },
        { accessor: 'site', Header: 'Site', width: this.getColumnWidth('site', 'Site') },
        { accessor: 'model', Header: 'Model', width: this.getColumnWidth('model', 'Model') },
        { accessor: 'number', Header: 'Number', width: this.getColumnWidth('number', 'Number') },
        { accessor: 'partNumber', Header: 'Part Number', width: this.getColumnWidth('partNumber', 'Part Number') },
        { accessor: 'description', Header: 'Description', width: this.getColumnWidth('description', 'Description') },
        { accessor: 'accRep', Header: 'Account Rep', width: this.getColumnWidth('accRep', 'Account Rep') },
        { accessor: 'region', Header: 'Region', width: this.getColumnWidth('region', 'Region') },
        { accessor: 'salesComments', Header: 'Sales Comments', width: this.getColumnWidth('salesComments', 'Sales Comments') },
        { accessor: 'salesOrder', Header: 'Sales Order', width: this.getColumnWidth('salesOrder', 'Sales Order') },
        { accessor: 'priority', Header: 'Priority', width: this.getColumnWidth('priority', 'Priority') },
        { accessor: 'bayStart',
          Header: 'Bay Start',
          width: this.getColumnWidth('bayStart', 'Bay Start'),
          Cell: props => {
            return props.value !== null ? moment(props.value).format('YYYY-MM-DD') : null
          } },
        { accessor: 'bufferedFactoryComplete',
          Header: 'Factory Complete',
          width: this.getColumnWidth('bufferedFactoryComplete', 'Factory Complete'),
          getProps: (state, rowInfo, column) => {
            let style = {}
            if (rowInfo !== undefined) {
              console.log(rowInfo.original.bomPct, moment(rowInfo.original.bufferedFactoryComplete).diff(moment(rowInfo.original.bayStart)))
              if (rowInfo.original.bomPct === 1) { style = { style: { backgroundColor: '#00800099' } } }
              if (rowInfo.original.bomPct < 1 && moment(rowInfo.original.bufferedFactoryComplete).diff(moment(rowInfo.original.bayStart), 'month') < 1) {
                style = { style: { backgroundColor: '#FFC10799' } }
              }
              if (rowInfo.original.bomPct < 1 && moment(rowInfo.original.bufferedFactoryComplete).diff(moment(rowInfo.original.bayStart), 'week') < 1) {
                style = { style: { backgroundColor: '#E8610099' } }
              }
              if (rowInfo.original.bomPct < 1 && moment(rowInfo.original.bufferedFactoryComplete).diff(moment(rowInfo.original.bayStart)) < 1) {
                style = { style: { backgroundColor: '#00800099' } }
              }
            }
            return style
          },
          Cell: props => {
            return props.value !== null ? moment(props.value).format('YYYY-MM-DD') : null
          } },
        { accessor: 'commitFactoryComplete',
          Header: 'Customer Date',
          width: this.getColumnWidth('commitFactoryComplete', 'Customer Date'),
          Cell: props => {
            return props.value !== null ? moment(props.value).format('YYYY-MM-DD') : null
          } },
        { accessor: 'reference', Header: 'Reference', width: this.getColumnWidth('reference', 'Reference') },
        { accessor: 'manDays', Header: 'Man Days', width: this.getColumnWidth('manDays', 'Man Days') },
        { accessor: 'extraDays', Header: 'Extra Days', width: this.getColumnWidth('extraDays', 'Extra Days') },
        { accessor: 'daysBooked', Header: 'Booked Days', width: this.getColumnWidth('daysBooked', 'Booked Days') },
        { accessor: 'customerID', Header: 'Customer ID', width: this.getColumnWidth('customerID', 'Customer ID') },
        { accessor: 'slot', Header: 'Slot', width: this.getColumnWidth('slot', 'Slot') },
        { accessor: 'pct', Header: 'Pct', width: this.getColumnWidth('pct', 'Pct  '), Cell: props => { return props.value * 100 + '%' } },
        { accessor: 'bomPct',
          Header: 'BOM Completion',
          width: this.getColumnWidth('bomPct', 'BOM Completion'),
          Cell: props => {
            let value = props.value * 100
            let color = value >= 75
              ? 'success'
              : value >= 50
                ? 'warning'
                : 'danger'
            return <React.Fragment>
              <div className='text-center'>{value.toFixed(2) + ' %'}</div>
              <Progress color={color} value={value} />
            </React.Fragment>
          }
        },
        { accessor: 'bomDate',
          Header: 'Full BOM Date',
          width: this.getColumnWidth('bomDate', 'Full BOM Date'),
          Cell: props => {
            return props.value !== null ? moment(props.value).format('YYYY-MM-DD') : null
          },
          getProps: (state, rowInfo, column) => {
            let style = {}
            if (rowInfo !== undefined && rowInfo.original.bomDate) {
              if (rowInfo.original.bomPct < 1 && moment(rowInfo.original.bomDate).subtract(1, 'month').valueOf() < moment().valueOf()) {
                style = { style: { backgroundColor: '#FFC10799' } }
              }
              if (rowInfo.original.bomPct < 1 && (moment().valueOf() < moment(rowInfo.original.bayStart).valueOf() && moment().valueOf() > moment(rowInfo.original.bomDate).valueOf())) {
                style = { style: { backgroundColor: '#E8610099' } }
              }
              if (rowInfo.original.bomPct < 1 && (moment().valueOf() > moment(rowInfo.original.bayStart).valueOf())) {
                style = { style: { backgroundColor: '#FF000099' } }
              }
              if (rowInfo.original.bomPct === 1) {
                style = { style: { backgroundColor: '#00800099' } }
              }
            }
            return style
          }
        }
      ]
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => {
      if (accessor === 'bayStart' || accessor === 'bufferedFactoryComplete' || accessor === 'commitFactoryComplete' || accessor === 'bomDate') {
        return new Date(row[accessor]).toLocaleDateString().length
      }
      return (`${row[accessor]}` || '').length
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }

  render () {
    return (
      <React.Fragment>
        <ReactTable
          data={this.props.data}
          defaultPageSize={10}
          filterable
          onFetchData={(state, instance) => {
            this.setState({ dataToExport: state.sortedData.map((data) => { return (data._original) }) })
          }}
          defaultFilterMethod={this.filterCaseInsensitive}
          columns={this.state.columns}
          className='-striped -highlight' />
        <br />
        <ExcelFile filename={`Invoice_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
          <ExcelSheet data={this.state.dataToExport} name='Invoice Data'>
            { this.state.columns.map((column) => {
              return (<ExcelColumn
                key={column}
                label={column.Header}
                value={
                  (col) => {
                    // console.log(col[column.accessor])
                    return col[column.accessor]
                  }
                }
              />)
            })}
          </ExcelSheet>
        </ExcelFile>
      </React.Fragment>
    )
  }
}

export default ReportTable
