import React, { Component } from 'react'
import Moment from 'moment'
import Controls from '../Controls/Controls'
import FaultTableHistory from '../FaultTable/FaultTableHistory'
import { Spinner, Alert } from 'reactstrap'
import shared from '../../../../shared/shared'
import axios from 'axios'

class FaultHistory extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: Moment.utc(this.props.details.last_row.datetime).subtract(30, 'days'),
      end: Moment.utc(this.props.details.last_row.datetime),
      span: 30,
      setEnd: true,
      spns: undefined,
      machineDateRangeStart: undefined,
      machineDateRangeEnd: undefined,
      noData: undefined
    }
    this.columnArr = undefined
    this.allowGetColumns = true
    this.allowGetSpns = true
    this.spnArrays = {}
  }

  getMachineDateRange () {
    let body = {
      machine: this.props.machine,
      fields: ['SPNs_active']
    }

    axios.post(
      shared.getVmsHost('machineDaterange'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(data);
      if (response.data.starts !== undefined) {
        this.setState({
          machineDateRangeStart: response.data.starts,
          machineDateRangeEnd: response.data.ends,
          noData: false
        })
      } else {
        this.setState({
          noData: true
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

    setStart = (e) => {
      let start = Moment(e)

      this.allowGetColumns = true
      this.allowGetSpns = true
      this.setState({
        start: start,
        setEnd: false,
        span: this.state.end.diff(start, 'days'),
        spns: undefined
      })
    }

    setEnd (e) {
      let end = Moment(e)

      this.allowGetColumns = true
      this.allowGetSpns = true
      this.setState({
        end: end,
        setEnd: true,
        span: end.diff(this.state.start, 'days'),
        spns: undefined
      })
    }

    setSpan = (e) => {
      if (this.state.setEnd) {
        let start = this.state.end.clone()
        start.subtract(e, 'days')
        this.allowGetColumns = true
        this.allowGetSpns = true
        this.setState({
          span: e,
          start: start,
          spns: undefined
        })
      } else {
        let end = this.state.start.clone()
        end.add(e, 'days')
        this.allowGetColumns = true
        this.allowGetSpns = true

        this.setState({
          span: e,
          end: end,
          spns: undefined
        })
      }
    }

    getSpns () {
      let body = {
        machine: this.props.machine,
        start: this.state.start,
        end: this.state.end
      }

      axios.post(
        shared.getVmsHost('getSPNsTimeframe'),
        body, { withCredentials: true }
      ).then((response) => {
        if (response.status > 298) {
          this.setState({
            noData: true
          })
        } else {
          this.sortSpns(response.data)
        }
      }).catch((error) => { shared.handleError(error) })
    }

    sortSpns (data) {
      let spns = {}

      for (let i in data) {
        if (Object.keys(data[i]).indexOf('SPNs_active') > -1) {
          if (data[i]['SPNs_active'].length > 0) {
            for (let j in data[i]['SPNs_active']) {
              spns[data[i]['SPNs_active'][j]] = { date: data[i].max }
            }
          }
        }
      }
      if (Object.keys(spns).length < 1) {
        spns = {}
      }
      this.setState({
        spns: spns
      })
    }

    render () {
      // console.log(this.props)
      let faultTable = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

      if (this.allowGetSpns) {
        this.allowGetSpns = false
        this.getSpns()
      }

      if (this.state.noData === undefined) {
        this.getMachineDateRange()
      }

      if (this.state.spns !== undefined) {
        faultTable = <FaultTableHistory ajaxError={this.props.ajaxError} spns={this.state.spns} table={this.props.spnTable} />
      }

      if (this.state.noData) {
        faultTable = <Alert color='primary'><center><h3>Fault history not available for {this.props.machine}.</h3></center></Alert>
      }

      return (
        <React.Fragment>
          <Controls start={this.state.start} end={this.state.end} setStart={this.setStart.bind(this)} setEnd={this.setEnd.bind(this)} setSpan={this.setSpan.bind(this)}
            span={this.state.span} rangeStart={this.state.machineDateRangeStart} rangeEnd={this.state.machineDateRangeEnd} />
          {faultTable}
        </React.Fragment>
      )
    }
}

export default FaultHistory
