import React, { Component } from 'react'
import TopBar from '../TopBar/TopBar'
import Notices from './Notices/Notices'
import MachineListPane from './MachineListPane/MachineListPane'
import './FleetNoticesPane.css'
import { Col } from 'reactstrap'

class fleetNoticesPane extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showList: undefined,
      machinesToDisp: undefined,
      notifications: undefined,
      openModal: false
    }
  }

  popOpenList (e, machinesToDisp, notifications) {
    this.setState(prevState => ({
      showList: e,
      machinesToDisp: machinesToDisp,
      notifications: notifications,
      openModal: !prevState.openModal
    }))
  }

  render () {
    let machineListPane = null
    if (this.state.showList !== undefined) {
      machineListPane = <MachineListPane whatList={this.state.showList} closer={this.popOpenList.bind(this)} machines={this.state.machinesToDisp}
        notifications={this.state.notifications} machineIdentities={this.props.machineIdentities} machineDetailPane={this.props.machineDetailPane}
        popOpenList={this.popOpenList.bind(this)} isOpen={this.state.openModal} data={this.props.data} ajaxError={this.props.ajaxError} />
    }

    return (
      <Col xs='6' sm='6'>
        <TopBar headerText='Fleet Events' />
        <Notices machineIdentities={this.props.machineIdentities} openList={this.popOpenList.bind(this)} rerender={this.props.rerender}
          data={this.props.data} ajaxError={this.props.ajaxError} detailsOpen={this.props.detailsOpen} showList={this.state.showList} />
        {machineListPane}
      </Col>
    )
  }
}

export default fleetNoticesPane
