import React, { Component } from 'react'
import DateTimePicker from 'react-datepicker'
import ControlledInputSpan from './ControlledInputSpan'
import ControlledInputStep from './ControlledInputStep'
import Moment from 'moment'
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import './Controls.css'
import '../../../../../../node_modules/react-datepicker/dist/react-datepicker.css'

class controls extends Component {
  render () {
    // console.log(this.props);

    return (
      <Form className='data-explorer-controls'>
        <Row form>
          <Col md={{ size: 1 }} className='my-4'>
            <IoIosArrowBack size='75%' onClick={this.props.forward} />
          </Col>
          <Col md={{ size: 2 }} className='mx-3'>
            <FormGroup>
              <Label for='beginning-date-time'>Beginning</Label>
              <DateTimePicker
                className='form-control'
                id='beginning-date-time'
                selected={this.props.start.toDate()}
                onChange={this.props.setStart}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy h:mm aa'
                minDate={Moment(this.props.rangeStart).toDate()}
                maxDate={Moment(this.props.rangeEnd).toDate()} />
            </FormGroup>
          </Col>
          <Col md={{ size: 1 }} className='mx-3'>
            <FormGroup className='units-container'>
              <Label for='span-days' className='units-label'>Span ({this.props.units})</Label>
              <ControlledInputSpan id='span-days' units={this.props.units} onChange={this.props.setSpan} value={this.props.span} />
            </FormGroup>
          </Col>
          <Col md={{ size: 2 }} className='mx-3'>
            <FormGroup>
              <Label for='ending-date-time'>End</Label>
              <DateTimePicker
                id='ending-date-time'
                className='form-control'
                selected={this.props.end.toDate()}
                onChange={this.props.setEnd}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='MMMM d, yyyy h:mm aa'
                minDate={Moment(this.props.rangeStart).toDate()}
                maxDate={Moment(this.props.rangeEnd).toDate()} />
            </FormGroup>
          </Col>
          <Col md={{ size: 1 }} className='mx-3'>
            <FormGroup row>
              <legend className='col-form-label'>Units</legend>
              <Col sm={12}>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' value='hours' name='units' onChange={this.props.setUnit} checked={this.props.units === 'hours'} />{' '}
                    Hours
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' value='minutes' name='units' onChange={this.props.setUnit} checked={this.props.units === 'minutes'} />{' '}
                    Minutes
                  </Label>
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
          <Col md={{ size: 1 }} className='mx-3'>
            <FormGroup>
              <Label for='utilization-detail-view-step'>Step</Label>
              <ControlledInputStep onChange={this.props.setStep} value={this.props.step} />
            </FormGroup>
          </Col>
          <Col md={{ size: 1 }} className='my-4'>
            <IoIosArrowForward size='75%' onClick={this.props.back} />
          </Col>
        </Row>
      </Form>
    )
  }
}

export default controls
