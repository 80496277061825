import React, { Component } from 'react'
import '../NotificationsTable.css'
import { Button, Popover, PopoverHeader, PopoverBody, FormGroup, Input } from 'reactstrap'

class NotificationDismisser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      popoverIsOpen: false,
      allowSubmit: false,
      comment: undefined
    }
  }

  toggle () {
    this.setState({
      popoverIsOpen: !this.state.popoverIsOpen
    })
  }
  // this.props.handleDismiss(this.props.id)

  validator (e) {
    if ((e.replace(/\s/g, '').length >= 1) && (e.replace(/\s/g, '').length <= 240)) {
      this.setState({
        allowSubmit: true,
        comment: e
      })
    } else {
      this.setState({
        allowSubmit: false,
        comment: e
      })
    }
  }

  submit (id) {
    if (this.state.allowSubmit) {
      this.setState({
        popoverIsOpen: false
      }, () => this.props.handleDismiss(id, this.state.comment))
    } else {

    }
  }

  cancel () {
    this.setState({
      popoverIsOpen: false,
      comment: undefined,
      allowSubmit: false
    })
  }

  render () {
    console.log()
    return (
      <div>
        <Button color='danger' className='imgShake' type='button' id={'Button' + this.props.index.toString()}>Dismiss</Button>
        <Popover style={{ width: '350px' }} isOpen={this.state.popoverIsOpen} target={'Button' + this.props.index.toString()} toggle={this.toggle.bind(this)} trigger='legacy'>
          <PopoverHeader>
                        Please enter your comments to dismiss:
          </PopoverHeader>
          <PopoverBody>
            <FormGroup>
              <Input
                valid={this.state.allowSubmit}
                invalid={!this.state.allowSubmit}
                onChange={(e) => this.validator(e.target.value)}
                type='textarea'
                name='text'
                id='exampleText'
                value={this.state.comment}
                style={{ height: '150px' }} />
            </FormGroup>
            <Button color='primary' onClick={() => this.cancel()} id={'Button' + this.props.index.toString()}>Cancel</Button>
            <Button color='primary' disabled={!this.state.allowSubmit} className='float-right' onClick={() => this.submit(this.props.id)} id={'Button' + this.props.index.toString()}>Submit</Button>
          </PopoverBody>
        </Popover>
      </div>

    )
  }
}

export default NotificationDismisser
