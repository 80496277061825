import React, { Component } from 'react'
import { Alert } from 'reactstrap'
class NotPermitted extends Component {
  render () {
    return (
      <React.Fragment>
        <Alert color='info'>
          <h3 style={{ fontWeight: 300, padding: '1% 0' }}>
            <center>
              You currently are not permitted access to this portal. Please reach out to your Sales Rep. or Supervisor for access.
            </center>
          </h3>
        </Alert>
      </React.Fragment>
    )
  }
}
export default NotPermitted
