import React, { Component } from 'react'
import { Button } from 'reactstrap'
import ReactTable from 'react-table'
import ReactExport from 'react-export-excel'
import 'react-table/react-table.css'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class InternalReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }
  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.props.data.map(row => {
      if (accessor === 'Delivery' || accessor === 'Create Date') {
        return new Date(row[accessor]).toLocaleDateString().length
      } else {
        return (`${row[accessor]}` || '').length
      }
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  componentDidMount () {
    this.setState({
      columns: [
        { accessor: 'Site', Header: 'Site', width: this.getColumnWidth('Site', 'Site') },
        { accessor: 'Warehouse', Header: 'Warehouse', width: this.getColumnWidth('Warehouse', 'Warehouse') },
        { accessor: 'SO', Header: 'Sales Order', width: this.getColumnWidth('So', 'Sales Order') },
        { accessor: 'Line', Header: 'Line', width: this.getColumnWidth('Line', 'Line') },
        { accessor: 'Customer Reference', Header: 'Customer Reference', width: this.getColumnWidth('Customer Reference', 'Customer Reference') },
        { accessor: 'Can Ship Full', Header: 'Can Ship Full', width: this.getColumnWidth('Can Ship Full', 'Can Ship Full') },
        { accessor: 'Customer ID', Header: 'Customer ID', width: this.getColumnWidth('Customer ID', 'Customer ID') },
        { accessor: 'Customer Name', Header: 'Customer Name', width: this.getColumnWidth('Customer Name', 'Customer Name') },
        { accessor: 'Delivery', Header: 'Delivery', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('Delivery', 'Delivery') },
        { accessor: 'Part Number', Header: 'Part Number', width: this.getColumnWidth('Part Number', 'Part Number') },
        { accessor: 'Description', Header: 'Description', width: this.getColumnWidth('Description', 'Description') },
        { accessor: 'Open Line', Header: 'Open Line', width: this.getColumnWidth('Open Line', 'Open Line') },
        { accessor: 'Original Qty', Header: 'Original Qty', width: this.getColumnWidth('Original Qty', 'Original Qty') },
        { accessor: 'Ship From', Header: 'Ship From', width: this.getColumnWidth('Ship From', 'Ship From') },
        { accessor: 'Remaining Qty', Header: 'Remaining Qty', width: this.getColumnWidth('Remaining Qty', 'Remaining Qty') },
        { accessor: 'QTY Reserved This Order', Header: 'Qty Reserved This Order', width: this.getColumnWidth('QTY Reserved This Order', 'QTY Reserved This Order') },
        { accessor: 'Physical Available', Header: 'Physical Available', width: this.getColumnWidth('Physical Available', 'Physical Available') },
        { accessor: 'Line Amount', Header: 'Line Amount', width: this.getColumnWidth('Line Amount', 'Line Amount') },
        { accessor: 'Is Capital', Header: 'Is Capital', width: this.getColumnWidth('Is Capital', 'Is Capital') },
        { accessor: 'Ship Complete', Header: 'Ship Complete', width: this.getColumnWidth('Ship Complete', 'Ship Complete') },
        { accessor: 'Sales Pool', Header: 'Sales Pool', width: this.getColumnWidth('Sales Pool', 'Sales Pool') },
        { accessor: 'Coverage Group', Header: 'Coverage Group', width: this.getColumnWidth('Coverage Group', 'Coverage Group') },
        { accessor: 'Create Date', Header: 'Create Date', Cell: props => new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('Create Date', 'Create Date') },
        { accessor: 'OH Sudbury', Header: 'OH Sudbury', width: this.getColumnWidth('OH Sudbury', 'OH Sudbury') },
        { accessor: 'OH Barrie', Header: 'OH Barrie', width: this.getColumnWidth('OH Barrie', 'OH Barrie') },
        { accessor: 'Can Satisfy from Sudbury', Header: 'Can Satisfy from Sudbury', width: this.getColumnWidth('Can Satisfy from Sudbury', 'Can Satisfy from Sudbury') },
        { accessor: 'Can Satisfy from Barrie', Header: 'Can Satisfy from Barrie', width: this.getColumnWidth('Can Satisfy from Barrie', 'Can Satisfy from Barrie') },
        { accessor: 'Incoming Transfers', Header: 'Incoming Transfers', width: this.getColumnWidth('Incoming Transfers', 'Incoming Transfers') },
        { accessor: 'Satisfied by Live Transfers', Header: 'Satisfied by Live Transfers', width: this.getColumnWidth('Satisfied by Live Transfers', 'Satisfied by Live Transfers') },
        { accessor: 'Incoming Planned Transfers', Header: 'Incoming Planned Transfers', width: this.getColumnWidth('Incoming Planned Transfers', 'Incoming Planned Transfers') },
        { accessor: 'Satisfied by Planned Transfers', Header: 'Satisfied by Planned Transfers', width: this.getColumnWidth('Satisfied by Planned Transfers', 'Satisfied by Planned Transfers') },
        { accessor: 'Ship Complete Check', Header: 'Ship Complete Check', width: this.getColumnWidth('Ship Complete Check', 'Ship Complete Check') },
        { accessor: 'Sales Line Note', Header: 'Sales Line Note', width: this.getColumnWidth('Sales Line Note', 'Sales Line Note') },
        { accessor: 'State', Header: 'State', width: this.getColumnWidth('State', 'State') },
        { accessor: 'Product Line', Header: 'Product Line', width: this.getColumnWidth('Product Line', 'Product Line') },
        { accessor: 'Category', Header: 'Category', width: this.getColumnWidth('Category', 'Category') },
        { accessor: 'Last Note Date', Header: 'Last Note Date', width: this.getColumnWidth('Last Note Date', 'Last Note Date'), Cell: props => new Date(props.value).toLocaleDateString() },
        { accessor: 'Last Note', Header: 'Last Note', width: this.getColumnWidth('Last Note', 'Last Note') },
        { accessor: 'TRANSACTION STATUS', Header: 'Transaction Status', width: this.getColumnWidth('TRANSACTION STATUS', 'Transaction Status') },
        { accessor: 'INVENTTRANSID', Header: 'Inventory Transaction ID', width: this.getColumnWidth('INVENTTRANSID', 'Inventory Transaction ID') },
        { accessor: 'PRODPOOLID', Header: 'Product Pool ID', width: this.getColumnWidth('PRODPOOLID', 'PRODPOOLID') },
        { accessor: 'MLNAME2', Header: 'ML Name 2', width: this.getColumnWidth('MLNAME2', 'ML Name 2') },
        { accessor: 'VANDELAY', Header: 'Vandelay', width: this.getColumnWidth('VANDELAY', 'Vandelay') }
      ]
    })
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    return (
      <React.Fragment>
        <ReactTable
          filterable
          defaultPageSize={10}
          data={this.props.data}
          onFetchData={(state, instance) => {
            this.setState({ dataToExport: state.sortedData.map((data) => { return (data._original) }) })
          }}
          defaultFilterMethod={this.filterCaseInsensitive}
          columns={this.state.columns}
          className='-striped -highlight' />
        <br />
        <ExcelFile filename={`Backlog_Data_${new Date().toString().split(' ').join('_')}`} element={<Button outline>Download As Excel</Button>}>
          <ExcelSheet data={this.state.dataToExport} name='Backlog Data'>
            { this.state.columns.map((column) => {
              return (<ExcelColumn
                key={column}
                label={column.Header}
                value={
                  (col) => {
                    // console.log(col[column.accessor])
                    return col[column.accessor]
                  }
                }
              />)
            })}
          </ExcelSheet>
        </ExcelFile>
      </React.Fragment>
    )
  }
}

export default InternalReportTable
