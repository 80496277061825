import React from 'react'
import { RadialProgress } from 'react-radial-progress-indicator'
import './RadialIndicator.css'
import { Row, Col } from 'reactstrap'

const RadialIndicator = (props) => {
  return (
    <React.Fragment>
      <Row>
        <RadialProgress width={130} height={130} steps={props.outOf} step={props.count} ringFgColour='#324b3c' duration={1000} />
      </Row>
      &nbsp;
      <Row>
        <Col sm={{ size: 12 }}><center>Machines have uploaded data in past 24 hours.</center></Col>
      </Row>
    </React.Fragment>
  )
}

export default RadialIndicator
