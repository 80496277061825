exports.getEnvironmentURL = (action = '') => {
  let url
  if (process.env.REACT_APP_ENVIRONMENT.trim() === 'development') {
    url = `http://localhost:3000/`
  } else if (process.env.REACT_APP_ENVIRONMENT.trim() === 'preprod') {
    url = `https://serviceportal_preprod.maclean.app/api/`
  } else if (process.env.REACT_APP_ENVIRONMENT.trim() === 'production') {
    url = `https://my.macleanengineering.com/api/`
  }
  return url + action
}

exports.getVmsHost = (action = '') => {
  let url
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:3000/'
  } else if (process.env.REACT_APP_ENVIRONMENT.trim() === 'preprod') {
    url = `https://serviceportal_preprod.maclean.app/api/`
  } else if (process.env.REACT_APP_ENVIRONMENT.trim() === 'production') {
    url = `https://my.macleanengineering.com/api/`
  }
  return url + action
}
exports.handleError = (error) => {
  console.log(error)
  if (error.response.status === 401) {
    window.location.pathname = '/unauthorized'
  } else if (error.response.status === 403) {
    window.location.pathname = '/no-permissions'
  } else if (error.response.status === 404) {
    window.location.pathname = '/internal-server-error'
  }
}
