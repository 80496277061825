import React, { Component } from 'react'
import Moment from 'moment'
import './NotificationsTable.css'
import { Table } from 'reactstrap'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import NotificationDismisser from './NotificationItem/NotificationDismisser'

class NotificationsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showNotifications: this.props.showNotifications,
      sortByColumn: 'Machine Time',
      nameSorted: '',
      machineTimeSorted: '',
      timeReceivedSorted: '',
      dismissedSorted: '',
      severitySorted: ''
    }

    this.sortSameOld = true

    this.clickable = [
      'Two_Foot_Driving',
      'Over_20%_Grade_EV',
      'BMM_Delta_ISO_Voltage_Warning',
      'BMM_Delta_ISO_Voltage_Danger',
      'BMM_ISO_Voltage_Low',
      'BMM_Overheat_Warning',
      'BMM_Overheat_Danger',
      'BMM_Freeze_Warning',
      'BMM_Delta_Temp_Danger',
      'BMM_Delta_Temp_Warning'
    ]

    this.dataViews = {
      Tramming: ['Two_Foot_Driving', 'Over_20%_Grade_EV'],
      DeltaISO: ['BMM_Delta_ISO_Voltage_Warning', 'BMM_Delta_ISO_Voltage_Danger', 'BMM_ISO_Voltage_Low'],
      BatteryDeltaTemp: ['BMM_Overheat_Warning', 'BMM_Overheat_Danger', 'BMM_Freeze_Warning', 'BMM_Delta_Temp_Danger', 'BMM_Delta_Temp_Warning'],
      BatterySOC: []
    }
  }

  componentWillReceiveProps (nextProps) {
    // console.log('nextProps');

    this.sortByColumns(undefined, nextProps.showNotifications)
  }

  sortByColumns (e, notiList = undefined) {
    // console.log(e);

    let tempNotiList

    if (notiList === undefined) {
      tempNotiList = this.state.showNotifications
    } else {
      tempNotiList = notiList
    }

    if (e === undefined) {
      e = this.state.sortByColumn
      this.sortSameOld = !this.sortSameOld
    }

    if (e === 'Machine Time') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowUp />, timeReceivedSorted: '', dismissedSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) { return Moment(a.hmi_time).valueOf() - Moment(b.hmi_time).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowDown />, timeReceivedSorted: '', dismissedSorted: '', severitySorted: '' })
        tempNotiList.sort(function (a, b) { return Moment(b.hmi_time).valueOf() - Moment(a.hmi_time).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Time Received') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowUp />, dismissedSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) { return Moment(a.server_time).valueOf() - Moment(b.server_time).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowDown />, dismissedSorted: '', severitySorted: '' })
        tempNotiList.sort(function (a, b) { return Moment(b.server_time).valueOf() - Moment(a.server_time).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Name') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: <MdKeyboardArrowDown />, machineTimeSorted: '', timeReceivedSorted: '', dismissedSorted: '', severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) {
          var x = b.name.toLowerCase()
          var y = a.name.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ nameSorted: <MdKeyboardArrowUp />, machineTimeSorted: '', timeReceivedSorted: '', dismissedSorted: '', severitySorted: '' })
        this.sortSameOld = true
        tempNotiList.sort(function (a, b) {
          var x = a.name.toLowerCase()
          var y = b.name.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else if (e === 'Dismissed' && this.props.showing === 'All') {
      // console.log(this.state.sortByColumn + ' ' + e);
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', dismissedSorted: <MdKeyboardArrowUp />, severitySorted: '' })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) { return Moment(a.acknowledged).valueOf() - Moment(b.acknowledged).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: '', dismissedSorted: <MdKeyboardArrowDown />, severitySorted: '' })
        this.sortSameOld = true
        tempNotiList.sort(function (a, b) { return Moment(b.acknowledged).valueOf() - Moment(a.acknowledged).valueOf() })
      }
    } else if (e === 'Severity') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowUp />, dismissedSorted: '', severitySorted: <MdKeyboardArrowUp /> })
        this.sortSameOld = false
        tempNotiList.sort(function (a, b) {
          if (a.severity === null) a.severity = 10
          if (b.severity === null) b.severity = 10
          return b.severity - a.severity
        })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', timeReceivedSorted: <MdKeyboardArrowDown />, dismissedSorted: '', severitySorted: <MdKeyboardArrowDown /> })
        tempNotiList.sort(function (a, b) {
          if (a.severity === null) a.severity = 10
          if (b.severity === null) b.severity = 10
          return a.severity - b.severity
        })
        this.sortSameOld = true
      }
    } else {
      return
    }

    // console.log(tempNotiList);

    this.setState({
      showNotifications: tempNotiList,
      sortByColumn: e
    })
  }

  handleDismiss (e, comment) {
    // console.log(JSON.stringify(Moment().format()));
    let tempNotiList = this.state.showNotifications
    for (let i = 0; i < tempNotiList.length; i++) {
      if (tempNotiList[i].id === e) {
        tempNotiList[i].acknowledged = JSON.stringify(Moment().format()).slice(1, 20)
        tempNotiList[i].user = 'me'
        if (this.props.showing === 'Unseen') {
          tempNotiList.splice(i, 1)
        }
        break
      }
    }

    this.props.acknowledge(e, tempNotiList.length, comment)
    this.setState({
      showNotifications: tempNotiList
    })
  }

  handleShowDataExplorer (name, time) {
    let nameKeys = Object.keys(this.dataViews)

    for (let i = 0; i < nameKeys.length; i++) {
      if (this.dataViews[nameKeys[i]].indexOf(name) >= 0) {
        this.props.changeView('Data Explorer', [nameKeys[i], time])
        break
      }
    }
  }

  handleSeverityColor (severity) {
    switch (severity) {
      case 1:
        return <img src={require('../../../Octagon_Danger_WHMIS.jpg')} alt='' />
      case 2:
        return <img src={require('../../../Square_Warning_WHMIS.jpg')} alt='' />
      case 3:
        return <img src={require('../../../Triangle_Caution_WHMIS.jpg')} alt='' />
      default:
        return <span className='dotgrey' />
    }
  }

  renderTable () {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th className='sortable' onClick={() => this.sortByColumns('Severity')}>Severity {this.state.severitySorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Name')}>Name {this.state.nameSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Machine Time')}>Machine Time {this.state.machineTimeSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Time Received')}>Time Received {this.state.timeReceivedSorted}</th>
            <th>Message</th>
            <th className={this.props.showing === 'All' ? 'sortable' : null} onClick={() => this.sortByColumns('Dismissed')}>Dismissed {this.props.showing === 'All' ? this.state.dismissedSorted : null}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.showNotifications.map((el, index) => {
            return (
              <tr key={index}>
                <td><div className='dotContainer'>{this.handleSeverityColor(el.severity)}</div></td>
                <td onClick={(this.clickable.indexOf(el.name) >= 0) ? () => { this.handleShowDataExplorer(el.name, Moment.utc(el.hmi_time.slice(0, 19))) } : null}
                  className={(this.clickable.indexOf(el.name) >= 0) ? 'clickable' : null}>
                  {el.name.replace(/_/g, ' ')}
                </td>
                <td>{el.hmi_time.slice(0, 19).replace('T', ' ')}</td>
                <td>{Moment(Moment.utc(el.server_time).format()).format().slice(0, 19).replace('T', ' ')}</td>
                <td>{el.notification.slice(0, -27)}</td>
                <td>{
                  (el.acknowledged === null)
                    ? <div>
                      <NotificationDismisser handleDismiss={this.handleDismiss.bind(this)} id={el.id} index={index} />
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    </div>
                    : <div>
                      <span>{el.acknowledged.slice(0, 19).replace('T', ' ')}
                        <br />
                        {el.user}{el.comment !== null ? ':' : null}
                        {el.comment !== null ? <br /> : null}
                        {el.comment !== null ? '"' + el.comment + '"' : null}
                      </span>
                    </div>
                }</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  render () {
    let table = null

    if (this.state.showNotifications !== undefined) {
      table = this.renderTable()
    }

    return (
      <React.Fragment>
        {table}
      </React.Fragment>
    )
  }
}

export default NotificationsTable
