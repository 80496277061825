import React, { Component } from 'react'
import { Spinner } from 'reactstrap'
import DeTable from './DeTable/DeTable'
import UsagePlot from './UsagePlot/UsagePlot'

class overviewEVs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machine_ID: this.props.details.serial_num,
      totalHours: undefined,
      lastRow: this.props.details.last_row,
      isTableSet: false,
      isUsagePlotSet: false
    }

    // *** HOURS FIELDS MUST BE SUM-ABLE!! ***
    this.lastRow = undefined
    this.totalHours = undefined
    this.spns = undefined
    this.allowSetState = true
    this.deTableFields = ['datetime', 'TrammingHourMeter', 'AuxWithoutChargingHourMeter', 'ChargingHourMeter', 'BatteryHourMeter', 'StateOfCharge']
    this.usagePlotFields = ['BatteryState', 'InAuxMode', 'ManageCharger1State']
  }

  isTableSet (flag = false) {
    if (!this.state.isTableSet) {
      this.setState({ isTableSet: flag })
    }
  }
  isUsagePlotSet (flag = false) {
    if (!this.state.isUsagePlotSet) {
      this.setState({ isUsagePlotSet: flag })
    }
  }
  render () {
    // console.log(this.props.details);
    let overviewContent = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    let deTable = null
    let usagePlot = null

    if (this.state.lastRow !== undefined && deTable === null && usagePlot === null) {
      deTable = <DeTable isTableSet={this.isTableSet.bind(this)} lastRow={this.state.lastRow} details={this.props.details} deTableFields={this.deTableFields} />
      usagePlot = <UsagePlot isUsagePlotSet={this.isUsagePlotSet.bind(this)} ajaxError={this.props.ajaxError} details={this.props.details} usagePlotFields={this.usagePlotFields} getBackFirstUtilData={this.props.getBackFirstUtilData} />
      overviewContent = <React.Fragment>
        {deTable}
        {usagePlot}
      </React.Fragment>
    }
    return (
      <React.Fragment>
        {overviewContent}
      </React.Fragment>
    )
  }
}

export default overviewEVs
