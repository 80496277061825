import React, { Component } from 'react'
import './MachineTypesList.css'

class machineTypeList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machineTypesAvail: this.props.machineTypes
    }
  }

  handleClick (el) {
    this.props.handleChildClicked(el)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.machineTypes !== this.state.machineTypesAvail) {
      this.setState({
        machineTypesAvail: nextProps.machineTypes
      })
    }
  }

  render () {
    let listItems = null
    if (this.state.machineTypesAvail !== null) {
      listItems = this.state.machineTypesAvail.map((el, id) => {
        return <li
          key={id}
          onClick={() =>
            this.handleClick(el)
          }
          className={
            (this.props.showMachines === el) ? 'text-danger' : null
          }>
          {el.replace('_', ' ')}
        </li>
      })
    }

    return (
      <ul>
        {listItems}
      </ul>
    )
  }
}

export default machineTypeList
