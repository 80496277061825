import React, { Component } from 'react'
import Tramming from './Plots/Tramming'
import DeltaISO from './Plots/DeltaISO'
import BatterySOC from './Plots/BatterySOC'
import BatteryTemp from './Plots/BatteryTemp'
import BatteryDeltaTemp from './Plots/BatteryDeltaTemp'
import BatteryCoolantPSI from './Plots/BatteryCoolantPSI'

import './DataExplorerEVs.css'
import { Row, Col, Input } from 'reactstrap'

class DataExplorerEVs extends Component {
  constructor (props) {
    super(props)

    this.state = {
      serial_num: this.props.details.serial_num,
      currentView: this.props.deProps.view
    }

    this.views = ['', 'Tramming', 'DeltaISO', 'BatterySOC', 'BatteryTemp', 'BatteryDeltaTemp', 'BatteryCoolantPSI']
    this.prevStart = undefined
    this.prevEnd = undefined
  }

  componentWillUnmount () {
    this.prevStart = undefined
    this.prevEnd = undefined
  }

  handleChangeView = (e) => {
    // console.log(e.target.value);

    this.setState({
      currentView: e.target.value
    })
  }

  getBackStartEnd (start, end) {
    this.prevStart = start
    this.prevEnd = end
    // console.log('getBackStartEnd', start.format(), end.format())
  }

  render () {
    // console.log(this.props);
    let currentView = null

    if (this.state.currentView !== undefined) {
      switch (this.state.currentView) {
        case 'Tramming':
          currentView = <Tramming
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        case 'DeltaISO':
          currentView = <DeltaISO
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        case 'BatterySOC':
          currentView = <BatterySOC
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        case 'BatteryTemp':
          currentView = <BatteryTemp
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        case 'BatteryDeltaTemp':
          currentView = <BatteryDeltaTemp
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        case 'BatteryCoolantPSI':
          currentView = <BatteryCoolantPSI
            ajaxError={this.props.ajaxError}
            details={this.props.details}
            tableDefinitions={this.state.tableDefinitions}
            deProps={this.props.deProps}
            start={this.prevStart}
            end={this.prevEnd}
            getBack={this.getBackStartEnd.bind(this)} />
          break

        default:
          currentView = null
      }
    }

    return (
      <React.Fragment>
        <Row>
          <Col sm={{ size: 2, offset: 3 }} md={{ size: 2, offset: 3 }}><h3>Select View:</h3></Col>
          <Col sm={{ size: 2 }} md={{ size: 3 }}>
            <Input type='select' onChange={this.handleChangeView}>
              {this.views.map((el, index) => { return <option key={index} value={el} selected={(this.state.currentView === el) ? 'selected' : null}>{el}</option> })}
            </Input>
          </Col>
        </Row>
        <br />
        {currentView}
      </React.Fragment>
    )
  }
}

export default DataExplorerEVs
