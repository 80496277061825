import React, { Component } from 'react'
import TopBar from '../../TopBar/TopBar'
import { Nav, NavItem, NavLink } from 'reactstrap'
import './SideDrawerDetailPane.css'

class sideDrawer extends Component {
    handleClicked = (el) => {
      this.props.clickedHandler(el)
    }

    render () {
      // ((this.state.machineTypes[0] === undefined) && (this.props.machineIdentities !== undefined))

      return (
        <React.Fragment>
          <TopBar headerText='Select View' />
          <Nav pills className='side-drawer'>
            <NavItem>
              <NavLink active={this.props.currentView === 'Overview'} onClick={() => this.handleClicked('Overview')}>Overview</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={this.props.currentView === 'Notification History'} onClick={() => this.handleClicked('Notification History')}>Notification History</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={this.props.currentView === 'Utilization Explorer'} onClick={() => this.handleClicked('Utilization Explorer')}>Utilization Explorer</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={this.props.currentView === 'Data Explorer'} onClick={() => this.handleClicked('Data Explorer')}>Data Explorer</NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={this.props.currentView === 'Power Consumption'} onClick={() => this.handleClicked('Power Consumption')}>Power Consumption</NavLink>
            </NavItem>
          </Nav>
        </React.Fragment>
      )
    }
}

export default sideDrawer
