import React, { Component } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import { Spinner } from 'reactstrap'
import ExcelExporting from './ExcelExporting'
import ServiceReport from './service-report'
import shared from '../../shared/shared'
import 'react-table/react-table.css'

// const DateFormatter = ({ value }) => value ? value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1').split('T')[0] : null
let filteredRows = []
class ReportTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.getColumnWidth = this.getColumnWidth.bind(this)
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.state.filteredRows.map(row => {
      if (accessor === 'ReportDate' || accessor === 'Modified') {
        return new Date(row[accessor]).toLocaleDateString().length
      }
      if (accessor === 'FormUrl') {
        return 10
      } else {
        return (`${row[accessor]}` || '').length
      }
    }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  componentDidMount () {
    if (this.props.year !== null) {
      this.loadData(this.props.year)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.year !== prevProps.year || prevProps.customer.key !== this.props.customer.key) {
      this.loadData(this.props.year)
    }
  }

  async loadData (year) {
    const URL = shared.getEnvironmentURL(`getServiceReportsData`) + `?fy=${year}`
    this.setState({ loaded: false })
    await axios.get(URL, { withCredentials: true }).then((response) => {
      response.data !== null
        ? this.setState({ row: response.data, loaded: true, filteredRows: response.data })
        : this.setState()
    }).catch((error) => {
      shared.handleError(error)
    })
    this.setState({
      columns: [
        { accessor: 'ID', Header: 'ID', width: this.getColumnWidth('ID', 'ID') },
        { accessor: 'FormName',
          Header: 'Form Name',
          width: this.getColumnWidth('FormName', 'Form Name'),
          Cell: props => {
            return (
              <ServiceReport csaNumber={props.original.CSA_ID} serviceReportNumber={props.value} year={this.props.year} reportDate={props.original.ReportDate} formURL={props.original.FormUrl} />
            // <a href={props.original.FormUrl} target='_blank' rel='noopener noreferrer'>{props.value}</a>
            )
          } },
        { accessor: 'OrderNo', Header: 'Order Number', width: this.getColumnWidth('OrderNo', 'Order No') },
        { accessor: 'ReportDate', Header: 'Report Date', Cell: props => props.value === null ? null : new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('ReportDate', 'Report Date') },
        { accessor: 'ServiceTech', Header: 'Service Tech', width: this.getColumnWidth('ServiceTech', 'Service Tech') },
        { accessor: 'ServiceTechId', Header: 'Service Tech ID', width: this.getColumnWidth('ServiceTechId', 'Service Tech Id') },
        { accessor: 'Shift', Header: 'Shift', width: this.getColumnWidth('Shift', 'Shift') },
        { accessor: 'Level', Header: 'Level', width: this.getColumnWidth('Level', 'Level') },
        { accessor: 'Customer', Header: 'Customer', width: this.getColumnWidth('Customer', 'Customer') },
        { accessor: 'Site', Header: 'Site', width: this.getColumnWidth('Site', 'Site') },
        { accessor: 'Contact', Header: 'Contact', width: this.getColumnWidth('Contact', 'Contact') },
        { accessor: 'ReferenceNo', Header: 'Reference Number', width: this.getColumnWidth('ReferenceNo', 'Reference Number') },
        { accessor: 'MachineNo', Header: 'Machine Number', width: this.getColumnWidth('MachineNo', 'Machine Number') },
        { accessor: 'MineUnitNo', Header: 'Mine Unit Number', width: this.getColumnWidth('MineUnitNo', 'Mine Unit Number') },
        { accessor: 'CarrierHrs', Header: 'Carrier Hours', width: this.getColumnWidth('CarrierHrs', 'Carrier Hours') },
        { accessor: 'PowerPackHrs', Header: 'Power Pack Hours', width: this.getColumnWidth('PowerPackHrs', 'Power Pack Hours') },
        { accessor: 'DrillPercHrs', Header: 'Drill Perc Hours', width: this.getColumnWidth('DrillPercHrs', 'Drill Perc Hours') },
        { accessor: 'WtyConsideration', Header: 'WTY Consideration', width: this.getColumnWidth('WtyConsideration', 'Wty Consideration') },
        { accessor: 'Labour', Header: 'Labour', width: this.getColumnWidth('Labour', 'Labour') },
        { accessor: 'Component', Header: 'Component', width: this.getColumnWidth('Component', 'Component') },
        { accessor: 'Delays', Header: 'Delays', width: this.getColumnWidth('Delays', 'Delays') },
        { accessor: 'IsCSRSubmitted', Header: 'CSR Submitted', Cell: props => props.value ? 'Yes' : 'No', width: this.getColumnWidth('IsCSRSubmitted', 'CSR Submitted') },
        { accessor: 'IsScopeOfWorkCompleted', Header: 'Scope of Work Completed', Cell: props => props.value ? 'Yes' : 'No', width: this.getColumnWidth('IsScopeOfWorkCompleted', 'Scope of Work Completed') },
        { accessor: 'TotalServiceHours', Header: 'Total Service Hours', width: this.getColumnWidth('TotalServiceHours', 'Total Service Hours') },
        { accessor: 'TotalTravelHours', Header: 'Total Travel Hours', width: this.getColumnWidth('TotalTravelHours', 'Total Travel Hours') },
        { accessor: 'TotalDelayHours', Header: 'Total Delay Hours', width: this.getColumnWidth('TotalDelayHours', 'Total Delay Hours') },
        { accessor: 'TotalHours', Header: 'Total Hours', width: this.getColumnWidth('TotalHours', 'Total Hours') },
        { accessor: 'Modified', Header: 'Modified', Cell: props => props.value === null ? null : new Date(props.value).toLocaleDateString(), width: this.getColumnWidth('Modified', 'Modified') },
        { accessor: 'CSA_ID', Header: 'CSA ID', width: this.getColumnWidth('CSA_ID', 'CSA ID') },
        { accessor: 'TotalIndirect', Header: 'Total Indirect', width: this.getColumnWidth('TotalIndirect', 'Total Indirect') },
        { accessor: 'ServiceType', Header: 'Service Type', width: this.getColumnWidth('ServiceType', 'Service Type') },
        { accessor: 'SignOffEmployeeNumber', Header: 'Sign Off Employee Number', width: this.getColumnWidth('SignOffEmployeeNumber', 'Sign Off Employee Number') },
        { accessor: 'SignOffContactEmail', Header: 'Sign Off Contact Email', width: this.getColumnWidth('SignOffContactEmail', 'Sign Off Contact Email') },
        { accessor: 'DispatchNumber', Header: 'Dispatch Number', width: this.getColumnWidth('DispatchNumber', 'DispatchNumber') },
        { accessor: 'Region', Header: 'Region', width: this.getColumnWidth('Region', 'Region') },
        { accessor: 'ServiceTechEmail', Header: 'Service Tech Email', width: this.getColumnWidth('ServiceTechEmail', 'Service Tech Email') },
        { accessor: 'SubmittedBy', Header: 'Submitted By', width: this.getColumnWidth('SubmittedBy', 'Submitted By') },
        { accessor: 'FiscalYear', Header: 'Fiscal Year', width: this.getColumnWidth('FiscalYear', 'Fiscal Year') }
      ]
    })
  }

  setTableData () {
    let excelExport

    excelExport = <ExcelExporting loaded={this.state.loaded} unfilteredData={this.state.row} filteredData={filteredRows} columns={this.state.columns} />
    this.setState({ excelExport: excelExport })
  }
  getTableData () {
    if (this.state.loaded) {
      return this.state.tableData
    } else {
      return false
    }
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  render () {
    let { loaded } = this.state
    return (
      <React.Fragment>
        {loaded && filteredRows.length >= 0
          ? (
            <React.Fragment>
              {/* {this.state.excelExport} */}
              <ReactTable filterable data={this.state.filteredRows} columns={this.state.columns} defaultPageSize={10} defaultFilterMethod={this.filterCaseInsensitive} />
            </React.Fragment>
          )
          : <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />}
      </React.Fragment>
    )
  }
}
export default ReportTable
