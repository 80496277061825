import React, { Component } from 'react'
import SideDrawer from '../SideDrawer/SideDrawer'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody, Row, Col } from 'reactstrap'

class topBar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machineIdentities: this.props.companyMachines
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.companyMachines !== undefined) {
      this.setState({
        machineIdentities: nextProps.companyMachines
      })
    }
  }

  popOver () {
    if (this.props.hasPopOver) {
      return (
        <Col sm={{ size: 3 }}>
          <Button outline id='filter-and-report-button' type='button'>
              Show Filters
          </Button>
          <UncontrolledPopover placement='bottom' target='filter-and-report-button' trigger='legacy'>
            <PopoverHeader>
              <center>Fleet</center>
            </PopoverHeader>
            <PopoverBody>
              <SideDrawer sortVehicleType={this.props.sortVehicleType} showMachines={this.props.showMachineView} machineIdentities={this.getMachineIdentities()} />
            </PopoverBody>
          </UncontrolledPopover>
        </Col>
      )
    } else {
      return null
    }
  }

  getMachineIdentities () {
    let machineIdentities = this.props.showMachines
    if (this.state.machineIdentities !== null) {
      machineIdentities = this.state.machineIdentities
    }
    return machineIdentities
  }
  render () {
    return (
      <Row>
        {this.popOver()}
        <Col sm={{ size: this.props.hasPopOver ? 5 : 12, offset: this.props.hasPopOver ? 1 : null }}>
          <h2>
            <center>
              {this.props.headerText}
            </center>
          </h2>
        </Col>
      </Row>
    )
  }
}

export default topBar
