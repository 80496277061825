import React, { Component } from 'react'
import { Redirect } from 'react-router'
import shared from '../shared/shared'
class LogoutComponent extends Component {
  componentDidMount () {
    return (<Redirect to={`https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${shared.getEnvironmentURL()}`} />)
  }
  render () {
    return (null)
  }
}

export default LogoutComponent
