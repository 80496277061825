import React, { Component } from 'react'
import Moment from 'moment'
import Controls from './Controls/Controls'
import UsagePlot from './Plot/UsagePlot'
import { Spinner } from 'reactstrap'
class detailView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: this.props.passDetailStartEnd[0] !== undefined ? this.props.passDetailStartEnd[0] : Moment(this.props.details.last_row.datetime.slice(0, 19)).subtract(24, 'hours'),
      end: this.props.passDetailStartEnd[1] !== undefined ? this.props.passDetailStartEnd[1] : Moment(this.props.details.last_row.datetime.slice(0, 19)),
      span: 24,
      stepUnit: 'hours',
      step: 24,
      stepBy: 'span',
      setEnd: true
    }
  }

  setStart (e) {
    let start = Moment(e)
    let end = start.clone()
    end.add(this.state.span, this.state.stepUnit)

    this.setState({
      start: start,
      end: end,
      setEnd: false
    })
  }

  setEnd (e) {
    let end = Moment(e)
    let start = end.clone()
    start = start.subtract(this.state.span, this.state.stepUnit)

    this.setState({
      end: end,
      start: start,
      setEnd: true
    })
  }

  setSpan (e) {
    if (this.state.setEnd) {
      let start = this.state.end.clone()
      start.subtract(e, this.state.stepUnit)

      this.setState({
        span: e,
        start: start
      })
    } else {
      let end = this.state.start.clone()
      end.add(e, this.state.stepUnit)

      this.setState({
        span: e,
        end: end
      })
    }
  }

  setUnit (e) {
    this.stepUnit = e.target.value
    let span = this.state.end.diff(this.state.start, e.target.value)
    this.setState({
      span: span,
      stepUnit: this.stepUnit
    })
    // console.log(this.stepUnit);
  }

  setStep (e) {
    this.step = e
    this.setState({
      step: this.step
    })
    // console.log(this.step);
  }

  forward () {
    // console.log(JSON.stringify(Moment(this.state.start)));
    let end = this.state.end.clone()
    let start = this.state.start.clone()

    this.setState({
      start: start.subtract(this.state.step, this.state.stepUnit),
      end: end.subtract(this.state.step, this.state.stepUnit)
    })
  }

  back () {
    // console.log(JSON.stringify(Moment(this.state.start).add(this.step, this.stepUnit)));
    let end = this.state.end.clone()
    let start = this.state.start.clone()

    this.setState({
      start: start.add(this.state.step, this.state.stepUnit),
      end: end.add(this.state.step, this.state.stepUnit)
    })
  }

  render () {
    let controls = null
    let usagePlot = <Spinner style={{ margin: '5% auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />

    if (this.state.start !== undefined) {
      controls = <Controls
        start={this.state.start}
        end={this.state.end}
        rangeStart={this.props.machineRangeStart}
        rangeEnd={this.props.machineRangeEnd}
        setStart={this.setStart.bind(this)}
        setEnd={this.setEnd.bind(this)}
        setSpan={this.setSpan.bind(this)}
        setUnit={this.setUnit.bind(this)}
        setStep={this.setStep.bind(this)}
        span={this.state.span}
        step={this.state.step}
        stepBy={this.state.stepBy}
        stepUnit={this.state.stepUnit}
        forward={this.forward.bind(this)}
        back={this.back.bind(this)}
      />

      usagePlot = <UsagePlot
        ajaxError={this.props.ajaxError}
        start={this.state.start}
        end={this.state.end} fields={this.props.fields}
        overviewData={this.props.overviewData}
        details={this.props.details}
        view={this.props.view} longTermClicked={this.props.longTermClicked} />
    }

    return (
      <React.Fragment>
        {controls}
        {usagePlot}
      </React.Fragment>
    )
  }
}

export default detailView
