import React, { Component } from 'react'

class ControlledInputWithInternalState extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isValid: true,
      value: props.value
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      // new value from the parent - copy it to state
      this.setState({ value: nextProps.value })
    }
  }

    onValueChanged = (e) => {
      const { value } = e.target
      // No empty strings
      const isValid = ((!isNaN(value)) && (value !== ''))

      this.setState({ value, isValid }, this.onStateUpdated)
    }

    onStateUpdated = () => {
      if (this.state.isValid) {
        this.props.onChange(this.state.value)
      }
    }

    render () {
      const { value, isValid } = this.state
      let style = {}

      if (!isValid) {
        style['border'] = '2px solid red'
      }

      return (
        <React.Fragment>
          <input
            className='form-control'
            type='text'
            id='utilization-detail-view-step'
            value={value}
            onChange={this.onValueChanged}
          />
        </React.Fragment>
      )
    }
}

export default ControlledInputWithInternalState
