import React, { Component } from 'react'
import { Redirect } from 'react-router'
import shared from '../shared/shared'
class LoginComponent extends Component {
  componentDidMount () {
    return (<Redirect to={`https://login.microsoftonline.com/memcoportal.onmicrosoft.com/oauth2/v2.0/authorize?client_id=d3aa2366-0cec-4a25-a5e5-f4bb1c8d9b14&response_type=code&redirect_uri=${shared.getEnvironmentURL('auth/openid/return&response_mode=query&scope=User.Read&state=12345')}`} />)
  }
  render () {
    return (null)
  }
}

export default LoginComponent
