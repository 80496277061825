import React from 'react'
import Plot from 'react-plotly.js'
import './PlotlyChart.css'

class PlotlyChart extends React.Component {
  renderPlot () {
    return <Plot
      data={[
        { name: 'Charging(KW)', type: 'bar', marker: { color: 'rgb(6, 193, 0)' }, x: this.props.barData.timechunk, y: this.props.barData.chunksum, yaxis: 'y2' },
        { mode: 'lines',
          type: 'line',
          name: 'Total(KWh)',
          marker: { color: 'rgb(107, 163, 255)' },
          x: this.props.barData.timechunk,
          y: this.props.barData.total,
          yaxis: 'y' },
        { name: '',
          marker: { color: 'rgba(107, 163, 255,0)' },
          mode: 'lines',
          x: [this.props.barData.timechunk[0],
            this.props.barData.timechunk[(this.props.barData.timechunk.length / 2).toFixed(0)], this.props.barData.timechunk[this.props.barData.timechunk.length - 1]],
          y: [this.props.barData.total[0], this.props.barData.total[0], this.props.barData.total[0]],
          yaxis: 'y',
          fill: 'tonexty',
          showlegend: false,
          type: 'line',
          hoverinfo: 'none' }]}
      useResizeHandler
      style={{ width: '100%', height: '50vh' }}
      config={{
        displaylogo: false,
        // showSendToCloud: true,
        modeBarButtonsToRemove: ['lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage']
      }}
      layout={{
        autosize: true,
        title: this.props.chartTitle,
        xaxis: { range: [this.props.rangeStart, this.props.rangeEnd] },
        // xaxis2:{range:[this.props.rangeStart, this.props.rangeEnd], overlaying:'x',},
        yaxis: { rangemode: 'nonnegative', range: [this.props.barData.total[0], this.props.barData.total[-1]], zeroline: false, tickfont: { color: 'rgb(107, 163, 255)' } },
        yaxis2: { autorange: true, rangemode: 'nonnegative', type: 'linear', overlaying: 'y', side: 'right', tickfont: { color: 'rgb(6, 193, 0))' }, zeroline: false } }}
    />
  }

  render () {
    let plot = null
    if (this.props.barData !== undefined) {
      plot = this.renderPlot()
    }
    return (
      <React.Fragment>{plot}</React.Fragment>
    )
  }
}

export default PlotlyChart
