import React, { Component } from 'react'
import MachineTypesList from './MachineTypesList/MachineTypesList'
import './SideDrawer.css'
import { Collapse, Button, CardBody, Card } from 'reactstrap'
class sideDrawer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machineTypes: [],
      collapse: false
    }
    this.toggle = this.toggle.bind(this)
    this.allowSetState = true
  }

  getMachineTypes () {
    this.allowSetState = false

    let machineTypes = {}
    let machineTypesFinal = []
    let i = 0

    for (i = 0; i < this.props.machineIdentities.length; i++) {
      machineTypes[this.props.machineIdentities[i].type] = true
    }

    machineTypesFinal = Object.keys(machineTypes)
    this.setState({
      machineTypes: machineTypesFinal
    })
  }
  toggle () {
    this.setState(state => ({ collapse: !state.collapse }))
  }
  handleChildClicked (el) {
    this.props.sortVehicleType(el)
  }

  render () {
    // ((this.state.machineTypes[0] === undefined) && (this.props.machineIdentities !== undefined))

    if ((this.allowSetState) && (this.props.machineIdentities !== undefined)) {
      this.getMachineTypes()
    }

    return (
      <React.Fragment>
        <h5 onClick={(event) => this.handleChildClicked('Whole Fleet')}><center>Whole Fleet</center></h5>
        <center>
          <Button outline color='secondary' onClick={this.toggle} >Sub-Fleets</Button>
        </center>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
              <MachineTypesList handleChildClicked={this.handleChildClicked.bind(this)} machineTypes={this.state.machineTypes} showMachines={this.props.showMachines} />
            </CardBody>
          </Card>
        </Collapse>
      </React.Fragment>
    )
  }
}

export default sideDrawer
