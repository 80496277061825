import React, { Component } from 'react'
import { Spinner } from 'reactstrap'
import axios from 'axios'
import ReactTable from 'react-table'
import shared from '../shared/shared'
class Rigs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      row: [],
      columns: []
    }
    this.filterCaseInsensitive = this.filterCaseInsensitive.bind(this)
  }

  getColumnWidth (accessor, headerText) {
    // https://github.com/tannerlinsley/react-table/issues/94
    const maxWidth = 200
    const magicSpacing = 12
    let cellLength = Math.max(...this.state.row.map(row => { return (`${row[accessor]}` || '').length }), headerText.length)

    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  async componentDidMount () {
    const URL = shared.getEnvironmentURL(`getRigs`)
    this.setState({ loaded: false })
    await axios.get(URL, { withCredentials: true }).then((response) => {
      this.setState({ row: response.data, loaded: true })
    }).catch((error) => {
      shared.handleError(error)
    })
    this.setState({
      columns: [
        { accessor: 'machine', Header: 'Machine', width: this.getColumnWidth('machine', 'Machine') },
        { accessor: 'description', Header: 'Description', width: this.getColumnWidth('description', 'Description') },
        { accessor: 'machineType', Header: 'Machine Type', width: this.getColumnWidth('machineType', 'Machine Type') },
        { accessor: 'status', Header: 'Status', width: this.getColumnWidth('status', 'Status') },
        { accessor: 'accountManager', Header: 'Account Manager', width: this.getColumnWidth('accountManager', 'Account Manager') },
        { accessor: 'customerUnitID', Header: 'Customer Unit ID', width: this.getColumnWidth('customerUnitID', 'Customer Unit ID') },
        { accessor: 'customerName', Header: 'Customer Name', width: this.getColumnWidth('customerName', 'Customer Name') },
        { accessor: 'site', Header: 'Site', width: this.getColumnWidth('site', 'Site') },
        { accessor: 'productionDate', Header: 'Production Date', width: this.getColumnWidth('productionDate', 'Production Date') },
        { accessor: 'shipDate', Header: 'Ship Date', width: this.getColumnWidth('shipDate', 'Ship Date') },
        { accessor: 'carrierHours', Header: 'Carrier Hours', width: this.getColumnWidth('carrierHours', 'Carrier Hours') },
        { accessor: 'powerPackHours', Header: 'Power Pack Hours', width: this.getColumnWidth('powerPackHours', 'Power Pack Hours') },
        { accessor: 'drillPercHours', Header: 'Drill Percussion Hours', width: this.getColumnWidth('drillPercHours', 'Drill Percussion Hours') },
        { accessor: 'lastServiceDate', Header: 'Last Service Date', width: this.getColumnWidth('lastServiceDate', 'Last Service Date') },
        { accessor: 'partNumber', Header: 'Part Number', width: this.getColumnWidth('partNumber', 'Part Number') }
      ]
    })
  }
  filterCaseInsensitive ({ id, value }, row) {
    return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.customer.key !== this.props.customer.key) {
      this.componentDidMount()
    }
  }
  render () {
    return (
      <React.Fragment>
        <React.Fragment><h2 className='display-4 text-center'>Capital Equipment</h2></React.Fragment>
        {
          this.state.loaded
            ? <ReactTable filterable data={this.state.row} columns={this.state.columns} className='-striped -highlight' defaultFilterMethod={this.filterCaseInsensitive} defaultPageSize={10} />
            : <Spinner color='primary' style={{ width: '20rem', height: '20rem', margin: '0 auto', display: 'block' }} />
        }
      </React.Fragment>

    )
  }
}

export default Rigs
