import React, { Component } from 'react'
import Moment from 'moment'
import NaItem from './NaElement'
import { Spinner, Progress } from 'reactstrap'
import shared from '../../../../../shared/shared'
import './UtilizationPreview.css'
import axios from 'axios'

class utilizationPreview extends Component {
  constructor (props) {
    super(props)
    this.state = {
      machine: this.props.data.serial_num,
      lastTimestamp: this.props.data.last_row !== undefined ? this.props.data.last_row.datetime : undefined,
      utilization: this.props.data.utilization //! == undefined ? this.props.data.utilization : undefined
    }

    this.totalRows = undefined
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.data.utilization !== undefined) {
      this.setState({
        utilization: nextProps.data.utilization
      })
    }
  }

  getData () {
    if (this.props.lastRow !== 'n') {
      let dateStart = Moment(this.props.lastRow.datetime).subtract(1, 'days')

      let body = {
        machine_id: this.state.machine,
        date_end: this.props.lastRow.datetime.replace('T', ' ').slice(0, 19),
        date_start: dateStart.format().replace('T', ' ').slice(0, 19)
      }
      // console.log(this.state.machine, body.date_start, body.date_end)

      axios.post(
        shared.getVmsHost('utilCounts'),
        body, { withCredentials: true }
      ).then((response) => {
        this.totalRows = response.data[0] !== undefined ? response.data[0].count : undefined
        // console.log(this.totalRows)
        if (this.totalRows !== undefined) {
          this.setState({
            utilization: (this.totalRows / 86400) * 100
          }, () => { this.props.getBackData(this.state.utilization) })
        } else {
          this.setState({
            utilization: 'N/A'
          }, () => { this.props.getBackData(this.state.utilization) })
        }
      }).catch((error) => { shared.handleError(error) })
    } else {
      this.setState({
        utilization: 'N/A'
      }, () => { this.props.getBackData(this.state.utilization) })
    }
  }

  renderUtilizationBar () {
    let utilization
    let noData
    let myArgs
    let values
    let item

    utilization = this.state.utilization.toFixed(2)
    noData = (100 - this.state.utilization).toFixed(2)

    myArgs = [
      {
        name: 'Utilization',
        value: utilization,
        color: '#324b3c'
      },
      {
        name: 'NoData',
        value: noData,
        color: '#ccc'
      }
    ]

    values = myArgs.filter(arg => arg.value > 0).map(function (item, i) {
      return (<div className='value' style={{ 'color': item.color, 'width': item.value + '%', minWidth: '15%', maxWidth: '85%' }} key={i}> <span>{((item.value / 100) * 24).toFixed(1)}hr</span> </div>)
    })

    item = <Progress color='primary' value={myArgs[0].value} />

    return (
      <div className='multicolor-bar'>
        {item}
        <div className='values'>
          {values === '' ? '' : values}
        </div>
      </div>
    )
  }

  render () {
    // console.log(this.state.utilization)

    let utilizationBar = <Spinner style={{ margin: '0 auto', display: 'block' }} color='primary' />

    if ((this.props.lastRow !== undefined) && (this.props.data.last_row === undefined) && (this.state.utilization === undefined)) {
      this.getData()
    } else if ((this.state.utilization !== undefined) && (this.state.utilization === 'N/A')) {
      utilizationBar = <NaItem machine={this.state.machine} />
    } else if (this.state.utilization !== undefined) {
      utilizationBar = this.renderUtilizationBar()
    }

    return (
      <div>
        {utilizationBar}
      </div>
    )
  }
}

export default utilizationPreview
