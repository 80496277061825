import React, { Component } from 'react'
import axios from 'axios'
import './FaultListTable.css'
import { Table } from 'reactstrap'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import Moment from 'moment'
import shared from '../../../../shared/shared'

class machineListTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      spns: undefined,
      allowSort: false,
      sortByColumn: 'Machine Time',
      faultSorted: '',
      machineTimeSorted: <MdKeyboardArrowDown />,
      machineSorted: '',
      nameSorted: ''
    }
    this.sortSameOld = false
    this.spns = {}
    this.fetchCount = 0
  }

  handleClicked (el, view) {
    this.props.machineDetailPane(el, view)
    this.props.popOpenList(undefined, undefined, undefined)
  }

  getSPNTable (serialNum) {
    for (let i in this.props.machineIdentities) {
      if (serialNum === this.props.machineIdentities[i].serial_num) {
        return this.props.machineIdentities[i].spn_table
      }
    }
    return undefined
  }

  getFaults () {
    let spns = []
    let newLine = {}

    for (let i in this.props.machines) {
      if (this.props.data[this.props.machines[i]].last_row !== undefined) {
        if ((this.props.data[this.props.machines[i]].last_row.SPNs_active !== null) && (this.props.data[this.props.machines[i]].last_row.SPNs_active.length !== 0)) {
          for (let j in this.props.data[this.props.machines[i]].last_row.SPNs_active) {
            newLine = {}
            newLine['spn'] = this.props.data[this.props.machines[i]].last_row.SPNs_active[j]
            newLine['machine'] = this.props.machines[i]
            newLine['spnTable'] = this.getSPNTable(this.props.machines[i])
            newLine['datetime'] = this.props.data[this.props.machines[i]].last_row.datetime
            newLine['title'] = undefined
            newLine['definition'] = undefined
            spns.push(newLine)
            this.spns[i] = newLine
          }
        }
      }
    }
    this.setState({
      spns: spns
    }, () => {
      for (let i in Object.keys(this.spns)) {
        this.fetchSPN(i)
      }
    })
    // return
  }

  fetchSPN (i) {
    let body = {
      spn: this.spns[i].spn,
      table: this.spns[i].spnTable
    }

    if (body.table === undefined) {
      this.fetchCount += 1
      return
    }

    axios.post(
      shared.getVmsHost('getSPN'),
      body, { withCredentials: true }
    ).then((response) => {
      if (response.data.length > 0) {
        this.fetchCount += 1
        let newSpns = []
        this.spns[i].title = response.data[0].title
        this.spns[i].definition = response.data[0].description
        for (let j in Object.keys(this.spns)) {
          newSpns.push(this.spns[j])
        }
        this.setState({
          spns: newSpns,
          allowSort: this.fetchCount === this.state.spns.length
        }, () => {
          if (this.fetchCount === this.state.spns.length) {
            this.sortByColumns()
          }
        })
      } else {
        this.fetchCount += 1
      }
    }).catch((error) => { shared.handleError(error) })
  }

  sortByColumns (e = undefined) {
    if (!this.state.allowSort) return
    // console.log(e);
    let tempSpnList = this.state.spns

    if (e === undefined) {
      e = this.state.sortByColumn
      // this.sortSameOld = !this.sortSameOld
    }

    if (e === 'Fault') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: '', machineSorted: '', faultSorted: <MdKeyboardArrowUp /> })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) { return Number(a.spn) - Number(b.spn) })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', machineSorted: '', faultSorted: <MdKeyboardArrowDown /> })
        tempSpnList.sort(function (a, b) { return Number(b.spn) - Number(a.spn) })
        this.sortSameOld = true
      }
    } else if (e === 'Machine Time') {
      // console.log(Moment('2018-11-16 09:17:57').valueOf());
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowUp />, machineSorted: '', faultSorted: '' })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) { return Moment(a.datetime).valueOf() - Moment(b.datetime).valueOf() })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: <MdKeyboardArrowDown />, machineSorted: '', faultSorted: '' })
        tempSpnList.sort(function (a, b) { return Moment(b.datetime).valueOf() - Moment(a.datetime).valueOf() })
        this.sortSameOld = true
      }
    } else if (e === 'Name') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        // e = undefined;
        this.setState({ nameSorted: <MdKeyboardArrowDown />, machineTimeSorted: '', machineSorted: '', faultSorted: '' })
        this.sortSameOld = false
        tempSpnList.sort(function (a, b) {
          var x = b.title.toLowerCase()
          var y = a.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ nameSorted: <MdKeyboardArrowUp />, machineTimeSorted: '', machineSorted: '', faultSorted: '' })
        this.sortSameOld = true
        tempSpnList.sort(function (a, b) {
          var x = a.title.toLowerCase()
          var y = b.title.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else if (e === 'Machine') {
      if ((this.state.sortByColumn === e) && (this.sortSameOld)) {
        this.sortSameOld = false
        this.setState({ nameSorted: '', machineTimeSorted: '', machineSorted: <MdKeyboardArrowDown />, faultSorted: '' })
        tempSpnList.sort(function (a, b) {
          var x = b.machine.toLowerCase()
          var y = a.machine.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      } else {
        this.setState({ nameSorted: '', machineTimeSorted: '', machineSorted: <MdKeyboardArrowUp />, faultSorted: '' })
        this.sortSameOld = true
        tempSpnList.sort(function (a, b) {
          var x = a.machine.toLowerCase()
          var y = b.machine.toLowerCase()
          if (x < y) { return -1 }
          if (x > y) { return 1 }
          return 0
        })
      }
    } else {
      return
    }

    this.setState({
      spns: tempSpnList,
      sortByColumn: e
    })
  }

  renderMachineListTable () {
    return (
      <Table responsive size='sm'>
        <thead>
          <tr>
            <th className='sortable' onClick={() => this.sortByColumns('Fault')}>SPN {this.state.faultSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Machine')}>Machine {this.state.machineSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Machine Time')}>Machine Time {this.state.machineTimeSorted}</th>
            <th className='sortable' onClick={() => this.sortByColumns('Name')}>Name {this.state.nameSorted}</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {this.state.spns.map((el, index) => {
            return (
              <tr key={index}>
                <td onClick={(e) => this.handleClicked(el.machine, 'Fault History')} className='clickable'>{el.spn}</td>
                <td>{el.machine}</td>
                <td>{el.datetime}</td>
                <td>{el.title !== undefined ? el.title : 'N/A'}</td>
                <td>{el.definition !== undefined ? el.definition : 'SPN definition could not be found'}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  render () {
    if ((this.props.data !== undefined) && (this.state.spns === undefined)) {
      this.getFaults()
    }

    let table = null

    if (this.state.spns !== undefined) {
      table = this.renderMachineListTable()
    }

    return (
      <Table className='machineListTable' responsive size='sm'>
        {table}
      </Table>
    )
  }
}

export default machineListTable
