import React, { Component } from 'react'
import Moment from 'moment'
import Controls from './Controls/Controls'
import UsagePlot from './Plot/UsagePlot'
import { Alert } from 'reactstrap'

class detailView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: undefined,
      end: undefined,
      span: undefined,
      step: 1,
      stepBy: 'span',
      setEnd: false,
      plotTicks: 'days'
    }

    this.firstState = false
    this.step = 1
    this.plotTicks = 'days'
  }

  setFirstStart () {
    let start = Moment.utc(this.props.machineRangeStart.slice(0, 19))
    let end = Moment.utc(this.props.machineRangeEnd.slice(0, 19))
    let ticks

    if (end.diff(start, 'days') > 90) {
      ticks = 'months'
    } else {
      ticks = 'days'
    }

    this.setState({
      start: start,
      end: end,
      span: end.diff(start, ticks, true).toFixed(1),
      plotTicks: ticks
    })
  }

  setStart = (e) => {
    let start = Moment(e).format()
    start = Moment.utc(start)

    this.setState({
      start: start,
      setEnd: false,
      span: this.state.end.diff(start, this.state.plotTicks, true).toFixed(1)
    })
  }

  setEnd (e) {
    let end = Moment(e).format()
    end = Moment.utc(end)

    this.setState({
      end: end,
      setEnd: true,
      span: end.diff(this.state.start, this.state.plotTicks, true).toFixed(1)
    })
  }

  setSpan = (e) => {
    if (this.state.setEnd) {
      let start = this.state.end.clone()
      start.subtract(e, this.state.plotTicks)

      this.setState({
        span: e,
        start: start
      })
    } else {
      let end = this.state.start.clone()
      end.add(e, this.state.plotTicks)

      this.setState({
        span: e,
        end: end
      })
    }
  }

  setUnit = (e) => {
    this.setState({
      plotTicks: e.target.value,
      span: this.state.end.diff(this.state.start, e.target.value, true).toFixed(1)
    })
    // console.log(this.stepUnit);
  }

  clickMonthForDays (e) {
    let newStart = Moment(e.points[0].x + ' 00:00:00')
    let newEnd = newStart.clone().add(1, 'months').subtract(1, 'days')
    this.plotTicks = 'days'

    this.setState({
      start: newStart,
      end: newEnd,
      span: newEnd.diff(newStart, 'days', true).toFixed(1),
      plotTicks: 'days'
    })
  }

  render () {
    let controls = null
    let usagePlot = null

    if ((this.props.details.last_row !== undefined) && (this.firstState === false) && (this.props.machineRangeStart !== undefined)) {
      this.setFirstStart()
      this.firstState = true
    }

    if (this.state.start !== undefined) {
      controls = <Controls start={this.state.start} end={this.state.end} setStart={this.setStart.bind(this)} setEnd={this.setEnd.bind(this)}
        setSpan={this.setSpan.bind(this)} setUnit={this.setUnit.bind(this)} span={this.state.span} stepUnit={this.state.plotTicks}
        machineRangeStart={this.props.machineRangeStart} machineRangeEnd={this.props.machineRangeEnd} />

      usagePlot = <UsagePlot
        ajaxError={this.props.ajaxError}
        start={this.state.start}
        end={this.state.end}
        fields={this.props.fields}
        details={this.props.details}
        units={this.state.plotTicks}
        clickBarForDetail={this.state.plotTicks === 'days' ? this.props.clickBarForDetail : this.clickMonthForDays.bind(this)} />
    }

    if (this.props.machineRangeStart === undefined) {
      controls = <Alert color='primary'><center><h3>Long term utilization not available for {this.props.details.serial_num}.</h3></center></Alert>
      usagePlot = null
    }

    return (
      <React.Fragment>
        {controls}
        <br />
        {usagePlot}
      </React.Fragment>
    )
  }
}

export default detailView
