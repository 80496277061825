import React, { Component } from 'react'
import PlotlyChart from './PlotlyChart'
import { Table, Spinner, Alert } from 'reactstrap'
import './PowerPlot.css'
import shared from '../../../../shared/shared'
import axios from 'axios'

class PowerPlot extends Component {
  constructor (props) {
    super(props)

    this.state = {
      start: this.props.start.clone(),
      end: this.props.end.clone(),
      machine: this.props.machine,
      powerData: undefined
    }

    this.allowGetFirst = true
  }

  returnString (e) {
    let str = JSON.stringify(e.format())
    return str
  }

  componentWillReceiveProps (nextProps) {
    if ((this.returnString(nextProps.end) !== this.returnString(this.state.end)) || (this.returnString(nextProps.start) !== this.returnString(this.state.start))) {
      // console.log('getPowerData');
      this.getPowerData(nextProps.start, nextProps.end)
    } else {

    }
  }

  getPowerData (start, end) {
    let body = {
      machine: this.state.machine,
      start: JSON.stringify(start.format()).slice(1, -1),
      end: JSON.stringify(end.format()).slice(1, -1)
    }

    axios.post(
      shared.getVmsHost('getPowerData'),
      body, { withCredentials: true }
    ).then((response) => {
      this.setState({
        powerData: response.data,
        start: start.clone(),
        end: end.clone()
      })
    }).catch((error) => { shared.handleError(error) })
  }

  renderPlotTable () {
    let perTotal = this.state.powerData[this.state.powerData.length - 1].total - this.state.powerData[0].total
    let perDayAvg = perTotal / (this.state.powerData.length / 96)
    let perMonthAvg = perTotal / (this.state.powerData.length / 2880)

    let makeTable = (
      <Table>
        <thead>
          <tr>
            <th>Total for Period</th><th>AVG Daily</th><th>AVG Monthly</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{perTotal.toFixed(2)} KWh</td><td>{perDayAvg.toFixed(2)} KWh</td><td>{perMonthAvg.toFixed(2)} KWh</td>
          </tr>
        </tbody>
      </Table>
    )

    return makeTable
  }

  convertToBarData (powerData) {
    let barData = { timechunk: [], total: [], chunksum: [] }

    for (let i = 0; i < powerData.length; i++) {
      barData.timechunk.push(powerData[i].timechunk)
      barData.total.push(Number(powerData[i].total).toFixed(2))
      barData.chunksum.push(Number(powerData[i].chunksum * 4).toFixed(2))
    }

    return barData
  }

  render () {
    // console.log(this.props);
    // console.log(this.state);

    let plotTable = null
    let plotlychart = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    if (this.allowGetFirst) {
      this.getPowerData(this.state.start, this.state.end)
      this.allowGetFirst = false
    }

    if (this.state.powerData !== undefined) {
      if (this.state.powerData.length !== 0) {
        plotTable = this.renderPlotTable()
        plotlychart = <PlotlyChart rangeStart={JSON.stringify(this.state.start.clone().format()).slice(1, -2).replace('T', ' ')}
          rangeEnd={JSON.stringify(this.state.end.clone().format()).slice(1, -2).replace('T', ' ')}
          barData={this.convertToBarData(this.state.powerData)} />
      } else {
        plotlychart = null
        plotTable = <Alert color='primary'><center><h3>Power consumption data not available for this timeframe {this.state.machine_ID}</h3></center></Alert>
      }
    }

    return (
      <React.Fragment>
        {plotTable}
        {plotlychart}
      </React.Fragment>
    )
  }
}

export default PowerPlot
