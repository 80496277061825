import React from 'react'
import { RadialProgress } from 'react-radial-progress-indicator'
import './RadialIndicator.css'
import { Row, Col } from 'reactstrap'
import _ from 'lodash'

const RadialIndicator = (props) => {
  let avg = _.round(props.avg)
  return (
    <React.Fragment>
      <Row>
        <RadialProgress width={130} height={130} steps={24} step={avg} ringFgColour='#324b3c' duration={1000} />
      </Row>
      &nbsp;
      <Row>
        <Col sm={{ size: 12 }}>
          <center>Average usage in last 24 hours of recorded machine operation.</center>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RadialIndicator
