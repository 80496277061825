import React, { Component } from 'react'
import Controls from './Controls/Controls'
import Moment from 'moment'
import './Notifications.css'
import NotificationTable from './NotificationTable/NotificationsTable'
import { Alert, Spinner } from 'reactstrap'
import shared from '../../../shared/shared'
import axios from 'axios'

class NotificationDetail extends Component {
  constructor (props) {
    super(props)

    this.state = {
      machineDetails: this.props.details,
      machineID: this.props.details.serial_num,
      start: undefined,
      end: undefined,
      show: 'Unseen',
      showNotifications: undefined,
      noNotifications: false,
      span: undefined,
      setEnd: true,
      machineDateRangeStart: undefined,
      machineDateRangeEnd: undefined
    }
  }

  getMachineDateRange () {
    let body = {
      machine: this.state.machineID,
      fields: ['datetime']
    }

    axios.post(
      shared.getVmsHost('machineDaterange'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(data);
      if (response.data.starts !== undefined) {
        this.setState({
          machineDateRangeStart: response.data.starts,
          machineDateRangeEnd: response.data.ends,
          noNotifications: false
        })
      } else {
        this.setState({
          noNotifications: true
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  getCurrentNotifications () {
    let body = {
      machine: this.state.machineID
    }

    axios.post(
      shared.getVmsHost('currentCustomerNotifications'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(data);
      if (response.data.length > 0) {
        let firstLast
        let diffSpan
        let start
        let end
        if (this.state.span === undefined) {
          firstLast = this.getFirstLast(response.data)
          start = Moment.utc(firstLast[0])
          end = Moment.utc(firstLast[1])
          diffSpan = Moment.utc(firstLast[1]).diff(Moment.utc(firstLast[0]), 'days')
        } else {
          start = this.state.start
          end = this.state.end
          diffSpan = this.state.span
        }
        this.setState({
          start: start,
          end: end,
          showNotifications: response.data,
          span: diffSpan,
          noNotifications: false,
          show: 'Unseen'
        })
      } else {
        this.setState({
          noNotifications: true,
          show: 'Unseen',
          start: Moment.utc().subtract(30, 'days'),
          end: Moment.utc(),
          span: 30
        })
      }
    }).catch((error) => { shared.handleError(error) })
  }

  getDateRangeNotifications (callback) {
    let body = {
      machine: this.state.machineID,
      start: JSON.stringify(this.state.start).slice(1, 20).replace('T', ' '),
      end: JSON.stringify(this.state.end).slice(1, 20).replace('T', ' ')
    }

    axios.post(
      shared.getVmsHost('daterangeCustomerNotifications'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(response.data);
      if (response.data.length > 0) {
        callback(response.data)
      } else {
        this.setState({
          noNotifications: true
        })
        return []
      }
    }).catch((error) => { shared.handleError(error) })
  }

  getFirstLast (data) {
    let dates = []
    for (let i in data) {
      dates.push(data[i].hmi_time)
    }
    dates.sort(function (a, b) { return Moment(b).valueOf() - Moment(a).valueOf() })
    return [dates[dates.length - 1], dates[0]]
  }

  setStart = (e) => {
    let end
    let start = Moment(e)

    if (this.state.end === undefined) {
      end = start.clone().add(30, 'days')
      this.setState({
        start: start,
        end: end,
        setEnd: false,
        span: 30,
        show: 'All'
      }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
    } else {
      this.setState({
        start: start,
        setEnd: false,
        span: this.state.end.diff(start, 'days')
      }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
    }
  }

  setEnd (e) {
    let start
    let end = Moment(e)

    if (this.state.start === undefined) {
      start = end.clone().subtract(30, 'days')
      this.setState({
        start: start,
        end: end,
        span: 30,
        setEnd: true,
        show: 'All'
      }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
    } else {
      this.setState({
        end: end,
        setEnd: true,
        span: end.diff(this.state.start, 'days')
      }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
    }
  }

  setSpan = (e) => {
    // console.log(e);
    let start
    let end

    if ((this.state.span === undefined) && (this.state.start === undefined)) {
      end = Moment()
      end = end.format().slice(0, 19)
      // console.log(end);
      end = Moment.utc(end)
      start = end.clone().subtract(e, 'days')
      this.setState({
        start: start,
        end: end,
        span: e,
        show: 'All'
      }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
    } else {
      if (this.state.setEnd) {
        let start = this.state.end.clone()
        start.subtract(e, 'days')

        this.setState({
          span: e,
          start: start
        }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
      } else {
        let end = this.state.start.clone()
        end.add(e, 'days')

        this.setState({
          span: e,
          end: end
        }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
      }
    }
  }

  toggleShowAll () {
    if (this.state.show === 'Unseen') {
      if (this.state.end === undefined) {
        let end = Moment.utc(this.state.machineDetails.last_data_timestamp)
        let start = end.clone().subtract(30, 'days')
        let span = end.diff(start, 'days')
        this.setState({
          start: start,
          end: end,
          span: span,
          show: 'All'
        }, () => this.getDateRangeNotifications(this.toggleNewData.bind(this)))
      } else {
        this.setState({
          show: 'All'
        })
        this.getDateRangeNotifications(this.toggleNewData.bind(this))
      }
    } else {
      this.getCurrentNotifications()
    }
  }

  toggleNewData (data) {
    this.setState({
      showNotifications: data,
      show: 'All',
      noNotifications: false
    })
  }

  acknowledgeHandler (ids, remaining, comment) {
    let body = {
      ids: [ids],
      timestamp: JSON.stringify(Moment().format()).slice(1, 20).replace('T', ' '),
      comment: comment
    }

    axios.post(
      shared.getVmsHost('acknowledgeCustomerNotifications'),
      body, { withCredentials: true }
    ).then((response) => {
      // console.log(response.data);
    }).catch((error) => { shared.handleError(error) })

    if (remaining < 1) {
      this.setState({ noNotifications: true })
    }
  }

  render () {
    // console.log(this.props);

    let table = <Spinner style={{ margin: '0 auto', display: 'block', width: '20vh', height: '20vh' }} color='primary' />
    let controls = null

    if ((this.state.showNotifications === undefined) && !this.state.noNotifications) {
      this.getCurrentNotifications()
    }

    if ((this.state.machineDateRangeStart === undefined) && (this.state.noNotifications === false)) {
      this.getMachineDateRange()
    }

    if ((this.state.showNotifications !== undefined) && (this.state.start !== undefined) && !this.state.noNotifications) {
      controls = <Controls
        toggleShowAll={this.toggleShowAll.bind(this)}
        setEnd={this.setEnd.bind(this)}
        setStart={this.setStart.bind(this)}
        setSpan={this.setSpan.bind(this)}
        showing={this.state.show} start={this.state.start}
        end={this.state.end}
        span={this.state.span}
        rangeStart={this.state.machineDateRangeStart}
        rangeEnd={this.state.machineDateRangeEnd} />

      table = <NotificationTable
        showNotifications={this.state.showNotifications}
        showing={this.state.show}
        acknowledge={this.acknowledgeHandler.bind(this)}
        changeView={this.props.changeView} />
    } else if (this.state.start !== undefined) {
      controls = <Controls
        toggleShowAll={this.toggleShowAll.bind(this)}
        setEnd={this.setEnd.bind(this)}
        setStart={this.setStart.bind(this)}
        setSpan={this.setSpan.bind(this)}
        showing={this.state.show}
        start={this.state.start}
        end={this.state.end}
        span={this.state.span}
      />
      table = <Alert color='primary'><center><h3>{ (this.state.show === 'Unseen') ? 'No unseen notifications at this time.' : 'No notifications recorded for this time period.' }</h3></center></Alert>
    } else if (this.state.noNotifications) {
      controls = <Controls
        toggleShowAll={this.toggleShowAll.bind(this)}
        setEnd={this.setEnd.bind(this)}
        setStart={this.setStart.bind(this)}
        setSpan={this.setSpan.bind(this)}
        showing={this.state.show}
        start={this.state.start}
        end={this.state.end}
        span={this.state.span}
      />
      table = <Alert color='primary'> <center><h3>{ (this.state.show === 'Unseen') ? 'No notifications recorded for ' + this.state.machineID : 'No notifications recorded for this time period.' }</h3></center></Alert>
    }

    return (
      <React.Fragment>
        {controls}
        {table}
      </React.Fragment>
    )
  }
}

export default NotificationDetail
